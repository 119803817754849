import React, { useState, useRef, useEffect } from "react";
import {
  Space,
  Table,
  Input,
  Button,
  Typography,
  Modal,
  Pagination,
} from "antd";
import moment from "moment-timezone";

import { FaLockOpen, FaPrint } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import CustomButton from "../../common/custom_button";
import {
  dynamicRequest,
  mutation_enrolment_ticket_print,
  useDynamicSelector,
} from "../../../../services/redux";
import ActionModal from "../billing_history_modal/action_modal";
import {
  mutation_update_enrolment,
  query_get_enrolment_details,
} from "../../../../services/redux/slices/graphql/graphql_billing_history";
import { useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toUpper, upperCase } from "lodash";
import {
  getAmountFormat,
  getAmountFormatWithSymbol,
  retrieveItem,
  send_response_to_printer_service,
} from "../../../../helpers/functions";
import { IoMdClose } from "react-icons/io";
import { io } from "socket.io-client";
import { MdEdit } from "react-icons/md";
import UpdateEnrollmentModal from "../billing_history_modal/update_enrollment_modal";
import axios from "axios";
import toast from "react-hot-toast";
import { get_enrolment_detail_query } from "../../../../services/redux/slices/graphql/graphql_get_enrolment_detail";

const BillingHistoryTable = ({
  get_all_enrollment_list,
  selected_date,
  set_current_page_limit,
  set_current_page,
  current_page,
  current_page_limit,
}) => {
  const dispatch = useDispatch();
  const [open_cancel_booking_modal, set_cancel_booking_modal] = useState(false);
  const [open_unlock_print_modal, set_open_unlock_print_modal] =
    useState(false);
  const [update_modal_visible, set_update_modal_visible] = useState(false);
  const [update_enrolment_record, set_update_enrolment_record] = useState({});
  const [is_print_token_modal_visible, set_is_print_token_modal_visible] =
    useState(false);
  const [enrolled_id, set_enrolled_id] = useState(null);

  const [header_type, set_header_type] = useState("");
  const [record, set_record] = useState({});
  const [search_text, set_search_text] = useState({});
  const [searched_column, set_searched_column] = useState(null);
  const [field, set_value] = useState({ field: "", value: "" });
  const [reset_value, set_reset_value] = useState(false);
  const search_input = useRef(null);

  const [socket_details, set_socket_details] = useState();
  const [token_printer_response, set_token_printer_response] = useState("");
  const [enrollment_items, set_enrollment_items] = useState([]);
  const [is_loading, set_is_loading] = useState(false);

  const {
    status: set_enrolment_ticket_printed_status,
    error: set_enrolment_ticket_printed_error,
  } = useDynamicSelector("set_enrolment_ticket_printed");

  const {
    loading: get_enrolment_list_loading,
    error: get_enrolment_list_error,
    items: enrolment_list,
    pagination: enrolment_list_pagination,
  } = useDynamicSelector("get_enrolment_list");

  let socket;

  const role = retrieveItem("role");
  const session_id = retrieveItem("session_id");

  useEffect(() => {
    if (enrolment_list?.length > 0) {
      set_enrollment_items(enrolment_list);
    }
  }, [enrolment_list]);

  useEffect(() => {
    if (token_printer_response === "printing started") {
      TicketsPrinted();
      set_is_loading(false);
      close_token_printer_modal();
    } else if (
      token_printer_response === "tickets already printed" ||
      token_printer_response === "invalid enrolment status" ||
      token_printer_response === "error"
    ) {
      toast.error("Tickets already printed");

      set_is_loading(false);

      close_token_printer_modal();
    }
  }, [token_printer_response]);

  const TicketsPrinted = async () => {
    let key = [{ key: "set_enrolment_ticket_printed", loading: true }];
    let query = mutation_enrolment_ticket_print;
    let variables = {
      id: enrolled_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const open_token_printer_modal = (id) => {
    set_is_print_token_modal_visible(true);
    set_enrolled_id(id);
  };
  const close_token_printer_modal = () => {
    set_is_print_token_modal_visible(false);
  };

  const handle_search = (selectedKeys, confirm, data_index) => {
    confirm();
    set_reset_value(false);
    set_search_text({ ...search_text, [data_index]: selectedKeys[0] });
    set_value({ field: data_index, value: selectedKeys[0] });
    get_all_enrollment_list(data_index, selectedKeys[0]);
  };

  const handle_reset = (clearFilters, data_index) => {
    clearFilters();
    set_search_text({ ...search_text, [data_index]: "" });
    set_value({ field: "", value: "" });
    set_reset_value(true);
  };

  useEffect(() => {
    if (reset_value === true) {
      get_enrollment_list();
    }
  }, [reset_value]);

  const handle_cancel_booking = (record) => {
    set_header_type("cancel_booking");
    set_record(record);
    set_cancel_booking_modal(true);
  };

  const handle_re_print = (id) => {
    open_token_printer_modal(id);
  };

  const handle_unlock_printer = (record) => {
    set_header_type("unlock_printer");
    set_record(record);
    set_open_unlock_print_modal(true);
  };

  const on_update_enrolment = (record) => {
    set_update_modal_visible(true);
    set_update_enrolment_record(record);
  };

  const get_column_search_props = (data_index, customTitle) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={search_input}
          placeholder={`Search ${customTitle}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handle_search(selectedKeys, confirm, data_index)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handle_reset(clearFilters, data_index)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handle_search(selectedKeys, confirm, data_index)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : "white" }} />
    ),
    // onFilter: (value, record) =>
    //   record[data_index]
    //     ? record[data_index]
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase())
    //     : "",
    // onFilterDropdownVisibleChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => search_input.current?.select(), 100);
    //   }
    // },
    //   render: (text, record) => {
    //     return searched_column === data_index ? (
    //       <Highlighter
    //         highlightStyle={{
    //           backgroundColor: "#ffc069",
    //           padding: 0,
    //         }}
    //         searchWords={[search_text[data_index]]}
    //         autoEscape
    //         textToHighlight={text ? text.toString() : ""}
    //       />
    //     ) : data_index === "booking_no" ? (
    //       record.sequence_count?.count
    //     ) : data_index === "mandram" ? (
    //       record.booking.mandram.name
    //     ) : data_index === "arrived_count" ? (
    //       record.arrived_count
    //     ) : data_index === "amount" ? (
    //       getAmountFormatWithSymbol(record?.arrived_count * record?.booking.price)
    //     ) : data_index === "enrolled_by" ? (
    //       record.enrolled_user.name
    //     ) : data_index === "enrolled_by_mobile" ? (
    //       record.enrolled_user.mobile
    //     ) : data_index === "leader_mobile" ? (
    //       record.booking.booking_user.mobile
    //     ) : data_index === "payment_mode" ? (
    //       // record?.payment?.payment_mode ? (
    //       //   upperCase(record?.payment?.payment_mode)
    //       if (data?.payment?.payment_mode) {
    //         return toUpper(data?.payment?.payment_mode);
    //       } else if (data?.booking?.payment?.payment_mode) {
    //         toUpper(data?.booking?.payment?.payment_mode);
    //       },
    //       ) : (
    //         "-"
    //       )
    //     ) : data_index === "leader_name" ? (
    //       record.booking.booking_user.name
    //     ) : (
    //       text
    //     );
    //   },

    render: (text, record) => {
      console.log("record", record);
      return searched_column === data_index ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[search_text[data_index]]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : data_index === "booking_no" ? (
        `${record.sequence_count?.count}${record?.sequence_count?.suffix ?? ""}`
      ) : data_index === "booking_reference" ? (
        record?.booking?.booking_reference
      ) : data_index === "mandram" ? (
        // record.booking.mandram.name
        `${record?.booking?.mandram?.name}/${record?.booking?.mandram?.code}`
      ) : data_index === "devotees" ? (
        record?.arrived_count
      ) : data_index === "amount" ? (
        getAmountFormatWithSymbol(
          record?.arrived_count * record?.booking?.price
        )
      ) : data_index === "enrolled_by" ? (
        record?.enrolled_by?.name
      ) : data_index === "enrolled_by_mobile" ? (
        record?.enrolled_by.mobile
      ) : data_index === "leader_mobile" ? (
        record?.booking?.booking_user?.mobile
      ) : data_index === "payment_mode" ? (
        // record?.payment?.payment_mode ? (
        //   upperCase(record?.payment?.payment_mode)
        record?.payment?.payment_mode ||
        record?.booking?.payment?.payment_mode ? (
          toUpper(
            record?.payment?.payment_mode ||
              record?.booking?.payment?.payment_mode
          )
        ) : (
          "-"
        )
      ) : data_index === "leader_name" ? (
        record.booking.booking_user.name
      ) : (
        text
      );
    },
  });

  const columns = [
    {
      title: "Receipt No",
      key: "receipt_no",
      dataIndex: "enrolment_sequence_number",
      align: "left",
      fixed: "left",
      width: 150,
      render: (data) => data,
      ...get_column_search_props("booking_no", "Receipt No"),
      className: "custom-fixed-column",
    },
    {
      title: "Booking Number",
      key: "booking_reference",
      dataIndex: "booking_reference",
      width: 150,
      align: "center",
      render: (data) => data?.booking?.booking_reference,
      ...get_column_search_props("booking_reference", "Booking Number"),
    },
    {
      title: "Mandram(VSG)/Code",
      key: "mandram",
      align: "left",
      width: 150,
      render: (data) => data?.booking.mandram.name,
      ...get_column_search_props("mandram", "Mandram"),
    },
    {
      title: "Rate",
      key: "rate",
      align: "center",
      width: 80,
      render: (data) => getAmountFormatWithSymbol(data?.booking?.price),
    },
    {
      title: "Devotees",
      width: 100,
      key: "devotees",
      dataIndex: "devotees",
      align: "center",
      render: (data) => data?.arrived_count,
      ...get_column_search_props("devotees", "Devotees"),
    },
    {
      title: "Amount",
      width: 135,
      key: "amount",
      dataIndex: "amount",
      align: "end",
      render: (data) => data?.payment?.amount,
      ...get_column_search_props("amount", "Amount"),
    },
    {
      title: "Leader Name",
      key: "",
      dataIndex: "",
      align: "left",
      width: 150,
      render: (data) => data?.booking?.booking_user?.name,
      ...get_column_search_props("leader_name", "Leader Name"),
    },
    {
      title: "Leader Mobile",
      key: "",
      dataIndex: "",
      align: "center",
      width: 150,
      render: (data) => {
        return data?.booking?.booking_user?.mobile;
      },
      ...get_column_search_props("leader_mobile", "Leader No"),
    },
    {
      title: "Enrolled By",
      key: "",
      dataIndex: "",
      width: 150,
      align: "center",
      render: (data) => {
        console.log("enrolled_by", data?.enrolled_by);
        return data?.enrolled_by?.name;
      },

      ...get_column_search_props("enrolled_by", "Enrolled By"),
    },
    {
      title: "Enrolled Date",
      key: "enrolled_date",
      width: 150,
      align: "center",
      render: (date) =>
        moment(date?.enrolled_on).format("DD-MMM-YYYY hh:mm a").toUpperCase(),
    },
    {
      title: "Payment mode",
      key: "payment_mode",
      width: 150,
      align: "center",
      render: (data) => {
        if (data?.payment?.payment_mode) {
          return toUpper(data?.payment?.payment_mode);
        } else if (data?.booking?.payment?.payment_mode) {
          toUpper(data?.booking?.payment?.payment_mode);
        }
      },
      ...get_column_search_props("payment_mode", "Payment mode"),
    },
    {
      title: "Payment Status",
      key: "payment_status",
      width: 150,
      align: "center",
      render: (data) => (data?.status ? toUpper(data?.status) : "-"),
    },
    {
      title: "VIP Booking",
      key: "is_prepaid",
      width: 150,
      align: "center",
      render: (data) => {
        return data?.booking?.is_prepaid ? "Yes" : "No";
      },
    },
    {
      title: "District",
      key: "district",
      width: 150,
      align: "left",
      render: (data) => {
        return data?.booking?.booking_user_district?.name;
      },
    },
    // {
    //   title: "Foreign Booking",
    //   key: "is_prepaid",
    //   width: 150,
    //   align: "center",
    //   render: (data) => {
    //     return data?.booking?.is_foreign ? "Yes" : "No";
    //   },
    // },

    {
      title: "Action",
      data_index: "",
      key: "x",
      width: 150,
      align: "center",
      fixed: "right",
      render: (value, record) => (
        <Space
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {(role === "Spl Enrolment Officer" ||
            role === "MASM Admin" ||
            role === "Super Admin") && (
            <CustomButton
              onclick_button={() => handle_unlock_printer(record)}
              rounded={true}
              icon={<FaLockOpen />}
              bg_color="green"
            />
          )}
          {(!record?.is_tickets_printed ||
            record?.reprintable_ticket_list?.length > 0) && (
            <CustomButton
              onclick_button={() => handle_re_print(record?.id)}
              rounded={true}
              icon={<FaPrint />}
              bg_color="green"
            />
          )}
          {(role === "MASM User" ||
            role === "MASM Admin" ||
            role === "Super Admin") && (
            <CustomButton
              onclick_button={() => on_update_enrolment(record)}
              rounded={true}
              icon={<MdEdit />}
              bg_color="dodgerBlue"
            />
          )}
          {/* <CustomButton
            disabled={record?.status === "initiated" ? false : true}
            onclick_button={() => handle_cancel_booking(record)}
            rounded={true}
            icon={<ImCancelCircle />}
            bg_color="red"
          /> */}
        </Space>
      ),
    },
  ];

  const get_enrollment_list = () => {
    let keys = [{ key: "get_enrolment_list", loading: true }];
    let query = query_get_enrolment_details;
    let filter = {
      from_date: selected_date?.from_date,
      to_date: selected_date?.to_date,
      search: {
        field: field?.field || "",
        value: field?.value || "",
      },
    };
    if (reset_value) {
      delete filter?.search;
    }

    let variables = {
      pagination: {
        page_number: current_page || 1,
        page_limit: current_page_limit || 10,
      },
    };

    dispatch(dynamicRequest(keys, query, variables));
  };

  const fetch_data = (page_number, page_limit) => {
    set_current_page(page_number);
    set_current_page_limit(page_limit);
  };

  useEffect(() => {
    if (selected_date.from_date && selected_date.to_date) {
      get_enrollment_list();
    }
  }, [current_page, current_page_limit, selected_date]);

  return (
    <>
      <Table
        loading={get_enrolment_list_loading}
        data-table="static-header"
        dataSource={enrollment_items}
        columns={columns}
        style={{ marginTop: "20px", overflowY: "scroll" }}
        scroll={{ x: 1400, y: 400 }}
        pagination={false}
        className="custom-table-billing-history"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Pagination
          showTotal={(total, range) => `Total ( ${total} ) `}
          total={enrolment_list_pagination?.total_count}
          showSizeChanger={true}
          current={current_page}
          hideOnSinglePage={false}
          onChange={fetch_data}
        />
      </div>

      <ActionModal
        set_open_unlock_print_modal={set_open_unlock_print_modal}
        set_cancel_booking_modal={set_cancel_booking_modal}
        open_unlock_print_modal={open_unlock_print_modal}
        open_cancel_booking_modal={open_cancel_booking_modal}
        header_type={header_type}
        set_header_type={set_header_type}
        record={record}
        get_all_enrollment_list={get_all_enrollment_list}
      />

      <UpdateEnrollmentModal
        update_modal_visible={update_modal_visible}
        set_update_modal_visible={set_update_modal_visible}
        record={update_enrolment_record}
        get_all_enrollment_list={get_all_enrollment_list}
      />

      <Modal
        title={"Print Token"}
        open={is_print_token_modal_visible}
        onCancel={() => {
          // close_token_printer_modal();
        }}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={() => {
              close_token_printer_modal();
            }}
          >
            {"No"}
          </Button>,
          <Button
            loading={is_loading}
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={() => {
              send_response_to_printer_service(
                enrolled_id,
                session_id,
                set_is_loading,
                TicketsPrinted,
                close_token_printer_modal,
                set_token_printer_response
              );
            }}
          >
            {"Yes"}
          </Button>,
          ,
        ]}
      >
        <Typography>
          {
            "Your information saved successfully..! Do you want to print the receipt ?"
          }
        </Typography>
      </Modal>
    </>
  );
};

export default BillingHistoryTable;
