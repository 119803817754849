import { Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const VipBookingUserIdProofSection = ({ form }) => {
  const { t } = useTranslation();
  const idRegex = {
    "Aadhaar Card": /^\d{12}$/,
    Pancard: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
    "Driving License": /^[\w\d]{5,15}$/,
    "Bank Passbook": /^[\w\d]{5,20}$/,
    Passport: /^[A-Z0-9]{7,9}$/,
    "Ration Card": /^[\w\d]{8,15}$/,
    "Student ID": /^[\w\d]{6,12}$/,
    "Government ID": /^[\w\d]{8,20}$/,
  };

  const validateIdNumber = async (_, value) => {
    const selectedIdProof = form.getFieldValue("id_proof");
    const regex = idRegex[selectedIdProof];

    if (!value) {
      return Promise.reject(new Error(t("ID number is required!")));
    }
    if (regex && !regex.test(value)) {
      return Promise.reject(new Error(t(`Invalid ${selectedIdProof} number!`)));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    form.setFieldsValue({ id_proof: "Aadhaar Card" });
  },[]);

  return (
    <div className="vip_booking_form_item_container">
      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="id_proof"
          label={t("ID Proof Type")}
          rules={[
            { required: true, message: t("Please select ID proof type") },
          ]}
        >
          <Select>
            {Object.keys(idRegex).map((id, index) => (
              <Select.Option key={index} value={id}>
                {t(id)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="id_number"
          label={t("ID Number")}
          rules={[
            { required: true, message: t("Please enter your ID number") },
            { validator: validateIdNumber },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

export default VipBookingUserIdProofSection;
