import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useDynamicSelector } from "../../../../services/redux";

const BookingTime = (props) => {
  const { items, set_selected_time, selected_time, selected_date } = props;
  const { t } = useTranslation();
  const { datetime } = useDynamicSelector("get_current_date_time");
  const [categorized_time_slots, set_categorized_time_slots] = useState([]);

  console.log("categorized_time_slots", categorized_time_slots);

  useEffect(() => {
    if (datetime && selected_date) {
      let categorized_items = {
        Morning: [],
        Afternoon: [],
        Evening: [],
      };
      let is_future_date = moment(datetime)
        .startOf("day")
        .isBefore(moment(selected_date).startOf("day"));
      let current_minutes =
        moment(datetime).hours() * 60 + moment(datetime).minutes();
      items?.forEach((time, index) => {
        const time_category = categorize_time_slot(
          time.start_time_in_minutes,
          time.end_time_in_minutes
        );
        const is_past_time = is_future_date
          ? false
          : time.end_time_in_minutes < current_minutes;

        const categorized_time_slot = {
          ...time,
          index,
          is_past_time: is_past_time,
        };

        categorized_items[time_category].push(categorized_time_slot);
      });
      set_categorized_time_slots(categorized_items);
    }
  }, [datetime, selected_date, items]);

  const handle_time_click = (time) => {
    set_selected_time(time);
  };

  const minutes_to_time = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const formattedHours = hours % 12 || 12;
    const period = hours < 12 ? "AM" : "PM";

    return `${formattedHours < 10 ? `0${formattedHours}` : formattedHours}.${
      mins < 10 ? `0${mins}` : mins
    } ${period}`;
  };

  const categorize_time_slot = (start, end) => {
    const morning_start = 0;
    const morning_end = 660;
    const afternoon_start = 660;
    const afternoon_end = 960;

    if (start >= morning_start && end <= morning_end) {
      return "Morning";
    } else if (start >= afternoon_start && end <= afternoon_end) {
      return "Afternoon";
    } else {
      return "Evening";
    }
  };

  return (
    <div style={{ marginLeft: "5%" }}>
      <Row style={{ display: "flex", justifyContent: "center", width: "96%" }}>
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
        >
          {t("time_slot_message")}
        </Typography>
      </Row>
      <Form.Item name="time_slots">
        <Row
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            width: "98%",
          }}
          gutter={[10, 10]}
        >
          {Object.keys(categorized_time_slots).map((category) => {
            const slots = categorized_time_slots[category];
            return (
              slots &&
              slots.length > 0 && (
                <React.Fragment key={category}>
                  <Col style={{ flex: "0 0 100%", marginTop: "10px" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "16px" }}
                    >
                      {category}
                    </Typography>
                  </Col>
                  {slots.map((slot) => {
                    const is_selected = selected_time === slot.id;
                    return (
                      <Col key={slot.index} style={{ flex: "0 0 140px" }}>
                        <Button
                          onClick={() => {
                            if (!slot.is_past_time) {
                              handle_time_click(slot.id);
                            }
                          }}
                          style={{
                            borderRadius: "8px",
                            border: "transparent",
                            backgroundColor: slot.is_past_time
                              ? "#dbd9d9"
                              : is_selected
                              ? "#EC9501"
                              : "#960808",
                            color: "white",
                            width: "100%",
                          }}
                          disabled={slot.is_past_time}
                        >
                          <Typography style={{ color: "white" }}>
                            {`${minutes_to_time(
                              slot.start_time_in_minutes
                            )} - ${minutes_to_time(slot.end_time_in_minutes)}`}
                          </Typography>
                        </Button>
                      </Col>
                    );
                  })}
                </React.Fragment>
              )
            );
          })}
        </Row>
      </Form.Item>
    </div>
  );
};

export default BookingTime;
