import { Button, Form } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {
  create_system_otp_mutation,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";

const VipBookingSendOtpButton = ({ generate_otp_vip_user,form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: otp_loading } = useDynamicSelector("create_system_otp");

  const booking_user_mobile = Form.useWatch("booking_user_mobile", form);

  const handle_send_otp = () => {
    generate_otp_vip_user();
  };

  // const generate_otp_vip_user = () => {
  //   let key = [{ key: "create_system_otp", loading: true }];
  //   let query = create_system_otp_mutation;
  //   let variables = {
  //     data: {
  //       mobile: booking_user_mobile,
  //     },
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  return (
    <div>
      <Button
        onClick={handle_send_otp}
        loading={otp_loading}
        className="login_button"
        style={{
          backgroundColor: "#1e90ff",
          color: "white",
          marginBottom: "10px",
        }}
        disabled={booking_user_mobile ? false : true}
      >
        {t("send_otp")}
      </Button>
    </div>
  );
};

export default VipBookingSendOtpButton;
