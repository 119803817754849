import { Row, Col, Table } from "antd";
import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { dynamicRequest, query_user, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";

const AdminCard = ({ items, set_open, set_header_type }) => {
const dispatch=useDispatch();

const district_admin_details=useDynamicSelector("get_user")
console.log("district_admin_details",district_admin_details);

  const get_one_user = (record) => {
    let key = [{ key: "get_user", loading: true }];
    let query = query_user;
    let variables = {
      id: record?.system_user?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      width: "400px",
      render: (data) => {
        return data?.name;
      },
    },
    {
      title: "Username",
      key: "username",
      width: "400px",
      render: (data) => {
        return data?.system_user?.username;
      },
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      key: "mobile",
      width: "400px",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "400px",
      align: "left",
      render: (text, record) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "400px",
      render: (text, record) => (
        <span>
          <EditOutlined onClick={() => handle_edit(record)} />
        </span>
      ),
    },
  ];

  const handle_edit = (record) => {
    console.log("record", record);
    get_one_user(record);
    set_header_type("edit_admin");
    set_open(true);
  };

  return (
    <Table
      columns={columns}
      style={{ width: "100%", backgroundColor: "red !important" }}
      dataSource={items}
      pagination={false}
      className="admin-card-table"
    />
  );
};

export default AdminCard;
