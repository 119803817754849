import React from "react";
import { Form, Switch } from "antd";

const InputIsActive = () => {
  return (
   
        <Form.Item name="is_active" label="Activate District" valuePropName="checked"
        style={{ marginTop: "20px", marginRight: "10px", width: "500px" }}
        >
        <Switch defaultChecked={false} />
      </Form.Item>
  );
};

export default InputIsActive;
