import { gql } from "@apollo/client";

export const query_get_all_sub_district = gql`
  query get_sub_district_list(
    $filter: get_sub_district_list_filter_input
    $pagination: pagination_input
    $sort: sort_input
  ) {
    get_sub_district_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        name
        tamil_name
        code
        group_id
        order_number
        is_vip
        is_foreign
        extra_allowed_percentage
        is_active
        admin {
          name
          mobile
          email
          password
          system_user {
            id
            username
            name
            mobile
          }
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const query_get_one_sub_district = gql`
  query get_sub_district($id: String!) {
    get_sub_district(id: $id) {
      id
      name
      tamil_name
      code
      group_id
      order_number
      extra_allowed_percentage
      is_vip
      is_foreign
      is_active
      district {
        id
        name
      }
      admin {
        name
        mobile
        email
        password
        is_active
        system_user {
          username
          is_active
        }
      }
    }
  }
`;

export const query_get_sub_district_list = gql`
  query get_sub_district_list(
    $filter: get_sub_district_list_filter_input
    $pagination: pagination_input
    $sort: sort_input
  ) {
    get_all_sub_district_list: get_sub_district_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        name
        tamil_name
        code
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const query_get_one_district = gql`
  query get_sub_district($id: String!) {
    get_sub_district(id: $id) {
      id
      name
      tamil_name
      code
      group_id
      order_number
      extra_allowed_percentage
      is_vip
      is_foreign
      is_active
      district {
        id
        name
      }
      admin {
        name
        mobile
        email
        password
        system_user {
          username
        }
      }
    }
  }
`;

export const query_get_one_sub_district_user = gql`
  query get_sub_district($id: String!) {
    get_sub_district_user: get_sub_district(id: $id) {
      id
      admin {
        name
        mobile
        email
        password
        system_user {
          id
          username
          name
          email
        }
      }
    }
  }
`;

export const mutation_create_sub_district = gql`
  mutation create_sub_district($data: create_sub_district_input!) {
    create_sub_district(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_sub_district = gql`
  mutation update_sub_district(
    $id: String!
    $data: update_sub_district_input!
  ) {
    update_sub_district(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_sub_district = gql`
  mutation delete_sub_district($id: String!) {
    delete_sub_district(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_district_details_query = gql`
  query get_sub_district_list(
    $filter: get_district_list_filter_input
    $sort: sort_input
  ) {
    get_district_values: get_sub_district_list(filter: $filter, sort: $sort) {
      items {
        id
        name
      }
    }
  }
`;

export const district_details_query = gql`
  query get_district_list(
    $filter: get_district_list_filter_input
    $sort: sort_input
  ) {
    district_list: get_district_list(filter: $filter, sort: $sort) {
      items {
        name
        id
      }
    }
  }
`;

export const query_get_sub_district_users_list = gql`
  query get_sub_district_user_list(
    $filter: get_sub_district_user_list_filter_input
  ) {
    get_sub_district_user_list(filter: $filter) {
      items {
        name
        mobile
        email
        password
        system_user {
          id
          username
          name
          email
          mobile
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_district_user = gql`
  mutation create_district_user($data: create_district_user_input) {
    create_district_user(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_district_user = gql`
  mutation update_district_user(
    $id: String!
    $data: update_district_user_input
  ) {
    update_district_user(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_district_user = gql`
  mutation delete_district_user($id: String!) {
    delete_district_user(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
