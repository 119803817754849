import React, { useState } from "react";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { RiMenuFold2Fill, RiVipCrownFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { clearLocalStorage, navigate } from "../../../helpers/functions";
import { IoClose } from "react-icons/io5";
import { startCase } from "lodash";
import { CgProfile } from "react-icons/cg";
import { SIDE_BAR_IMAGE } from "../../../helpers/image_constants";
import { Popover, Pressable } from "native-base";
import { Drawer, Image } from "antd";
import { dynamicClear } from "../../../services/redux";

const VipUserLoginHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location?.pathname;
  const [menu_open, set_menu_open] = useState(false);
  const [is_drawer_open, set_is_drawer_open] = useState(false);

  const handleMenuToggle = () => {
    set_menu_open(!menu_open);
  };
  const handle_logout = () => {
    clearLocalStorage();
    dispatch(dynamicClear("login"));
    navigate("/vip-login");
  };

  const drawer_click = () => {
    set_is_drawer_open(true);
  };

  const drawer_close = () => {
    set_is_drawer_open(false);
  };

  const drawer_options = [
    {
      id: "vip_booking_form",
      value: "vip_booking_form",
      icon: <RiVipCrownFill />,
    },
    {
      id: "vip_booking_history",
      value: "vip_booking_history",
      icon: <MdOutlineSpeakerNotes />,
    },
  ];

  const handle_menu_click = (menu) => {
    console.log("menu", menu);

    if (menu?.id === "vip_booking_history") {
      navigate("/vip-booking-history");
    } else if (menu?.id === "vip_booking_form") {
      navigate("/vip-booking-users");
    }
  };

  return (
    <div className="login_header">
      <div className="header_container">
        {pathname !== "/login" && pathname !== "/" ? (
          <RiMenuFold2Fill
            color="#3c3c3c"
            className="logout_logo"
            onClick={drawer_click}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <div></div>
        )}
        {pathname !== "/login" && pathname !== "/" && <div></div>}
        <Drawer
          placement="left"
          open={is_drawer_open}
          className="mobile-drawer-vip"
          onClose={drawer_close}
          width={"300px"}
          bodyStyle={{
            backgroundImage: `url(${SIDE_BAR_IMAGE})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <Image
              src={require("../../../assets/icons/Masm_logo.png")}
              alt="Adhiparasakthi Image"
              className="logo_image_drawer"
              preview={false}
            />
            <text className="header_text_drawer">
              ADHIPARASAKTHI SIDDHAR PEEDAM
            </text>
            <IoClose className="drawer-close-icon" onClick={drawer_close} />
          </div>
          <hr></hr>
          <div>
            {drawer_options?.map((menu) => (
              <div
                key={menu?.id}
                style={{
                  display: "flex",

                  cursor: "pointer",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                }}
                onClick={() => handle_menu_click(menu)}
              >
                <span
                  style={{
                    color: "#fff",
                    fontSize: "25px",
                  }}
                >
                  {menu.icon}
                </span>
                <p
                  key={menu?.id}
                  value={menu?.value}
                  className="vip-drawer-menus-title-text"
                  onClick={() => handle_menu_click(menu)}
                >
                  {startCase(menu?.value)}
                </p>
              </div>
            ))}
          </div>
        </Drawer>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={require("../../../assets/icons/Masm_logo.png")}
            alt="Adhiparasakthi Image"
            className="logo_image"
            preview={false}
          />

          <div
            alignItems="center"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <text className="header_text">ADHIPARASAKTHI SIDDHAR PEEDAM</text>
            <text className="header_subtext">
              Melmaruvathur, Kanchipuram District, Tamilnadu,
            </text>
            <text className="header_subtext">India-603319</text>
          </div>
        </div>
        {pathname !== "/vip-login" && pathname !== "/" ? (
          <Popover
            isOpen={menu_open}
            onClose={() => set_menu_open(false)}
            trigger={(triggerProps) => (
              <Pressable {...triggerProps} onPress={handleMenuToggle}>
                <CgProfile color="#3c3c3c" className="side_bar" />
              </Pressable>
            )}
          >
            <Popover.Content accessibilityLabel="User Menu" width="150px">
              <Popover.Body>
                <text
                  variant="ghost"
                  colorScheme="danger"
                  onClick={handle_logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </text>
              </Popover.Body>
            </Popover.Content>
          </Popover>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default VipUserLoginHeader;
