import { Form, Switch } from "antd";
import React from "react";

const InputSubDistrictUserActive = () => {
  return (
    <Form.Item name="is_active" label="Active User" valuePropName="checked">
      <Switch defaultChecked={false} />
    </Form.Item>
  );
};

export default InputSubDistrictUserActive;
