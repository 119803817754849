import { Form, Switch } from 'antd'
import React from 'react'

const InputSubDistrictAdminIsActive = () => {
  return (
    <Form.Item
    name={["sub_district_admin", "is_active"]}
    label="Active Admin"
    valuePropName="checked"
  >
    <Switch defaultChecked={false} />
  </Form.Item>
  )
}

export default InputSubDistrictAdminIsActive