import { gql } from "@apollo/client";
export const query_get_season_date_slot_list = gql`
  query get_season_date_slot_list(
    $pagination: pagination_input
    $sort: sort_input
  ) {
    get_season_date_slot_list(pagination: $pagination, sort: $sort) {
      items {
        id
        date
        date_string
        confirmed_quota
        waiting_quota
        confirmed_count
        waiting_count
        date_string
        season {
          ticket_price
          vip_ticket_price
        }
        time_slots {
          id
          start_time_in_minutes
          end_time_in_minutes
        }
      }
    }
  }
`;

export const mutation_create_booking = gql`
  mutation create_booking($data: create_booking_input) {
    create_booking(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const query_get_booking_user_details = gql`
  query get_booking_user_details($custom: get_booking_user_input) {
    get_booking_user_details(custom: $custom) {
      name
      mobile
      email
      address
      id_type
      id_number
      id
    }
  }
`;

export const mutation_pay_for_booking = gql`
  mutation pay_for_booking($data: booking_payment_input) {
    pay_for_booking(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_check_order_status = gql`
  query check_order_status($id: String!) {
    check_order_status(id: $id) {
      order_id
      booking_id
      enrolment_id
      status
      error {
        message
      }
    }
  }
`;

export const query_get_mandram_confirmed_waiting_count_summary = gql`
  query get_mandram_confirmed_waiting_count_summary(
    $filter: mandram_confirmed_waiting_count_summary_input
  ) {
    get_mandram_confirmed_waiting_count_summary(filter: $filter) {
      confirmed_count
      waiting_count
    }
  }
`;
export const query_get_booking_count = gql`
  query get_booking_count($filter: booking_count_filter_input) {
    get_booking_count(filter: $filter) {
      total_confirmed_count
      total_waiting_count
    }
  }
`;

export const query_check_has_pos = gql`
  query check_has_pos($data: check_has_pos_input) {
    check_has_pos(data: $data) {
      result
    }
  }
`;

export const query_check_has_pos_for_enroll = gql`
  query check_has_pos($data: check_has_pos_input) {
    check_pos_connect: check_has_pos(data: $data) {
      result
    }
  }
`;
export const query_current_date_time = gql`
  query get_current_date_time {
    get_current_date_time {
      datetime
    }
  }
`;

export const query_yet_to_enroll = gql`
  query get_date_yet_to_enroll_count($date_slot_id: String!) {
    get_date_yet_to_enroll_count(date_slot_id: $date_slot_id) {
      items {
        district_name
        yet_to_enroll_count
      }
    }
  }
`;

export const query_get_asynchronous_request = gql`
  query get_asynchronous_request($id: String!) {
    get_asynchronous_request(id: $id) {
      id
      status
      response
    }
  }
`;

export const query_asynchronous_check_request = gql`
  query get_asynchronous_request($id: String!) {
    asynchronous_check_request: get_asynchronous_request(id: $id) {
      id
      status
      response
    }
  }
`;
