import UserLayout from "../layouts/user_layout";
import District from "../pages/district";
import IrumudiCalendar from "../pages/irumudi_calendar";
import Notifications from "../pages/notification";
import Receipt from "../pages/receipt";
import POS from "../pages/pos";
import TodayDashboard from "../pages/today_dashboard";
import Group from "../pages/group";
import Counter from "../pages/counter";
import MandramVsg from "../pages/mandram_vsg";
// import TimeManage from "../pages/time_slots";
import DistrictAdministration from "../pages/district_administration";
import BookingReport from "../pages/booking_report";
import DayWiseReport from "../pages/day_wise_report";
import OmSakthiReport from "../pages/om_sakthi_report";
import DailyTransactionReport from "../pages/daily_transaction_report";
import AccountsReport from "../pages/accounts_report";
import BookingHistory from "../pages/booking_history";
import SpotBooking from "../pages/spot_booking";
import GroupWiseReport from "../pages/group_wise_report";
// import PaymentMode from "../pages/payment_mode";
import PaymentModeReport from "../pages/payment_mode_report";
import DevoteePaymentReport from "../pages/devotee_payment_report";
import Enroll from "../pages/enroll";
import FeedBackManage from "../pages/feedback_manage";
import Booking from "../pages/bookings";
// import SecurityCode from "../pages/security_code_setting";
import SystemUsers from "../pages/system_users";
import BillingHistory from "../pages/billing_history";
import LoginPage from "../pages/login";
import UserResponse from "../pages/user_response";
import Feedback from "../pages/feedback";
import PrintContent from "../components/receipt/PDF/print_content";
import FeedbackManagePage from "../pages/feedback_manage_question";
import Bank from "../pages/bank";
// import AdditionalUsers from "../pages/additional_users";
import BookingAvailabilityDateslotsPage from "../pages/season_date_slots";
import BankEnrolmentOfficer from "../pages/bank_enrolment_officer";
import MandramsExcelImport from "../pages/mandram_list_excel_upload";
import VipBooking from "../pages/vip_booking";
import DashboardUser from "../pages/dashboard_user";
import EnrolmentOfficer from "../pages/enrolment_officer";
import HelpdeskOfficer from "../pages/helpdesk_officer";
import SplEnrolmentOfficer from "../pages/spl_enrolment_officer";
import VipEnrolmentOfficer from "../pages/vip_enrolment_officer";
import MasmUser from "../pages/masm_user";
import DistrictWiseReport from "../pages/district_wise_report";
import CounterPaymentReport from "../pages/counter_payment_report";
import CounterUserWiseReport from "../pages/counter_user_wise_report";
import SakthiPeedamReport from "../pages/sakthi_peedam_report";
import AccessRights from "../pages/access_rights";
import UpdateProfile from "../pages/update_profile";
import AdditionalUsers from "../pages/additional_users";
import Logout from "../pages/logout";
import Season from "../pages/season";
import SeasonDateSlots from "../pages/season_date_slots";
import Reports from "../pages/reports";
import DateWiseReports from "../pages/date_wise_report";
import CounterGroup from "../pages/counter_group";
import Dashboard from "../pages/dashboard";
import DistrictDashboard from "../pages/district_dashboard";
import BookingAction from "../pages/booking_action";
import MandramWiseCount from "../pages/mandramwise_count";
import AddressWiseReport from "../pages/address_report";
import TermsOfServices from "../pages/term_of_service";
import PrivacyPolicy from "../pages/privacy_policy";
import SendEmail from "../pages/send_email";
import CustomLayout from "../layouts/custom_layout";
import UserDashboard from "../components/dashboard/user_dashboard";
import EnrolmentActionUser from "../pages/enrolment_action_user";
import EnrollAction from "../pages/enroll_action";
import VipEnrollmentList from "../pages/vip_enrollment_list";
import UserGroup from "../pages/user_group";
import EnrolmentSession from "../pages/enrolment_session";
import EnrolmentSessionHistory from "../components/enrolment_session/enrolment_session_list/enrolment_session_history_details";
import UsersGroupListTable from "../components/user_group/user_group_list_table";
import BankBranchEnrolmentOfficer from "../pages/bank_branch_enrolment_officer";
import UserCounterWiseReport from "../pages/user_counter_wise_report";
import CounterGroupWiseReport from "../pages/counter_group_wise_report";
import UserGroupWiseReport from "../pages/user_group_wise_report";
import DashboardAdmin from "../pages/dashboard_admin";
import SmsDashboard from "../pages/sms_dashboard";
import DateWiseBookingReport from "../pages/date_wise_booking_report";
import ExtraAllowedPercentage from "../pages/extra_allowed_percentage";
import SubDistrict from "../pages/sub_district";
import MandramRenewal from "../pages/mandram_renewal";
import SubDistrictDashboard from "../pages/sub_district_dashboard";
import VipBookingUsers from "../pages/vip_booking_users";
import VipBookingHistory from "../pages/vip_booking_history";
import PaymentGateWayPage from "../pages/payment_gate_way_page";
import PaymentSuccessPage from "../pages/payment_success_page";
import PaymentFailed from "../pages/payment_failed";
import PaymentLinkExpired from "../pages/payment_link_expired";
import PaymentRedirect from "../pages/payment_redirect";
import PaymentStatus from "../pages/payment_status";
import VipBookingUserPaymentResult from "../pages/vip_booking_user_payment_result";
import VipLoginPortalUser from "../pages/vip_login_portal_user";

export const ROUTES = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  DISTRICT: "/district",
  SUBDISTRICT: "/sub-district",
  IRUMUDI_CALENDAR: "/irumudi-calendar",
  NOTIFICATIONS: "/notifications",
  RECEIPT: "/receipt",
  OVERALL_DASHBOARD: "/overall-dashboard",
  SMS_DASHBOARD: "/sms-dashboard",
  TODAY_DASHBOARD: "/today-dashboard",
  DASHBOARD: "/dashboard",
  GROUP: "/group",
  COUNTER: "/counter",
  COUNTER_GROUP: "/counter-group",
  MANDRAM: "/mandram-vsg",
  // MANAGING_PROVISIONS: "/managing-provisions",
  // TIME_MANAGE: "/time-slot",
  SEASON: "/season",
  DISTRICT_ADMINISTRATION: "/district-administration",
  SAKTHI_PEEDAM_REPORTS: "/sakthi-peedam-report",
  OM_SAKTHI_REPORT: "/om-sakthi-report",
  DAY_WISE_REPORT: "/day-wise-report",
  TRANSACTION_REPORT: "/transaction-report",
  ACCOUNTS_REPORT: "/accounts-report",
  GROUP_WISE_REPORT: "/group-wise-report",
  PAYMENT_MODE_REPORT: "/payment-mode-report",
  DEVOTEE_PAYMENT_REPORT: "/devotee-payment-report",
  BILLING_HISTORY: "/billing-history",
  BOOKING_HISTORY: "/booking-history",
  SPOT_BOOKING: "/spot-booking",
  DAY_WISE_TRANSACTIONS: "/day-wise-transactions",
  BANK: "/bank",
  PAYMENT_MODE: "/payment-mode",
  BOOKING: "/booking",
  ENROLL: "/enroll",
  FEEDBACK_MANAGE: "/feedback-manage",
  // SECURITY_CODE_SETTING: "/security-code",
  SYSTEM_USERS: "/system-user",
  USER_RESPONSE: "/user-response",
  FEEDBACK: "/feedback",
  PRINT_RECEIPT: "/print-receipt",
  LOGIN_PRINT_RECEIPT: "/print-receipt",
  FEEDBACK_QUESTION: "/feedback-question",
  ADDITIONAL_USERS: "/additional-users",
  EXCEL_IMPORT: "/upload-mandrams",
  BOOKING_AVAILABILITY_DATE_SLOTS: "/season-date-slots",
  VIP_BOOKING: "/vip-booking",
  BANK_ENROLMENT_OFFICER: "/bank-enrolment-officer",
  VIP_BOOKING: "/vip-booking",
  DASHBOARD_USER: "/dashboard-user",
  DASHBOARD_ADMIN: "/dashboard-admin",
  ENROLMENT_OFFICER: "/enrolment-officer",
  HELPDESK_OFFICER: "/helpdesk-officer",
  SPL_ENROLMENT_OFFICER: "/spl-enrolment-officer",
  VIP_ENROLMENT_OFFICER: "/vip-enrolment-officer",
  MASM_USER: "/masm-user",
  DISTRICT_WISE_REPORT: "/district-wise-report",
  COUNTER_PAYMENT_REPORT: "/counter-payment-report",
  COUNTER_USER_WISE_REPORT: "/counter-user-wise-report",
  REPORTS: "/reports",
  ACCESS_RIGHTS: "/access-rights",
  UPDATE_PROFILE: "/profile",
  DATE_WISE_REPORT: "/date-wise-report",
  ACTIVE_DATE_SLOTS: "/active-date-slots",
  DISTRICT_DASHBOARD: "/district-dashboard",
  POS: "/Pos",
  BOOKING_ACTION: "/booking-action",
  MANDRAM_WISE_COUNT: "/mandram-wise-count",
  ADDRESS_WISE_REPORT: "/address-wise-report",
  TERMS_OF_SERVICES: "/terms-of-service",
  PRIVACY_POLICY: "/privacy-policy",
  SEND_EMAIL: "/send-email",
  ENROLMENT_ACTION_USER: "/enrolment-action-user",
  ENROLL_ACTION: "/enroll-action",
  VIP_ENROLLMENT_LIST: "/vip-enrollment-list",
  USER_GROUP: "/user-group",
  ENROLMENT_SESSION: "/enrolment-session",
  ENROLMENT_SESSION_HISTORY_DETAILS: "/enrolment-session-history",
  USERS_GROUP_LIST: "/group-users",
  BANK_BRANCH_ENROLMENT_OFFICER: "/bank-branch-enrolment-officer",
  USER_COUNTER_WISE_REPORT: "/user-counter-wise-report",
  COUNTER_GROUP_WISE_REPORT: "/counter-group-wise-report",
  USER_GROUP_WISE_REPORT: "/user-group-wise-report",
  BOOKING_REPORT: "/booking-report",
  DATE_WISE_BOOKING_REPORT: "/date-wise-booking-report",
  EXTRA_ALLOWED_PERCENTAGE: "/general-settings",
  MANDRAM_RENEWAL: "/mandram-renewal",
  SUB_DISTRICT_DASHBOARD: "/sub-district-dashboard",
  VIP_BOOKING_USERS: "/vip-booking-users",
  VIP_BOOKING_HISTORY: "/vip-booking-history",
  PAYMENT_GATE_WAY: "/online-booking",
  PAYMENT_SUCCESS_APPRECIATION: "/payment-success",
  PAYMENT_FAILED: "/payment-failed",
  PAYMENT_LINK_EXPIRED: "/payment-link-expired",
  PAYMENT_REDIRECT: "/payment",
  PAYMENT_STATUS: "/payment-status",
  VIP_BOOKING_USER_PAYMENT_RESULT: "/payment-result",
  VIP_LOGIN_PORTAL_USER: "/vip-login",
};

const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: LoginPage,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    key: "login",
    component: LoginPage,
    authenticate: false,
  },

  {
    path: ROUTES.LOGOUT,
    key: "logout",
    component: Logout,
    authenticate: false,
  },
  {
    path: ROUTES.TERMS_OF_SERVICES,
    key: "service",
    component: TermsOfServices,
    authenticate: false,
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    key: "service",
    component: PrivacyPolicy,
    authenticate: false,
  },

  {
    key: "User Dashboard",
    component: CustomLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.DASHBOARD,
        exact: true,
        key: "User Dashboard",
        component: UserDashboard,
        authenticate: true,
      },
    ],
  },

  {
    key: "Reports",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.REPORTS,
        key: "Reports",
        component: Reports,
        authenticate: true,
      },
    ],
  },
  {
    key: "Irumudi Calendar",
    component: UserLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.IRUMUDI_CALENDAR,
        key: "Irumudi Calendar",
        component: IrumudiCalendar,
        authenticate: false,
      },
    ],
  },

  {
    path: ROUTES.VIP_BOOKING_USERS,
    key: "vip-booking-users",
    component: VipBookingUsers,
    authenticate: false,
  },

  {
    path: ROUTES.VIP_LOGIN_PORTAL_USER,
    key: "vip-login",
    component: VipLoginPortalUser,
    authenticate: false,
  },
  {
    path: ROUTES.VIP_BOOKING_HISTORY,
    key: "vip-booking-history",
    component: VipBookingHistory,
    authenticate: false,
  },

  {
    path: ROUTES.PAYMENT_SUCCESS_APPRECIATION,
    key: "payment-success",
    component: PaymentSuccessPage,
    authenticate: false,
  },

  {
    path: `${ROUTES.PAYMENT_STATUS}/:id?`,
    key: "payment-status",
    component: PaymentStatus,
    authenticate: false,
  },
  {
    path: `${ROUTES.VIP_BOOKING_USER_PAYMENT_RESULT}/:id?`,
    key: "payment-result",
    component: VipBookingUserPaymentResult,
    authenticate: false,
  },
  {
    path: ROUTES.PAYMENT_FAILED,
    key: "payment-failed",
    component: PaymentFailed,
    authenticate: false,
  },
  {
    path: ROUTES.PAYMENT_LINK_EXPIRED,
    key: "payment-link-expired",
    component: PaymentLinkExpired,
    authenticate: false,
  },
  {
    path: `${ROUTES.PAYMENT_REDIRECT}/:id?`,
    key: "payment",
    component: PaymentRedirect,
    authenticate: false,
  },
  {
    path: ROUTES.PAYMENT_GATE_WAY,
    key: "online-booking",
    component: PaymentGateWayPage,
    authenticate: false,
  },

  {
    key: "Enrolment Session",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ENROLMENT_SESSION,
        key: "Enrolment Session",
        component: EnrolmentSession,
        authenticate: true,
      },
      {
        path: `${ROUTES.ENROLMENT_SESSION_HISTORY_DETAILS}/:id?`,
        key: "Enrolment Session",
        component: EnrolmentSessionHistory,
        authenticate: true,
      },
    ],
  },
  {
    key: "Profile",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.UPDATE_PROFILE,
        key: "Profile",
        component: UpdateProfile,
        authenticate: true,
      },
    ],
  },
  {
    key: "Active Season Date Slots",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ACTIVE_DATE_SLOTS,
        exact: true,
        key: "Active Season Date Slots",
        component: BookingAvailabilityDateslotsPage,
        authenticate: true,
      },
    ],
  },

  {
    key: "Manage District  ",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.DISTRICT,
        exact: true,
        key: "Manage District  ",
        component: District,
        authenticate: true,
      },
      // {
      //   path: ROUTES.MANDRAM,
      //   exact: true,
      //   key: "Manage District  ",
      //   component: MandramVsg,
      //   authenticate: true,
      // },
    ],
  },
  {
    key: "Sub District",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: `${ROUTES.SUBDISTRICT}/:id?`,
        exact: true,
        key: "Sub District",
        component: SubDistrict,
        authenticate: true,
      },
    ],
  },
  {
    key: "Manage Mandram",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.MANDRAM,
        exact: true,
        key: "Manage Mandram",
        component: MandramVsg,
        authenticate: true,
      },
    ],
  },
  {
    key: "Mandramwise Count",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.MANDRAM_WISE_COUNT,
        exact: true,
        key: "Mandramwise Count",
        component: MandramWiseCount,
        authenticate: true,
      },
    ],
  },

  {
    key: "Enroll Action",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ENROLL_ACTION,
        exact: true,
        key: "Enroll Action",
        component: EnrollAction,
        authenticate: true,
      },
    ],
  },
  {
    key: "Settings",
    component: UserLayout,
    authenticate: true,
    accessKey: "Settings",
    children: [
      {
        path: ROUTES.SEASON,
        exact: true,
        key: "Settings",
        component: Season,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: ROUTES.BANK,
        exact: true,
        key: "Settings",
        component: Bank,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: ROUTES.COUNTER,
        exact: true,
        key: "Settings",
        component: Counter,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: ROUTES.COUNTER_GROUP,
        exact: true,
        key: "Settings",
        component: CounterGroup,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: ROUTES.USER_GROUP,
        exact: true,
        key: "Settings",
        component: UserGroup,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: ROUTES.GROUP,
        exact: true,
        key: "Settings",
        component: Group,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: ROUTES.NOTIFICATIONS,
        exact: true,
        key: "Settings",
        component: Notifications,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: `${ROUTES.BOOKING_AVAILABILITY_DATE_SLOTS}/:id?/:title`,
        exact: true,
        key: "Settings",
        component: BookingAvailabilityDateslotsPage,
        authenticate: true,
        accessKey: "Settings",
      },
      {
        path: `${ROUTES.USERS_GROUP_LIST}/:id?`,
        exact: true,
        key: "Settings",
        component: UsersGroupListTable,
        authenticate: true,
        accessKey: "Settings",
      },
    ],
  },
  {
    key: "Booking",
    component: UserLayout,
    authenticate: true,
    accessKey: "Booking",
    children: [
      {
        path: ROUTES.BOOKING,
        exact: true,
        key: "Booking",
        component: Booking,
        authenticate: true,
        accessKey: "Booking",
      },
    ],
  },
  {
    key: "VIP Booking",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.VIP_BOOKING,
        exact: true,
        key: "VIP Booking",
        component: VipBooking,
        authenticate: true,
        accessKey: "VIP Booking",
      },
    ],
  },
  {
    key: "VIP Enrollment List",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.VIP_ENROLLMENT_LIST,
        exact: true,
        key: "VIP Enrollment List",
        component: VipEnrollmentList,
        authenticate: true,
      },
    ],
  },
  {
    key: "System Users",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.SYSTEM_USERS,
        exact: true,
        key: "System Users",
        component: SystemUsers,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.BANK_ENROLMENT_OFFICER,
        exact: true,
        key: "System Users",
        component: BankEnrolmentOfficer,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.BANK_BRANCH_ENROLMENT_OFFICER,
        exact: true,
        key: "System Users",
        component: BankBranchEnrolmentOfficer,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.DASHBOARD_USER,
        exact: true,
        key: "System Users",
        component: DashboardUser,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.DASHBOARD_ADMIN,
        exact: true,
        key: "System Users",
        component: DashboardAdmin,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.ENROLMENT_OFFICER,
        exact: true,
        key: "System Users",
        component: EnrolmentOfficer,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.HELPDESK_OFFICER,
        exact: true,
        key: "System Users",
        component: HelpdeskOfficer,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.SPL_ENROLMENT_OFFICER,
        exact: true,
        key: "System Users",
        component: SplEnrolmentOfficer,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.VIP_ENROLMENT_OFFICER,
        exact: true,
        key: "System Users",
        component: VipEnrolmentOfficer,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.MASM_USER,
        exact: true,
        key: "System Users",
        component: MasmUser,
        authenticate: true,
        accessKey: "System Users",
      },
      {
        path: ROUTES.ENROLMENT_ACTION_USER,
        exact: true,
        key: "System Users",
        component: EnrolmentActionUser,
        authenticate: true,
        accessKey: "System Users",
      },
    ],
  },
  {
    key: "Access Rights",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ACCESS_RIGHTS,
        exact: true,
        key: "Access Rights",
        component: AccessRights,
        authenticate: true,
      },
    ],
  },
  {
    key: "Booking Summary",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.BOOKING_HISTORY,
        exact: true,
        key: "Booking Summary",
        component: BookingHistory,
        authenticate: true,
      },
    ],
  },
  {
    key: "Spot Booking",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.SPOT_BOOKING,
        exact: true,
        key: "Spot Booking",
        component: SpotBooking,
        authenticate: true,
      },
    ],
  },

  {
    key: "Booking Action",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.BOOKING_ACTION,
        exact: true,
        key: "Booking Action",
        component: BookingAction,
        authenticate: true,
      },
    ],
  },
  {
    path: `${ROUTES.LOGIN_PRINT_RECEIPT}/:receipt_no?/:mobile_number/:is_duplicate?`,
    exact: true,
    key: "login_Receipt",
    component: PrintContent,
    authenticate: false,
  },
  {
    key: "Receipt",
    component: UserLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.RECEIPT,
        exact: true,
        key: "Receipt",
        component: Receipt,
        authenticate: true,
      },
    ],
  },
  {
    path: `${ROUTES.PRINT_RECEIPT}/:receipt_no?/:mobile_number?/:is_duplicate?`,
    exact: true,
    key: "Print-receipt",
    component: PrintContent,
    authenticate: false,
  },
  {
    key: "Manage Feedback",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.FEEDBACK_MANAGE,
        exact: true,
        key: "Manage Feedback",
        component: FeedBackManage,
        authenticate: true,
        accessKey: "Manage Feedback",
      },
      {
        path: ROUTES.USER_RESPONSE,
        exact: true,
        key: "Manage Feedback",
        component: UserResponse,
        authenticate: true,
        accessKey: "Manage Feedback",
      },
      {
        path: `${ROUTES.FEEDBACK_QUESTION}/:id?/:title`,
        exact: true,
        key: "Manage Feedback",
        component: FeedbackManagePage,
        authenticate: true,
        accessKey: "Manage Feedback",
      },
    ],
  },
  {
    key: "Feedback",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.FEEDBACK,
        exact: true,
        key: "Feedback",
        component: Feedback,
        authenticate: true,
        accessKey: "Feedback",
      },
    ],
  },
  {
    key: "Enroll",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ENROLL,
        exact: true,
        key: "Enroll",
        component: Enroll,
        authenticate: true,
        accessKey: "Enroll",
      },
    ],
  },
  {
    key: "System Reports",
    component: UserLayout,
    authenticate: true,
    children: [
      // {
      //   path: ROUTES.BOOKING_REPORT,
      //   exact: true,
      //   key: "System Reports",
      //   component: BookingReport,
      //   authenticate: true,
      //   accessKey: "System Reports",
      // },
      {
        path: ROUTES.SAKTHI_PEEDAM_REPORTS,
        exact: true,
        key: "System Reports",
        component: SakthiPeedamReport,
        authenticate: true,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.OM_SAKTHI_REPORT,
        exact: true,
        key: "System Reports",
        component: OmSakthiReport,
        authenticate: true,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.DAY_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: DayWiseReport,
        authenticate: true,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.TRANSACTION_REPORT,
        exact: true,
        key: "System Reports",
        component: DailyTransactionReport,
        authenticate: true,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.PAYMENT_MODE_REPORT,
        exact: true,
        key: "System Reports",
        component: PaymentModeReport,
        authenticate: true,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.DEVOTEE_PAYMENT_REPORT,
        exact: true,
        key: "System Reports",
        component: DevoteePaymentReport,
        authenticate: true,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.ACCOUNTS_REPORT,
        exact: true,
        key: "System Reports",
        component: AccountsReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.GROUP_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: GroupWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.COUNTER_GROUP_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: CounterGroupWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.USER_GROUP_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: UserGroupWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.DISTRICT_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: DistrictWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.COUNTER_PAYMENT_REPORT,
        exact: true,
        key: "System Reports",
        component: CounterPaymentReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.COUNTER_USER_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: CounterUserWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.COUNTER_USER_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: CounterUserWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.DATE_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: DateWiseReports,
        accessKey: "System Reports",
      },

      {
        path: ROUTES.USER_COUNTER_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: UserCounterWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.ADDRESS_WISE_REPORT,
        exact: true,
        key: "System Reports",
        component: AddressWiseReport,
        accessKey: "System Reports",
      },
      {
        path: ROUTES.DATE_WISE_BOOKING_REPORT,
        exact: true,
        key: "System Reports",
        component: DateWiseBookingReport,
        accessKey: "System Reports",
      },
    ],
  },
  {
    key: "Additional Users",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ADDITIONAL_USERS,
        exact: true,
        key: "Additional Users",
        component: AdditionalUsers,
        authenticate: true,
      },
    ],
  },
  {
    key: "Billing History",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.BILLING_HISTORY,
        exact: true,
        key: "Billing History",
        component: BillingHistory,
        authenticate: true,
      },
    ],
  },
  {
    key: "SMS Dashboard",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.SMS_DASHBOARD,
        exact: true,
        key: "SMS Dashboard",
        component: SmsDashboard,
        authenticate: true,
      },
    ],
  },
  {
    key: "Overall Dashboard",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.OVERALL_DASHBOARD,
        exact: true,
        key: "Overall Dashboard",
        component: Dashboard,
        authenticate: true,
      },
    ],
  },
  {
    key: "Today Dashboard",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.TODAY_DASHBOARD,
        exact: true,
        key: "Today Dashboard",
        component: TodayDashboard,
        authenticate: true,
      },
    ],
  },

  {
    key: "Send Email",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.SEND_EMAIL,
        exact: true,
        key: "Send Email",
        component: SendEmail,
        authenticate: true,
      },
    ],
  },

  {
    key: "District Dashboard",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.DISTRICT_DASHBOARD,
        exact: true,
        key: "District Dashboard",
        component: DistrictDashboard,
        authenticate: true,
      },
    ],
  },
  {
    key: "Sub District Dashboard",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.SUB_DISTRICT_DASHBOARD,
        exact: true,
        key: "Sub District Dashboard",
        component: SubDistrictDashboard,
        authenticate: true,
      },
    ],
  },

  {
    key: "user",
    component: UserLayout,
    authenticate: true,
    accessKey: "user",
    children: [
      // {
      //   path: ROUTES.DASHBOARD,
      //   exact: true,
      //   Key: "Overall Dashboard",
      //   component: Dashboard,
      //   authenticate: true,
      // },

      // {
      //   path: ROUTES.ADDITIONAL_USERS,
      //   exact: true,
      //   key: "additional_users",
      //   component: AdditionalUsers,
      //   authenticate: true,
      // },

      // {
      //   path: ROUTES.SECURITY_CODE_SETTING,
      //   exact: true,
      //   key: "security_code_setting",
      //   component: SecurityCode,
      //   authenticate: true,
      // },

      // {
      //   path: ROUTES.PAYMENT_MODE,
      //   exact: true,
      //   key: "payment_mode",
      //   component: PaymentMode,
      //   authenticate: true,
      // },
      // {
      //   path: ROUTES.MANAGING_PROVISIONS,
      //   exact: true,
      //   key: "Managing Provisions",
      //   component: ManagingProvisions,
      // },

      // {
      //   path: ROUTES.TIME_MANAGE,
      //   exact: true,
      //   key: "time_slots",
      //   component: TimeManage,
      //   authenticate: true,
      // },
      // {
      //   path: ROUTES.SEASON,
      //   exact: true,
      //   key: "season",
      //   component: Season,
      //   authenticate: false,
      // },
      {
        path: ROUTES.DISTRICT_ADMINISTRATION,
        exact: true,
        key: "district_administration",
        component: DistrictAdministration,
        authenticate: true,
      },

      {
        path: `${ROUTES.BOOKING_AVAILABILITY_DATE_SLOTS}/:id?/:title`,
        exact: true,
        key: "feedback_question",
        component: SeasonDateSlots,
        authenticate: false,
      },
      {
        path: ROUTES.EXCEL_IMPORT,
        exact: true,
        key: "excel_import",
        component: MandramsExcelImport,
        authenticate: true,
      },
      // {
      //   path: ROUTES.REPORTS,
      //   exact: true,
      //   key: "excel_import",
      //   component: reports,
      //   authenticate: true,
      // },
      {
        path: ROUTES.UPDATE_PROFILE,
        exact: true,
        key: "profile",
        component: UpdateProfile,
        authenticate: true,
      },
    ],
  },

  // {
  //   key: "System Reports",
  //   component: UserLayout,
  //   authenticate: true,
  //   children: [
  //     {
  //       path: ROUTES.SAKTHI_PEEDAM_REPORTS,
  //       exact: true,
  //       key: "sakthi_peedam_report",
  //       component: SakthiPeedamReport,
  //       authenticate: true,
  //     },
  //     {
  //       path: ROUTES.OM_SAKTHI_REPORT,
  //       exact: true,
  //       key: "om_sakthi_report",
  //       component: OmSakthiReport,
  //       authenticate: true,
  //     },
  //     {
  //       path: ROUTES.DAY_WISE_REPORT,
  //       exact: true,
  //       key: "day_wise_report",
  //       component: DayWiseReport,
  //       authenticate: true,
  //     },
  //     {
  //       path: ROUTES.DAILY_TRANSACTION_REPORT,
  //       exact: true,
  //       key: "day_wise_report",
  //       component: DailyTransactionReport,
  //       authenticate: true,
  //     },
  //     {
  //       path: ROUTES.PAYMENT_MODE_REPORT,
  //       exact: true,
  //       key: "payment_mode_report",
  //       component: PaymentModeReport,
  //       authenticate: true,
  //     },
  //     {
  //       path: ROUTES.DEVOTEE_PAYMENT_REPORT,
  //       exact: true,
  //       key: "devotee_payment_report",
  //       component: DevoteePaymentReport,
  //       authenticate: true,
  //     },

  //     {
  //       path: ROUTES.ACCOUNTS_REPORT,
  //       exact: true,
  //       key: "accounts_report",
  //       component: AccountsReport,
  //     },
  //     {
  //       path: ROUTES.GROUP_WISE_REPORT,
  //       exact: true,
  //       key: "group_wise_report",
  //       component: GroupWiseReport,
  //     },
  //     {
  //       path: ROUTES.DISTRICT_WISE_REPORT,
  //       exact: true,
  //       key: "district_wise_report",
  //       component: DistrictWiseReport,
  //     },
  //     {
  //       path: ROUTES.COUNTER_PAYMENT_REPORT,
  //       exact: true,
  //       key: "counter_payment_report",
  //       component: CounterPaymentReport,
  //     },
  //     {
  //       path: ROUTES.COUNTER_USER_WISE_REPORT,
  //       exact: true,
  //       key: "counter_user_wise_report",
  //       component: CounterUserWiseReport,
  //     },
  //     {
  //       path: ROUTES.DAY_WISE_TRANSACTIONS,
  //       exact: true,
  //       key: "day_wise_transactions",
  //       component: DayWiseTransactionsHistory,
  //       authenticate: true,
  //     },
  //     {
  //       path: ROUTES.DATE_WISE_REPORT,
  //       exact: true,
  //       key: "date_wise_report",
  //       component: DateWiseReports,
  //       authenticate: true,
  //     },
  //   ],
  // },
  {
    key: "POS",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.POS,
        exact: true,
        key: "POS",
        component: POS,
        authenticate: true,
      },
    ],
  },
  {
    key: "Booking Report",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.BOOKING_REPORT,
        exact: true,
        key: "Booking Report",
        component: BookingReport,
        authenticate: true,
        accessKey: "Booking Report",
      },
    ],
  },
  {
    key: "General Settings",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.EXTRA_ALLOWED_PERCENTAGE,
        exact: true,
        key: "General Settings",
        component: ExtraAllowedPercentage,
        authenticate: true,
        accessKey: "General Settings",
      },
    ],
  },
  {
    key: "Mandram Renewal",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: `${ROUTES.MANDRAM_RENEWAL}/:id?`,

        exact: true,
        key: "Mandram Renewal",
        component: MandramRenewal,
        authenticate: true,
        accessKey: "Mandram Renewal",
      },
    ],
  },
];

export default myRoutes;
