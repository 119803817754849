import React from "react";
import { PAYMENT_LINK_EXPIRED } from "../../helpers/image_constants";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const PaymentLinkExpired = () => {
  return (
    <div className="thank_you_modal_content_main_container">
      <div className="thank_you_modal_content">
        <DotLottieReact
          src="https://lottie.host/4d5b50e9-79f5-4aab-8cd0-6fc14385b9f8/oyMouD3M8o.lottie"
          loop
          autoplay
          style={{ height: "400px", width: "400px" }}
        />
        <h6
          className="thank_you_message"
          style={{
            color: "#ffc820",
          }}
        >
          {"Payment Link Expired"}
        </h6>
      </div>
    </div>
  );
};

export default PaymentLinkExpired;
