import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Popconfirm, Typography } from "antd";
import { useDispatch } from "react-redux";
import Title from "antd/lib/typography/Title";
import {
  close_enrolment_session_mutation,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import {
  get_closure_summary_query,
  get_enroll_booking_list_query,
  set_counter_user_mutation,
} from "../../../services/redux/slices/graphql/graphql_enroll_booking";
import { get_current_counter_query } from "../../../services/redux/slices/graphql/graphql_counter_group";
import { retrieveItem } from "../../../helpers/functions";
import EnrollButtons from "./enroll_buttons";
import EnrollmentListTable from "./enrollment_history/enrollment_list_table";
import PaymentHistoryTable from "./payment_history_for_enroll/payment_history_table";
import SearchEnrollDetailsForm from "./search_enroll_details/search_enroll_details_form";

const Enrolment = () => {
  const dispatch = useDispatch();
  const spot_enrol_button_ref = useRef(null);
  const [is_print_token_modal_visible, set_is_print_token_modal_visible] =
    useState(false);
  const [totalAmount, set_total_amount] = useState(0);
  const [open_confirmation, set_open_confirmation] = useState(false);

  const system_user = retrieveItem("system_user");
  const { counter_mac_address } = useDynamicSelector("counter_mac_address");

  const { name: current_counter_name, id: current_counter_id } =
    useDynamicSelector("get_current_counter");

    useEffect(()=>{
      get_enroll_booking_list();
    },[])

  useEffect(() => {
    if (counter_mac_address) {
      set_counter_for_user(counter_mac_address);
      get_current_counter_name(counter_mac_address);
    }
  }, [counter_mac_address]);

  const set_counter_for_user = (mac_address) => {
    let keys = [{ key: "set_counter_user", loading: true }];
    let query = set_counter_user_mutation;
    let variables = {
      id: mac_address,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const get_current_counter_name = (mac_address) => {
    let key = [{ key: "get_current_counter", loading: true }];
    let query = get_current_counter_query;
    let variables = {
      data: {
        system_reference_id: mac_address,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const open_token_printer_modal = () => {
    set_is_print_token_modal_visible(true);
  };

  const get_enroll_booking_list = () => {
    let key = [{ key: "get_current_session_enrolment_list", loading: true }];
    let query = get_enroll_booking_list_query;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_closure_details = () => {
    let key = [{ key: "get_closure_summary", loading: true }];
    let query = get_closure_summary_query;
    dispatch(dynamicRequest(key, query));
  };

  const close_enrollment_session_status = () => {
    let keys = [{ key: "close_enrolment_session", loading: true }];
    let query = close_enrolment_session_mutation;
    let variables = null;
    dispatch(dynamicRequest(keys, query, variables));
  };

  return (
    <>
      <Card
        style={{
          height: "auto",
          overflowY: "scroll",
          margin: "10px",
          width: "97%",
          backgroundColor: "white",
          marginLeft: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
        }}
      >
        <Title
          level={4}
          style={{
            textAlign: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          Your Counter is {current_counter_name}
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div>
            <Title level={4}>Enrolled By : {system_user}</Title>
          </div>
          <div>
            <EnrollButtons
              open_token_printer_modal={open_token_printer_modal}
              get_enroll_booking_list={get_enroll_booking_list}
              spot_enrol_button_ref={spot_enrol_button_ref}
              totalAmount={totalAmount}
              system_user={system_user}
              get_closure_details={get_closure_details}
              close_enrollment_session_status={close_enrollment_session_status}
            />
          </div>
        </div>
        <div style={{ marginTop: "2%" }}>
          <EnrollmentListTable set_total_amount={set_total_amount} />
        </div>
      </Card>
      <Card
        style={{
          overflowY: "scroll",
          margin: "10px",
          width: "97%",
          backgroundColor: "#f7f7f7",
          marginLeft: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
        }}
      >
        <PaymentHistoryTable totalAmount={totalAmount} />
      </Card>
      <Card
        style={{
          height: "17%",
          margin: "20px",
          width: "97%",
          backgroundColor: "#f7f7f7",
          marginLeft: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
        }}
      >
        <SearchEnrollDetailsForm
          get_enroll_booking_list={get_enroll_booking_list}
          spot_enrol_button_ref={spot_enrol_button_ref}
          open_token_printer_modal={open_token_printer_modal}
          get_closure_details={get_closure_details}
          open_confirmation={open_confirmation}
          set_open_confirmation={set_open_confirmation}
        />
      </Card>
    </>
  );
};

export default Enrolment;
