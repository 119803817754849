import { Form, Switch } from 'antd'; 
import React from 'react';

const InputAdminActive = () => {
  return (
    <Form.Item
      name={["district_admin", "is_active"]}
      label="Active Admin"
      valuePropName="checked" 
    > 
      <Switch defaultChecked={false} /> 
    </Form.Item>
  );
};

export default InputAdminActive;
