import React from "react";
import PaymentSuccessAppreciation from "../components/payment_success_page/payment_success";
import { APPRECIATION_IMAGE_ } from "../../helpers/image_constants";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const PaymentSuccessPage = () => {
  return (
    <div className="thank_you_modal_content_main_container">
      <div className="thank_you_modal_content">
        {/* <h2>{"Thank You!"}</h2> */}
        {/* <img
        className="thank_you_image"
        src={APPRECIATION_IMAGE_}
        alt="Profile"
      /> */}

        <DotLottieReact
          src="https://lottie.host/8794cb33-52dd-4c3f-a0ae-82a3d95fd45e/oTevoywAdk.lottie"
          loop
          autoplay
        style={{ height: "400px", width: "400px" }}

        />

        <h6 className="thank_you_message" style={{color:"#00b74a"}}>{"Payment Successfully"}</h6>
        {/* <PaymentSuccessAppreciation /> */}
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
