import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  get_set_system_module_list_query,
  update_link_end_point_system_ui_module_component_mutation,
  update_system_ui_module_component_mutation,
} from "../../../../services/redux/slices/graphql/graphql_access_rights";
import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import EndPoints from "./end_points";
import { showToast } from "@helpers/functions";

const EndPointForm = (props) => {
  const { get_id, link_type, set_loading, action_item, close, action_type } =
    props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { items: api_list } = useDynamicSelector("get_system_api_module_list");

  console.log("api_listff",api_list);
  

  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("update_link_end_point");

  useEffect(() => {
    if (
      link_type === "link_component" &&
      // action_type !== "edit_page_component" &&
      update_status === "success"
    ) {
      showToast({
        type: "success",
        message: "link to page component updated successfully",
      });
      system_api_module_list();
      close();
      dispatch(dynamicClear("update_link_end_point"));
    } else if (update_error) {
      showToast({
        type: "error",
        message: update_error.message,
      });
      system_ui_module_list();
      dispatch(dynamicClear("update_link_end_point"));
    }
  }, [update_status, update_error]);

  useEffect(() => {
    set_loading(update_loading);
  }, [update_loading]);

  const system_api_module_list = () => {
    let key = [{ key: "get_system_api_module_list", loading: true }];
    let query = get_set_system_module_list_query;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    system_api_module_list();
  }, []);

  const update_page_component = (data) => {
    let key = [{ key: "update_link_end_point", loading: true }];
    let query = update_link_end_point_system_ui_module_component_mutation;
    let variables = { data, id: get_id.id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_finish = (values) => {
    update_page_component({
      ...values,
      id: get_id.id,
      name: get_id.name,
    });
  };

  useEffect(() => {
    // if (action_type === "link_component") {
    //   form.setFieldsValue({});
    // } else {
    form.setFieldsValue({
      api_module_id_list: action_item?.api_modules?.map((item) => item?.id),
    });
    // }
  }, [action_item]);
  // useEffect(() => {
  //   form.resetFields();
  // }, []);

  return (
    <div>
      <Form
        name="create page link"
        onFinish={on_finish}
        id="link_form"
        form={form}
      >
        <EndPoints api_list={api_list} />
      </Form>
    </div>
  );
};
export default EndPointForm;
