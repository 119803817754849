import { gql } from "@apollo/client";

export const query_login = gql`
  query login($custom: login_input) {
    login(custom: $custom) {
      user_id
      session_id
      sub_district {
        id
        name
        district {
          id
        }
      }
      district {
        id
        name
      }
      status
      page_accesses
      page_component_accesses
      system_user {
        id
        username
        name
        email
        mobile
        roles {
          id
          name
        }
      }
      error {
        status_code
        message
      }
      vip_booking_user {
        name
        mobile
        address
        id_type
        id_number
        email
      }
    }
  }
`;

export const query_booking_receipt = gql`
  query get_booking_receipt($custom: get_booking_receipt_input) {
    get_booking_receipt(custom: $custom) {
      status
      booking_reference
      booking_user_id
      booking_status
      booked_on
      booked_by {
        name
      }
      booking_user {
        name
        mobile
        email
        address
        id_type
        id_number
        id
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const get_active_season_query = gql`
  query get_active_season {
    get_active_season {
      start_date
      end_date
    }
  }
`;

export const create_system_otp_mutation = gql`
  mutation create_system_otp($data: create_system_otp_input!) {
    create_system_otp(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const vip_login_query = gql`
  query login($custom: login_input) {
    vip_login: login(custom: $custom) {
      user_id
      session_id
      status
      page_accesses
      page_component_accesses
      system_user {
        id
        username
        name
        email
        mobile
        roles {
          id
          name
        }
      }
      vip_booking_user {
        id
        name
        mobile
        address
        id_type
        id_number
        email
      }
      error {
        status_code
        message
      }
    }
  }
`;
