import React from "react";
import LoginHeaderBar from "../components/headerbar/login_header";
import VipUserLoginMasm from "../components/login/vip_user_login_masm";
import BackgroundLogo from "../../assets/icons/background_image.png";

import { Box } from "native-base";
import VipUserLoginHeader from "../components/headerbar/vip_user_login_header";

const VipLoginPortalUser = () => {
  return (
    <div>
      <VipUserLoginHeader />

      <Box
        bgImage={`url(${BackgroundLogo})`}
        style={{ height: "100vh", backgroundSize: "cover", width: "100%" }}
      >
        <VipUserLoginMasm />
      </Box>
    </div>
  );
};

export default VipLoginPortalUser;
