import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Typography } from "antd";
import VipBookingMobileFormItem from "./vip_booking_mobile_form_item";
import VipBookingSendOtpButton from "./vip_booking_send_otp_button";
import VipBookingPortalLoginButton from "./vip_booking_portal_login_button";
import { useTranslation } from "react-i18next";
import { redirect_page, storeItem } from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import {
  create_system_otp_mutation,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  vip_login_query,
} from "../../../../services/redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import TermsAndConditions from "../booking_and_irumudi_portal/booking_portal_form/terms_and_conditions";

const VipBookingPortal = ({ handle_back_click }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [countdown, set_countdown] = useState(60);
  const [is_otp_status, set_is_otp_status] = useState(false);
  const [resend_otp, set_resend_otp] = useState(false);
  const [username_type, set_username_type] = useState("");
  const pathname = location?.pathname;

  const {
    status: vip_login_status,
    session_id,
    error: vip_login_error,
    system_user,
    page_accesses,
    page_component_accesses,
    vip_booking_user,
  } = useDynamicSelector("vip_login");
  const { status: create_otp_status, error: create_otp_error } =
    useDynamicSelector("create_system_otp");
  const booking_user_mobile = Form.useWatch("booking_user_mobile", form);

  const handleFormSubmit = (values) => {
    if (pathname === "/vip-login") {
      storeItem("vip_login_path", "vip-login");
    }

    if (values?.booking_user_mobile && values?.otp) {
      vip_user_login(values);
    } else if (values?.booking_user_mobile) {
      generate_otp_vip_user();
    }
  };

  const generate_otp_vip_user = () => {
    const key = [{ key: "create_system_otp", loading: true }];
    const query = create_system_otp_mutation;

    const data =
      username_type === "mobile"
        ? { mobile: booking_user_mobile }
        : { email: booking_user_mobile };

    const variables = {
      data,
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (create_otp_status === "success") {
      toast.success("OTP sent Successfully");
      set_is_otp_status(true);
      set_resend_otp(true);
      dispatch(dynamicClear("create_system_otp"));
    } else if (create_otp_error) {
      toast.error("Something went Wrong");
      dispatch(dynamicClear("create_system_otp"));
    }
    dispatch(dynamicClear("create_system_otp"));
  }, [create_otp_status, create_otp_error]);

  useEffect(() => {
    if (vip_login_error) {
      const { message } = vip_login_error;
      if (message === "Invalid OTP") {
        toast.error("Please enter a valid OTP");
        dispatch(dynamicClear("vip_login"));
      } else if (message === "invalid_credentials") {
        toast.error("Please enter valid credentials");
        dispatch(dynamicClear("vip_login"));
      }
      dispatch(dynamicClear("vip_login"));
    }

    if (vip_login_status === "success") {
      storeItem("session_id", session_id);
      storeItem("role", system_user?.roles?.[0]?.name);
      storeItem("page_accesses", page_accesses);
      storeItem("system_user", system_user?.name);
      storeItem("page_component_accesses", page_component_accesses);
      storeItem("vip_booking_user", vip_booking_user);
      redirect_page(ROUTES.VIP_BOOKING_USERS);

      dispatch(dynamicClear("vip_login"));
    }
  }, [vip_login_error, vip_login_status]);

  const vip_user_login = (values) => {
    let key = [{ key: "vip_login", loading: true }];
    let query = vip_login_query;
    let variables = {
      custom: {
        username: values?.booking_user_mobile,
        otp: values?.otp,
        is_vip_user: "true",
        password: "123456",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  let resent_otp_content;
  if (resend_otp) {
    resent_otp_content = (
      <p className="otp-text">{`Resend OTP in ${countdown} seconds`}</p>
    );
  } else if (resend_otp === false && is_otp_status) {
    resent_otp_content = (
      <text onClick={generate_otp_vip_user} style={{ cursor: "pointer" }}>
        Resend OTP
      </text>
    );
  }

  useEffect(() => {
    if (resend_otp) {
      const timer = setInterval(() => {
        set_countdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      if (countdown === 0) {
        set_resend_otp(false);
        set_countdown(60);
      }

      return () => clearInterval(timer);
    }
  }, [resend_otp, countdown]);

  return (
    <div className="login_form_container">
      <Row
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              width: "100%",
              marginTop: "15px",
              fontWeight: "bold",
              fontSize: "15px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            VIP Booking Portal
          </Typography>
        </div>
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <VipBookingMobileFormItem set_username_type={set_username_type} />

          {!is_otp_status ? (
            <VipBookingSendOtpButton
              form={form}
              generate_otp_vip_user={generate_otp_vip_user}
            />
          ) : (
            <>
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: t("otp"),
                  },
                ]}
              >
                <Input
                  maxLength={4}
                  placeholder="Enter OTP"
                  className="otp-input"
                />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {resent_otp_content}
              </div>

              <VipBookingPortalLoginButton />
            </>
          )}
          {pathname !== "/vip-login" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "3px",
              }}
            >
              <Button
                style={{ width: "300px", height: "30px", marginBottom: "10px" }}
                onClick={handle_back_click}
              >
                Back
              </Button>
            </div>
          )}
          <TermsAndConditions />
        </Form>
      </Row>
    </div>
  );
};

export default VipBookingPortal;
