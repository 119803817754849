import { Button, Form, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux/selector";
import {
  create_district_vip_booking_mutation,
  dynamicClear,
  dynamicRequest,
  get_check_payment_order_status_query,
  mutation_create_booking,
  query_get_asynchronous_request,
  query_get_booking_details,
} from "../../../services/redux/slices";
import { useDispatch } from "react-redux";
import BookingConfirmationDetail from "./booking_modal_form_item/booking_confirmation_detail";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import { startCase } from "lodash";

let modalInstance = Modal;
let modal_Instance_request = Modal;

const BookingConfirmationModal = (props) => {
  const {
    modal_open,
    set_open_notification,
    set_modal_open,
    booking_details,
    selected_time,
    selected_item,
    mandram_id,
    initial_values,
    form,
    pay_mode,
    ignore_waiting,
    count,
    set_pay_mode,
    set_selected_item,
    set_amount,
    set_selected_time,
    set_value,
    set_get_leader_details,
    set_leader_mobile_number,
    set_selected_id_proof,
    set_leader_details,
    set_mandram_id,
    set_duplicate_booking_modal,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [order_booking_id, set_order_booking_id] = useState();
  const [is_duplicate_booking, set_is_duplicate_booking] = useState(false);
  const item = useDynamicSelector("get_booking_user_details");
  const booking_type = Form.useWatch("booking_type", form);
  const [create_booking_id, set_create_booking_id] = useState("");
  const [create_booking_error, set_create_booking_error] = useState("");
  const [create_booking_status, set_create_booking_status] = useState("");

  const {
    reference_number: reference_number,
    status: vip_booking_status,
    loading: create_district_vip_booking_loading,
  } = useDynamicSelector("create_district_vip_booking");

  const { status: check_order_status, error: check_order_error } =
    useDynamicSelector("check_payment_order_status");
  const {
    // status: create_booking_status,
    // error: create_booking_error,
    loading: create_booking_loading,
    // id: create_booking_id,
    id: check_booking_status_id,
  } = useDynamicSelector("create_booking");

  const {
    // error: create_booking_error,
    loading: create_booking_request_loading,
    // id: create_booking_id,
    status: asynchronous_status,
    response,
  } = useDynamicSelector("get_asynchronous_request");

  console.log("check_booking_status_id", check_booking_status_id);

  useEffect(() => {
    if (response) {
      const create_booking_details = JSON.parse(response);
      set_create_booking_status(create_booking_details?.status);
      set_create_booking_id(create_booking_details?.id);
      set_create_booking_error(create_booking_details?.error);
    }
  }, [response]);

  const closeModalInstance = () => {
    if (modalInstance) {
      modalInstance.destroyAll();
    }
  };

  const closeModal_instance_request = () => {
    if (modal_Instance_request) {
      modal_Instance_request.destroyAll();
    }
  };

  useEffect(() => {
    if (check_order_status === "initiated") {
      setTimeout(() => {
        get_check_payment_order_status();
      }, 5000);
    }
    if (check_order_status === "completed") {
      closeModalInstance();
      set_pay_mode("");
      set_selected_item("");
      set_value(1);
      set_amount(0);
      set_get_leader_details("");
      set_selected_time("");
      set_is_duplicate_booking(false);
      set_modal_open(false);
      set_open_notification(true);
      set_leader_mobile_number("");
      set_leader_details(false);
      set_mandram_id("");
      form.resetFields();
      set_selected_id_proof("Aadhaar Card");
      form.setFieldValue("booking_type", "normal");
      dispatch(dynamicClear("create_district_vip_booking"));
      dispatch(dynamicClear("get_booking_user_details"));
      dispatch(dynamicClear("get_mandram_confirmed_waiting_count_summary"));
      // dispatch(dynamicClear("check_payment_order_status"));
    }
    if (check_order_status === "failed") {
      closeModalInstance();
      dispatch(dynamicClear("create_district_vip_booking"));
      dispatch(dynamicClear("get_booking_user_details"));
      dispatch(dynamicClear("get_mandram_confirmed_waiting_count_summary"));
      dispatch(dynamicClear("check_payment_order_status"));
      toast.error("Transaction Failed");
    }
  }, [check_order_status, check_order_error]);

  const create_booking = () => {
    let key = [{ key: "create_booking", loading: true }];
    let query = mutation_create_booking;
    let data = {
      time_slot_id: selected_time,
      count: count,
      mandram_id: mandram_id,
      payment_mode: pay_mode,
      ignore_waiting: ignore_waiting,
      booking_user_id: item?.id ? item?.id : "",
      booking_type: booking_details?.booking_type,
      booking_user: {
        name: booking_details?.group_leader
          ? booking_details?.group_leader
          : item?.name,
        mobile: booking_details?.mobile
          ? booking_details?.mobile
          : item?.mobile,
        address: booking_details?.address
          ? booking_details?.address
          : item.address,
        id_type: booking_details?.id_proof
          ? booking_details?.id_proof
          : item.id_type,
        id_number: booking_details?.id_number
          ? booking_details?.id_number
          : item.id_number,
      },
    };

    if (item?.id) {
      delete data.booking_user;
    } else {
      delete data.booking_user_id;
    }
    let variables = {
      data: data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const create_vip_booking = () => {
    let key = [{ key: "create_district_vip_booking", loading: true }];
    let query = create_district_vip_booking_mutation;
    let data = {
      time_slot_id: selected_time,
      count: count,
      mandram_id: mandram_id,
      payment_mode: pay_mode,
      booking_user_id: item?.id ? item?.id : "",
      booking_type: booking_details?.booking_type,
      booking_user: {
        name: booking_details?.group_leader
          ? booking_details?.group_leader
          : item?.name,
        mobile: booking_details?.mobile
          ? booking_details?.mobile
          : item?.mobile,
        address: booking_details?.address
          ? booking_details?.address
          : item.address,
        id_type: booking_details?.id_proof
          ? booking_details?.id_proof
          : item.id_type,
        id_number: booking_details?.id_number
          ? booking_details?.id_number
          : item.id_number,
      },
    };

    if (item?.id) {
      delete data.booking_user;
    } else {
      delete data.booking_user_id;
    }
    let variables = {
      data: data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  console.log("create_booking_id", create_booking_id);

  const get_one_booking = () => {
    let key = [{ key: "get_booking_detail", loading: true }];
    let query = query_get_booking_details;
    let variables = {
      custom: {
        booking_reference: create_booking_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_check_payment_order_status = (create_booking_id) => {
    let key = [{ key: "check_payment_order_status", loading: true }];
    let query = get_check_payment_order_status_query;
    let variables = {
      ref_number: reference_number,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_asynchronous_request = () => {
    let key = [{ key: "get_asynchronous_request", loading: true }];
    let query = query_get_asynchronous_request;
    let variables = {
      id: check_booking_status_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (check_booking_status_id) {
      modal_Instance_request.info({
        title: "Booking Process",
        content: (
          <div>
            <p>Your booking is processing...</p>
            <p>Do not close or refresh the page.</p>
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ),
        okButtonProps: {
          style: { display: "none" },
        },
        getContainer: document.body,
        className: "custom-modal",
        keyboard: false,
      });
      get_asynchronous_request();

      const interval_id = setInterval(() => get_asynchronous_request(), 5000);
      return () => clearInterval(interval_id);
    }
  }, [check_booking_status_id]);

  useEffect(() => {
    if (asynchronous_status === "completed") {
      closeModal_instance_request();
      dispatch(dynamicClear("create_booking"));
    } else if (create_booking_status === "duplicate") {
      closeModal_instance_request();
      dispatch(dynamicClear("create_booking"));
    } else if (create_booking_status === "completed") {
      closeModal_instance_request();
      toast.success("Booking created");
      dispatch(dynamicClear("create_booking"));
    } else if (create_booking_status === "failed") {
      closeModal_instance_request();
      toast.error("Booking creation failed");
      dispatch(dynamicClear("create_booking"));
    }
  }, [check_booking_status_id, create_booking_status]);

  const on_finish = () => {
    if (booking_type === "vip") {
      create_vip_booking();
      set_modal_open(false);
    } else if (booking_type === "normal") {
      create_booking();
      set_modal_open(false);
    }
  };

  useEffect(() => {
    if (
      vip_booking_status === "district vip booking initiated" &&
      reference_number
    ) {
      set_modal_open(false);

      modalInstance.info({
        title: "Payment Process",
        content: (
          <div>
            <p>Your payment is processing...</p>
            <p>Do not close or refresh the page.</p>
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ),
        okButtonProps: {
          style: { display: "none" },
        },
        getContainer: document.body,
        className: "custom-modal",
      });

      get_check_payment_order_status();
    } else if (vip_booking_status === "payment success") {
      closeModalInstance();
    } else if (vip_booking_status === "payment failed") {
      closeModalInstance();
      Modal.error({
        title: "Payment Failed",
        content: "Your payment could not be processed. Please try again.",
      });
    }
  }, [vip_booking_status, reference_number]);
  console.log("vip_booking_status", vip_booking_status);

  console.log("create_booking_status", create_booking_status);

  useEffect(() => {
    if (create_booking_status === "booking created") {
      closeModal_instance_request();
      set_pay_mode("");
      set_selected_item("");
      set_value(1);
      set_amount(0);
      set_get_leader_details("");
      set_selected_time("");
      set_is_duplicate_booking(false);
      set_modal_open(false);
      set_open_notification(true);
      get_one_booking();
      set_leader_mobile_number("");
      set_leader_details(false);
      set_mandram_id("");
      form.resetFields();
      set_selected_id_proof("Aadhaar Card");
      form.setFieldValue("booking_type", "normal");
      set_create_booking_status("");
      set_create_booking_id("");
      set_create_booking_error("");
      dispatch(dynamicClear("create_booking"));
      dispatch(dynamicClear("get_booking_user_details"));
      dispatch(dynamicClear("get_asynchronous_request"));
      dispatch(dynamicClear("get_mandram_confirmed_waiting_count_summary"));
    } else if (create_booking_error) {
      if (
        create_booking_error?.message ===
        "Cannot Read Properties Of Null Reading Date Slot"
      ) {
        set_is_duplicate_booking(true);
        create_booking();
      } else if (
        create_booking_error?.message === "Already booking exist for this user"
      ) {
        set_pay_mode("");
        set_selected_item("");
        set_value(1);
        set_amount(0);
        set_get_leader_details("");
        set_selected_time("");
        set_modal_open(false);
        get_one_booking();
        set_leader_mobile_number("");
        set_leader_details(false);
        set_mandram_id("");
        set_selected_id_proof("Aadhaar Card");
        form.resetFields();
        if (is_duplicate_booking) {
          set_open_notification(true);
        } else {
          set_duplicate_booking_modal(true);
        }
        set_create_booking_status("");
        set_create_booking_id("");
        set_create_booking_error("");
        dispatch(dynamicClear("create_booking"));
        dispatch(dynamicClear("get_asynchronous_request"));
        dispatch(dynamicClear("get_booking_user_details"));
      } else {
        toast.error(startCase(create_booking_error?.message));
        set_modal_open(false);
        dispatch(dynamicClear("create_booking"));
        dispatch(dynamicClear("get_asynchronous_request"));
      }
    }
  }, [create_booking_status, create_booking_id, create_booking_error]);

  console.log("create_booking_error", create_booking_error);

  const formatted_date = selected_item?.date
    ? moment(selected_item?.date).format("DD-MMM-YYYY")
    : "";

  return (
    <div>
      <Modal
        title={t("conformation")}
        open={modal_open}
        onCancel={() => set_modal_open(false)}
        style={{ maxWidth: 600, height: 300 }}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={() => set_modal_open(false)}
          >
            {t("no")}
          </Button>,
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            form="add_counter_form"
            onClick={on_finish}
            loading={
              create_booking_loading ||
              create_district_vip_booking_loading ||
              create_booking_request_loading
            }
          >
            {t("yes")}
          </Button>,
        ]}
      >
        <Typography
          style={{
            marginBottom: "15px",
          }}
        >
          {t("details_for_booking")}
        </Typography>

        <BookingConfirmationDetail
          title={t("mandram_id")}
          value={initial_values.code}
        />
        <BookingConfirmationDetail
          title={t("mandram")}
          value={initial_values.name}
        />
        <BookingConfirmationDetail title={t("devotee_s")} value={count} />
        <BookingConfirmationDetail title={t("date")} value={formatted_date} />
      </Modal>
    </div>
  );
};

export default BookingConfirmationModal;
