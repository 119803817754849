import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const VipBookingMobileFormItem = ({ set_username_type }) => {
  const { t } = useTranslation();

  const validateMobileOrEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    const mobileRegex = /^\d{10}$/; 

    if (!value) {
      return Promise.reject(t("mobile_or_email_required"));
    }

    if (mobileRegex.test(value)) {
      set_username_type("mobile");
      return Promise.resolve(); 
    }

    if (!/^\d+$/.test(value) && !emailRegex.test(value)) {
      return Promise.reject(t("invalid_email_format"));
    }

    if (!emailRegex.test(value) && /^\d+$/.test(value)) {
      // If numeric but not 10 digits
      return Promise.reject(t("invalid_mobile_number"));
    }

    set_username_type("email"); 
    return Promise.resolve();
  };

  const handle_change = (value) => {
    console.log("Input value changed to:", value);
  };

  return (
    <div>
      <Form.Item
        name="booking_user_mobile"
        rules={[
          {
            validator: validateMobileOrEmail,
          },
        ]}
      >
        <Input
          className="placeholder-black"
          type="text"
          placeholder={t("mobile_or_email")}
          style={{
            backgroundColor: "#FFDE8B",
            color: "#2e291e",
            border: "1px solid black",
          }}
          onChange={(e) => handle_change(e.target.value)}
        />
      </Form.Item>
    </div>
  );
};

export default VipBookingMobileFormItem;
