import { gql } from "@apollo/client";

export const mutation_create_spot_vip_booking = gql`
  mutation create_spot_vip_booking($data: create_spot_vip_booking_input) {
    create_spot_vip_booking(data: $data) {
      id
      status
      booking_reference
      receipt_no
      amount
      arrived_count
      payment_mode
      district_code
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_spot_foreign_booking = gql`
  mutation create_spot_foreign_booking(
    $data: create_spot_foreign_booking_input
  ) {
    create_spot_foreign_booking(data: $data) {
      id
      status
      booking_reference
      receipt_no
      amount
      payment_mode
      arrived_count
      district_code
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_enrolment_detail = gql`
  query get_enrolment_detail($id: String!) {
    get_enrolment_detail(id: $id) {
      booking_id
      sequence_count {
        count
        suffix
      }
      booking {
        price
        count
        booking_reference
        payment {
          amount
          payment_mode
          paid_on
        }
        booking_status
      }
    }
  }
`;

export const query_get_all_districts_list = gql`
  query get_district_list(
    $filter: get_district_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_district_list(filter: $filter, sort: $sort, pagination: $pagination) {
      items {
        id
        name
      }
    }
  }
`;

export const create_vip_booking_mutation = gql`
  mutation create_vip_booking($data: create_booking_input) {
    create_vip_booking(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const create_district_vip_booking_mutation = gql`
  mutation create_vip_booking($data: create_booking_input) {
    create_district_vip_booking: create_vip_booking(data: $data) {
      id
      status
      reference_number
      error {
        message
      }
    }
  }
`;
export const get_check_payment_order_status_query = gql`
  query check_payment_order_status($ref_number: String!) {
    check_payment_order_status(ref_number: $ref_number) {
      status
      booking_id
      booking {
        booking_reference
        booking_user {
          mobile
          email
        }
      }
      error {
        message
      }
    }
  }
`;
export const payment_redirect_query = gql`
  query payment_redirect($ref_number: String!) {
    payment_redirect(ref_number: $ref_number) {
      payment_url
    }
  }
`;
