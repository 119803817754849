import { Form, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DistrictUserAccess from "./district_user_access";
import UpdateDistrictUserAccessButtons from "./update_district_user_access_buttons";
import {
  dynamicRequest,
  dynamicSet,
  mutation_set_accessible_mandram_list,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import MandramFilter from "./mandram_filter/mandram_filter";

export const ManagingProvisionModal = (props) => {
  const {
    managing_provision_modal_visible,
    set_managing_provision_modal_visible,
    get_all_mandram_list,
    district_user_id,
    mandram_list,
    set_mandram_list,
    record_id,
  } = props;
  const [form] = Form.useForm();
  const mandram = Form.useWatch("mandram", form);
  const district_data = useDynamicSelector("district_user_initial_data");
  const { items: get_accessible_mandram_list } = useDynamicSelector(
    "get_accessible_mandram_list"
  );
  console.log("district_data", district_data);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handle_update = () => {
    console.log("initial_values", district_data?.initial_values);

    set_accessible_mandram(district_data?.initial_values || []);
    set_managing_provision_modal_visible(false);
  };

  const set_accessible_mandram = (mandram_id) => {
    let key = [{ key: "set_accessible_mandram_list", loading: true }];
    let query = mutation_set_accessible_mandram_list;
    let variables = {
      custom: {
        mandram_id_list: mandram_id,
        district_user_id: district_user_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    const get_selected_id_list = get_accessible_mandram_list?.reduce(
      (acc, item) => {
        if (item?.is_active) {
          acc.push(item.id);
        }
        return acc;
      },
      []
    );
    if (get_selected_id_list?.length > 0) {
      dispatch(
        dynamicSet("district_user_initial_data", {
          initial_values: get_selected_id_list,
        })
      );
    } else {
      dispatch(
        dynamicSet("district_user_initial_data", {
          initial_values: [],
        })
      );
    }
  }, [get_accessible_mandram_list]);

  return (
    <Modal
      open={managing_provision_modal_visible}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      width={"60%"}
      title={t("provisions")}
      onCancel={() => set_managing_provision_modal_visible(false)}
      footer={
        <UpdateDistrictUserAccessButtons
          mandram_list={mandram_list}
          handle_update={handle_update}
          set_managing_provision_modal_visible={
            set_managing_provision_modal_visible
          }
          set_mandram_list={set_mandram_list}
        />
      }
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <MandramFilter record_id={record_id} />
      </div>
      <DistrictUserAccess
        get_all_mandram_list={get_all_mandram_list}
        district_user_id={district_user_id}
        set_managing_provision_modal_visible={
          set_managing_provision_modal_visible
        }
        mandram_list={mandram_list}
        set_mandram_list={set_mandram_list}
        form={form}
      />
    </Modal>
  );
};
export default ManagingProvisionModal;
