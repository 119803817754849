import { Button, Col, Form, Row, Select, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import DistrictUserCheckbox from "./district_user_checkbox";
import {  useDynamicSelector } from "../../../services/redux";
import LoaderSpinner from "../common/custom_spinner";
import { useDispatch } from "react-redux";

const DistrictUserAccess = (props) => {
  const { get_all_mandram_list, mandram_list, set_mandram_list, form } = props;
  const dispatch = useDispatch();
  const [initial_values, set_initial_values] = useState();
  const [search_mandram_list, set_search_mandram_list] = useState([]);
  console.log("search_mandram_list", search_mandram_list);

  const { items: mandrams } = useDynamicSelector("get_accessible_mandram_list");
  const { items, loading: mandram_loading } =
    useDynamicSelector("get_mandrams");
  const { items: mandram_search_items } = useDynamicSelector(
    "get_mandrams_search"
  );
  console.log("mandram_search_items", mandram_search_items);

  const {
    loading: get_accessible_mandram_loading,
    items: get_accessible_mandram_list,
  } = useDynamicSelector("get_accessible_mandram_list");

  const handle_on_change = (event, index, id) => {
    let mandram = mandram_list?.map((x) =>
      x.id === id ? { ...x, is_active: event.target.checked } : x
    );
    set_mandram_list(mandram);
  };

  const checkboxesInRows = [];
  for (let i = 0; i < items?.length; i += 4) {
    const rowOptions = items?.slice(i, i + 4);
    checkboxesInRows.push(rowOptions);
  }
  useEffect(() => {
    let matching_elements = items;
    // ?.map((mandram_items) => {
    //   const found_items = mandrams?.find(
    //     (item) => item.id === mandram_items.id
    //   );
    //   const new_items = { ...mandram_items, is_active: !!found_items };
    //   return new_items;
    // });
    set_mandram_list(matching_elements);
  }, [items]);

  // useEffect(() => {
  //   get_all_mandram_list();
  // }, [mandrams]);

  useEffect(() => {
    const temp = get_accessible_mandram_list?.reduce((acc, mandram) => {
      acc[`mandram-${mandram.id}`] = mandram.is_active;
      return acc;
    }, {});

    set_initial_values(temp);
    console.log("tetemptempmp", temp);

    form.setFieldsValue({ ...temp });
  }, [get_accessible_mandram_list]);

  return (
    <>
      <div
        style={{
          display: "flex",
          margin: "auto",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      ></div>
      <div
        style={{
          padding: "30px",
        }}
      >
        <Row gutter={[30, 30]}>
          {mandram_loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoaderSpinner />
            </div>
          ) : (
            <>
              {mandram_list?.map((x, index) => {
                return (
                  <Col
                    key={index}
                    span={7}
                    style={{
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "23px",
                    }}
                  >
                    <Form initialValues={initial_values} form={form}>
                      <DistrictUserCheckbox
                        key={x.id}
                        option={x}
                        index={index}
                        set_mandram_list={set_mandram_list}
                        handle_on_change={handle_on_change}
                      />
                    </Form>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </div>
    </>
  );
};
export default DistrictUserAccess;
