import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../../../helpers/functions";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { Form, Select } from "antd";
import { query_get_sub_district_filter } from "../../../../../services/redux/slices/graphql/graphql_district";
import { filter } from "lodash";

const MandramSubDistrict = ({ header_type, get_district_id }) => {
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const district_name = retrieveItem("district_name");
  const district_id = retrieveItem("district_id");
  const { items: sub_district_items } = useDynamicSelector(
    "get_sub_district_filter"
  );

  console.log("get_district_id", district_id);

  const handleSelectDistrict = (value) => {
    // set_sub_district_id(value);
  };

  const filterOption = (input, option) =>
    option.props.children.toLowerCase().startsWith(input.toLowerCase());

  const getSubDistrictDetails = () => {
    let key = [{ key: "get_sub_district_filter", loading: true }];
    let query = query_get_sub_district_filter;
    let variables = {
      filter: {
        district_id: get_district_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getSubDistrictDetails();
  }, [get_district_id]);

  const isAdmin =
    role === "Super Admin" ||
    role === "MASM Admin" ||
    role === "Mandram Renewal Officer";

  const isEditable = !(header_type === "Edit Mandram");

  return (
    <Form.Item
      label="Sub District"
      name="sub_district_id"
      rules={[
        {
          required: true,
          message: "Please Select District",
        },
      ]}
    >
      <Select
        allowClear
        showSearch={isAdmin}
        onChange={handleSelectDistrict}
        filterOption={isAdmin ? filterOption : undefined}
        // defaultValue={isAdmin ? undefined : district_id}
        // disabled={!(get_district_id || district_id)}
        disabled={!(isAdmin)}
      >
        {isAdmin ? (
          sub_district_items?.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))
        ) : (
          <Select.Option key={district_id} value={district_id}>
            {district_name}
          </Select.Option>
        )}
      </Select>
    </Form.Item>
  );
};

export default MandramSubDistrict;
