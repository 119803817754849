import { Form, Input } from "antd";
import React from "react";

const InputSubDistrictAdminName = ({ is_admin }) => {
  return (
    <>
      <Form.Item
        name={["district_admin", "name"]}
        label="Name"
        rules={[
          {
            required: true,
            message: "Please enter name",
          },
        ]}
      >
        <Input
          placeholder="Name"
          allowClear={true}
          disabled={is_admin ? false : true}
        />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictAdminName;
