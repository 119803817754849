import React from "react";
import { Box, HStack, Center, Text, VStack } from "native-base";

let defaultStyles = {
  height: "50px",
};

const FooterBar = (props) => {
  let height = props.height || props.h || defaultStyles.height;

  return (
    <Box h={height} w="100%" bg="#780a0a" position="relative">
      <Box
        h="100%"
        w="100%"
        bg="#780a0a"
        position="absolute"
        bottom="0px"
        style={{
          boxShadow: "rgb(229 218 255) 0px 0px 4px",
          justifyContent:"center"
        }}
      >
        <marquee direction="left">
          <Text bold color={"white"} >
            Technical Support Team Contact us: Mugesh - 9003581906 , Dharun -
            8428071414
          </Text>
        </marquee>
      </Box>
    </Box>
  );
};

export default FooterBar;
