import React from "react";
import { Card, Row, Col, Typography } from "antd";
import VipBookingUserNameAddressSection from "../vip_booking_form/vip_booking_user_name_address_section";
import VipBookingUserIdProofSection from "../vip_booking_form/vip_booking_user_id_proof_section";
import VipBookingUserMobileNumberSection from "../vip_booking_form/vip_booking_user_mobile_section";
import { retrieveItem } from "../../../../helpers/functions";

const VipBookingUserLeaderDetailsCard = ({ form }) => {
  const vip_booking_user = retrieveItem("vip_booking_user");
  console.log("vip_booking_user", vip_booking_user);

  return vip_booking_user?.id ? (
    <Card className="leader_details_card">
      <Row gutter={16} style={{ padding: "3px" }}>
        <Col span={12}>
          <Typography className="leader_details_title">Leader Name:</Typography>
          <Typography>{vip_booking_user?.name}</Typography>
        </Col>

        {vip_booking_user?.mobile && (
          <Col span={12}>
            <Typography className="leader_details_title">Mobile:</Typography>
            <Typography>{vip_booking_user?.mobile}</Typography>
          </Col>
        )}
        {vip_booking_user?.email && (
          <Col span={12}>
            <Typography className="leader_details_title">Email:</Typography>
            <Typography>{vip_booking_user?.email}</Typography>
          </Col>
        )}
      </Row>

      <Row gutter={16} style={{ padding: "3px" }}>
        <Col span={12}>
          <Typography className="leader_details_title">ID Proof:</Typography>
          <Typography>{vip_booking_user?.id_type}</Typography>
        </Col>
        <Col span={12}>
          <Typography className="leader_details_title">
            ID Proof Number:
          </Typography>
          <Typography>{vip_booking_user?.id_number}</Typography>
        </Col>
      </Row>

      <Row gutter={16} style={{ padding: "3px" }}>
        <Col span={12}>
          <Typography className="leader_details_title">Address:</Typography>
          <Typography>{vip_booking_user?.address}</Typography>
        </Col>
      </Row>
    </Card>
  ) : (
    <>
      <VipBookingUserMobileNumberSection form={form} />
      <VipBookingUserNameAddressSection form={form} />
      <VipBookingUserIdProofSection form={form} />
    </>
  );
};

export default VipBookingUserLeaderDetailsCard;
