import React, { useEffect, useState } from "react";
import {
  dynamicRequest,
  get_active_season_query,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box, Text } from "native-base";
import { Row } from "antd";
import VipBookingPortal from "./vip_booking_portal/vip_booking_portal";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const VipUserLoginMasm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pathname = location?.pathname;
  console.log("pathname", pathname);
  const { start_date: seasonStart, end_date: seasonEnd } =
    useDynamicSelector("get_active_season");

  useEffect(() => {
    fetchActiveSeason();
  }, []);

  const fetchActiveSeason = () => {
    const key = [{ key: "get_active_season", loading: false }];
    const query = get_active_season_query;
    dispatch(dynamicRequest(key, query, {}));
  };

  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Text fontSize="15px">Welcome To Irumudi Booking System</Text>
        <Text color="#bd0000" fontSize="12px" marginTop="10px">
          {seasonStart && seasonEnd
            ? `Date: ${moment(seasonStart).format("DD-MMM-YYYY")} To ${moment(
                seasonEnd
              ).format("DD-MMM-YYYY")}`
            : null}
        </Text>
      </Box>

      <Row justify="center">
        <VipBookingPortal />
      </Row>
    </Box>
  );
};

export default VipUserLoginMasm;
