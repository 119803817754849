import { Form, Input, InputNumber, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { countFormat } from "../../../../helpers/constants";
import { useDynamicSelector } from "../../../../services/redux";

const BookingAmount = ({ form }) => {
  const [amount, set_amount] = useState(0);
  const irumudi_count = Form.useWatch("irumudi_count", form);
  const booking_type = Form.useWatch("booking_type", form);
  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");

  const { t } = useTranslation();

  useEffect(() => {
    if (booking_type === "vip") {
      set_amount(
        irumudi_count *
          season_date_slot_list_items?.[0]?.season?.vip_ticket_price
      );
    } else if (booking_type === "normal") {
      set_amount(
        irumudi_count * season_date_slot_list_items?.[0]?.season?.ticket_price
      );
    }
  }, [booking_type, irumudi_count]);

  useEffect(() => {
    form.resetFields(["irumudi_count"]);
    set_amount(0);
  }, [booking_type, form]);

  return (
    <div>
      <Typography style={{ marginBottom: "2%" }}>Amount</Typography>
      <Typography style={{ marginTop: "3%" }}>
        {countFormat(amount || 0)}
      </Typography>
      {/* <Input
        style={{
          width: "100%",
        }}
        value={countFormat(amount)||""}
      /> */}
    </div>
  );
};

export default BookingAmount;
