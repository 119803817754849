import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import {
  check_enrolment_session_query,
  create_enrolment_session_mutation,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  query_get_asynchronous_request,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
let interval_id;

const EnrolmentSession = () => {
  const dispatch = useDispatch();
  const [is_creating_enrolment_session, set_is_creating_enrolment_session] =
    useState(false);
  const { counter_mac_address } = useDynamicSelector("counter_mac_address");
  const {
    session_status: check_enrolment_session_status,
    error: check_enrolment_session_error,
  } = useDynamicSelector("check_enrolment_session");
  const { enrolment_status } = useDynamicSelector("enrolment_status");
  const { id: asynchronous_request_id } = useDynamicSelector(
    "create_enrolment_session"
  );

  const { status: asynchronous_status, response } = useDynamicSelector(
    "get_asynchronous_request"
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:7878/my-address");
        dispatch(
          dynamicSet("counter_mac_address", {
            counter_mac_address: response?.data,
          })
        );
      } catch (error) {}
    };
    fetchData();
  }, []);
  useEffect(() => {
    const check_enrollment_session_status = (mac_address) => {
      let keys = [{ key: "check_enrolment_session", loading: true }];
      let query = check_enrolment_session_query;
      let variables = {
        data: {
          system_reference_id: mac_address,
        },
      };
      dispatch(dynamicRequest(keys, query, variables));
    };
    if (counter_mac_address) {
      check_enrollment_session_status(counter_mac_address);
    }
  }, [counter_mac_address]);

  useEffect(() => {
    if (check_enrolment_session_error) {
      toast.error(check_enrolment_session_error?.message);
      dispatch(dynamicSet("enrolment_status", { enrolment_status: "error" }));
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (check_enrolment_session_status === "open") {
      dispatch(dynamicSet("enrolment_status", { enrolment_status: "open" }));
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (check_enrolment_session_status === "closed") {
      dispatch(dynamicSet("enrolment_status", { enrolment_status: "closed" }));
      dispatch(dynamicClear("check_enrolment_session"));
    }
  }, [check_enrolment_session_status, check_enrolment_session_error]);

  useEffect(() => {
    if (asynchronous_request_id && is_creating_enrolment_session) {
      dispatch(dynamicClear("create_enrolment_session"));
      interval_id = setInterval(() => check_asynchronous_request(), 5000);
    }
  }, [asynchronous_request_id]);

  useEffect(() => {
    if (is_creating_enrolment_session) {
      if (asynchronous_status === "completed") {
        const created_enrolment_session = JSON.parse(response);
        if (created_enrolment_session.status === "success") {
          clearInterval(interval_id);
          set_is_creating_enrolment_session(false);
          dispatch(
            dynamicSet("enrolment_status", { enrolment_status: "open" })
          );
        }
      }
      dispatch(dynamicClear("get_asynchronous_request"));
    }
  }, [asynchronous_status]);

  const create_new_enrollment_session = () => {
    set_is_creating_enrolment_session(true);
    let keys = [{ key: "create_enrolment_session", loading: true }];
    let query = create_enrolment_session_mutation;
    let variables = null;
    dispatch(dynamicRequest(keys, query, variables));
  };

  const check_asynchronous_request = () => {
    let key = [{ key: "get_asynchronous_request", loading: true }];
    let query = query_get_asynchronous_request;
    let variables = {
      id: asynchronous_request_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  return (
    <div>
      {(enrolment_status === undefined || enrolment_status === "error") && (
        <Card
          style={{
            // height: "70%",
            height: "auto",
            overflowY: "scroll",
            margin: "10px",
            width: "97%",
            backgroundColor: "white",
            marginLeft: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Title
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginLeft: "35%",
            }}
          >
            Checking Counter Status...
          </Title>
        </Card>
      )}
      <Modal
        title="Create Session"
        open={enrolment_status === "closed"}
        style={{
          width: "100%",
          height: "50%",
        }}
        maskClosable={false}
        footer={null}
        closeIcon={<></>}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p>
            Currently, you don't have an active session. Create a new enrollment
            session
          </p>
          <div>
            <Button
              type="primary"
              loading={is_creating_enrolment_session}
              onClick={() => {
                create_new_enrollment_session();
              }}
            >
              {"Start New Session"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EnrolmentSession;
