import React from "react";
import { Card, Typography, Layout, Menu } from "antd";
import VipBookingFormUser from "../components/vip_booking_users/vip_booking_form/vip_booking_user_form";
import Scrollbars from "react-custom-scrollbars-2";
import LoginHeaderBar from "../components/headerbar/login_header";
import BackgroundLogo from "../../assets/icons/background_image.png";
import VipBookingSideBar from "../components/headerbar/vip_booking_side_bar";

const { Content } = Layout;

const VipBookingUsers = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <VipBookingSideBar />
      <Content
        style={{
          backgroundImage: `url(${BackgroundLogo})`,
          backgroundSize: "cover",
        }}
      >
        <Scrollbars>
          <LoginHeaderBar />
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography.Title level={3}>Booking User Form</Typography.Title>
          </div>

          <Card className="vip_booking_user_form_card">
            <VipBookingFormUser />
          </Card>
        </Scrollbars>
      </Content>
    </Layout>
  );
};

export default VipBookingUsers;
