import { Button, Card, Modal, Popconfirm, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import VipBookingForm from "../components/vip_booking/vip_booking_form/vip_booking_form";
import {
  retrieveItem,
  send_response_to_printer_service,
} from "../../helpers/functions";
import {
  dynamicClear,
  dynamicRequest,
  mutation_enrolment_ticket_print,
  useDynamicSelector,
} from "../../services/redux";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import VipBookingEnrollmentDetailModel from "../components/vip_booking/vip_booking_enrollment_detail_model/vip_booking_enrollment_detail_model";
import EnrolmentSession from "../components/enrolment_session/enrolment_session";

const VipBooking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session_id = retrieveItem("session_id");
  const [is_printing, set_is_printing] = useState(false);
  const [enrolled_id, set_enrolled_id] = useState(null);
  const [booking_details, set_booking_details] = useState({});
  const [enrollment_model_open, set_enrollment_model_open] = useState(false);
  const [enrollment_details, set_enrollment_details] = useState({
    booking_reference: "",
    receipt_no: "",
    amount: "",
    arrived_count: "",
    spot_vip_booking_pay_mode: "",
    district_code: "",
  });
  const [is_print_token_modal_visible, set_is_print_token_modal_visible] =
    useState(false);
  const [leader_details, set_leader_details] = useState(false);
  const [has_pos, set_has_pos] = useState(false);
  const [get_leader_details, set_get_leader_details] = useState("");

    const {enrolment_status} =
    useDynamicSelector("enrolment_status");


  const open_token_printer_modal = (enrolment_id) => {
    set_enrolled_id(enrolment_id)
    set_is_print_token_modal_visible(true);
  };
  const close_token_printer_modal = () => {
    set_enrolled_id("")
    set_is_print_token_modal_visible(false);
    set_enrollment_model_open(true);
    set_is_printing(false);
  };

  const TicketsPrinted = async () => {
    let key = [{ key: "set_enrolment_ticket_printed", loading: true }];
    let query = mutation_enrolment_ticket_print;
    let variables = {
      id: enrolled_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };


  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("VIP_BOOKING")} />
        </Row>
      </Row>
<EnrolmentSession/>
{enrolment_status === "open" && (
        <Card
          style={{
            width: "98%",
            margin: "5px auto",
            minHeight: 0,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <VipBookingForm
            open_token_printer_modal={open_token_printer_modal}
            has_pos={has_pos}
            leader_details={leader_details}
            set_leader_details={set_leader_details}
            get_leader_details={get_leader_details}
            set_get_leader_details={set_get_leader_details}
            set_booking_details={set_booking_details}
            booking_details={booking_details}
            set_enrollment_details={set_enrollment_details}
          />
        </Card>
      )}

      <Modal
        title={"Print Token"}
        open={is_print_token_modal_visible}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <Popconfirm
              title={"Are you sure to process without printing?"}
              onConfirm={close_token_printer_modal}
              okText="Yes"
              cancelText="No"
            >
              <IoMdClose
                style={{
                  color: "white",
                  width: "28px",
                  margin: "15px 0px 0px 0px",
                }}
              />
            </Popconfirm>
          </span>
        }
        footer={[
          <Button
            loading={is_printing}
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={() => {
              send_response_to_printer_service(
                enrolled_id,
                session_id,
                set_is_printing,
                TicketsPrinted,
                close_token_printer_modal,
              );
            }}
          >
            {"Print"}
          </Button>,
          ,
        ]}
      >
        <Typography>
          {
            "Your information saved successfully..! Do you want to print the receipt ?"
          }
        </Typography>
      </Modal>

      <VipBookingEnrollmentDetailModel
        is_modal_open={enrollment_model_open}
        set_is_modal_open={set_enrollment_model_open}
        enrollment_details={enrollment_details}
      />
    </div>
  );
};

export default VipBooking;
