import { Form } from "antd";

import { useDynamicSelector } from "../../../../../services/redux/selector";

import { retrieveItem } from "../../../../../helpers/functions";
import InputSubDtName from "../sub_district_user_inputs/input_sub_district_user_name";
import InputSubDistrictUserEmailId from "../sub_district_user_inputs/input_sub_district_user_email_id";
import InputSubDistrictUserContactNumber from "../sub_district_user_inputs/input_sub_district_user_contact_number";
import InputSubDistrictUserName from "../sub_district_user_inputs/input_sub_district_user_username";
import InputSubDistrictUserPassword from "../sub_district_user_inputs/input_sub_district_user_password";
import {
  dynamicClear,
  dynamicRequest,
  mutation_create_district_user,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  mutation_create_sub_district_user,
  mutation_update_district_user,
  mutation_update_sub_district_user,
} from "../../../../../services/redux/slices/graphql/graphql_district";
import InputSubDistrictPosSelector from "../../sub_district_form/sub_district_form_inputs/input_sub_district_pos_selector";
import { toast } from "react-toastify";
import InputSubDistrictUserActive from "../sub_district_user_inputs/input_sub_district_user_active";
import Mobile from "../../../booking/booking_mobile";

const SubDistrictUserForm = (props) => {
  const {
    header_type,
    edit_record,
    set_add_user_modal_visible,
    get_all,
    record_id,
    district_id,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const role = retrieveItem("role");
  const is_visible =
    role === "Super Admin" ||
    role === "MASM Admin" ||
    role === "District Admin";
  const {
    status: update_district_user_status,
    error: update_district_user_error,
  } = useDynamicSelector("update_sub_district_user");

  const {
    status: create_district_user_status,
    error: create_district_user_error,
  } = useDynamicSelector("create_sub_district_user");

  console.log("edit_redddcord", edit_record);

  useEffect(() => {
    if (header_type === "edit_user") {
      form.setFieldsValue({
        ...edit_record,
        // system_user: {
        //   username: edit_record?.system_user.username,
        //   password: edit_record.password,
        // },
        pos_id: edit_record?.pos_id,
      });
    } else {
      form.resetFields();
    }
  }, [header_type, edit_record]);

  const update_sub_district_user = (values) => {
    let keys = [{ key: "update_sub_district_user", loading: true }];
    let query = mutation_update_sub_district_user;
    let variable = {
      id: edit_record?.id,
      data: {
        name: values?.name,
        email: values?.email,
        mobile: values?.mobile,
        is_active: values?.is_active,
        system_user: {
          username: values?.mobile,
          password: "123456",
        },
      },
    };

    dispatch(dynamicRequest(keys, query, variable));
  };

  const create_sub_district_user = (values) => {
    let keys = [{ key: "create_sub_district_user", loading: true }];
    let query = mutation_create_sub_district_user;
    let variable = {
      data: {
        name: values?.name,
        email: values?.email,
        mobile: values?.mobile,
        is_active: values?.is_active,
        system_user: {
          username: values?.mobile,
          password: "123456",
        },
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const on_finish = (values) => {
    if (header_type === "edit_user") {
      console.log("header_type", header_type, values);
      update_sub_district_user(values);
    } else {
      create_sub_district_user(values);
    }
  };

  useEffect(() => {
    if (create_district_user_status === "success") {
      toast.success("Sub District User Created");
      dispatch(dynamicClear("create_sub_district_user"));
      get_all(record_id);
      set_add_user_modal_visible(false);
    } else if (create_district_user_error?.message) {
      toast.error(create_district_user_error?.message);
      dispatch(dynamicClear("create_sub_district_user"));
      // set_add_user_modal_visible(false);
    } else if (update_district_user_status === "success") {
      toast.success("Sub District User Updated");
      dispatch(dynamicClear("update_sub_district_user"));
      get_all(record_id);
      set_add_user_modal_visible(false);
    } else if (update_district_user_error?.message) {
      toast.error(update_district_user_error?.message);
      dispatch(dynamicClear("update_sub_district_user"));
      // set_add_user_modal_visible(false);
    }
  }, [
    create_district_user_status,
    create_district_user_error,
    update_district_user_status,
    update_district_user_error,
  ]);

  return (
    <Form
      key={record_id}
      layout="horizontal"
      colon={false}
      labelAlign="left"
      id={`add_edit_district_user_form-${record_id}`}
      onFinish={on_finish}
      form={form}
      style={{ width: "400px" }}
    >
      <InputSubDtName />
      <InputSubDistrictUserEmailId />
      <InputSubDistrictUserContactNumber />
      {is_visible && <InputSubDistrictUserActive />}

      {/* <InputSubDistrictUserName header_type={header_type} />
      <InputSubDistrictUserPassword header_type={header_type} /> */}
      {/* {role === "District Admin" && (
        <InputSubDistrictPosSelector
          name={"pos_id"}
          type="district_user"
          record_id={
            header_type === "edit_user"
              ? edit_record?.system_user?.id
              : district_id
          }
          header_type={header_type}
        />
      )} */}
    </Form>
  );
};

export default SubDistrictUserForm;
