import { Button, Col, Form, InputNumber, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  query_get_booking_user_details,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { retrieveItem } from "../../../../helpers/functions";

const VipBookingUserMobileNumberSection = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contact_number, set_contact_number] = useState();

  const [username_type, set_username_type] = useState();
  const system_user = retrieveItem("system_user");

  console.log("system_user", system_user);

  const handle_change = (value) => {
    const mobile_number_pattern = /^\d{10}$/;

    // Check if the input is valid
    if (mobile_number_pattern.test(value)) {
      set_contact_number(value);
    } else {
      set_contact_number(null);
    }
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^\d{10}$/;

  useEffect(() => {
    form.setFieldValue("mobile", system_user);
    form.setFieldValue("email", system_user);

    if (system_user) {
      if (mobileRegex.test(system_user)) {
        set_username_type("mobile");
        return Promise.resolve();
      } else if (emailRegex.test(system_user)) {
        set_username_type("email");
        return Promise.resolve();
      }
    }
  }, [system_user]);

  const get_booking_user_details = () => {
    if (contact_number) {
      const key = [{ key: "get_booking_user_details", loading: true }];
      const query = query_get_booking_user_details;
      const variables = {
        custom: {
          booking_user_reference: contact_number,
        },
      };
      dispatch(dynamicRequest(key, query, variables));
    }
  };

  useEffect(() => {
    get_booking_user_details();
  }, [contact_number]);

  return (
    <div className="vip_booking_form_item_container">
      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name={username_type === "mobile" ? "mobile" : "email"}
          label={username_type === "mobile" ? t("mobile_number") : t("email")}
          rules={[
            { required: true, message: t("Please enter your mobile number") },
          ]}
        >
          <Input
            onChange={handle_change}
            disabled={system_user ? true : false}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>

      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="leader_name"
          label={t("Leader Name")}
          rules={[
            { required: true, message: t("Please enter the leader's name") },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

export default VipBookingUserMobileNumberSection;
