import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import { Button, Modal } from "antd";
import { QueryRequest } from "../../../../services/apollo/api_service";
import { fileQuery } from "../../../../helpers/constants";
import { getUUID } from "../../../../helpers/functions";
import toast from "react-hot-toast";
import { FaCamera, FaRegEye } from "react-icons/fa";

const WebcamCapture = ({ set_url }) => {
  const { t } = useTranslation();
  const webcam_ref = useRef(null);
  const [image_src, set_image_src] = useState(null);
  const [is_modal_visible, set_is_modal_visible] = useState(false);
  const [download_link, set_download_link] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const capturePhoto = () => {
    if (webcam_ref.current) {
      const image = webcam_ref.current.getScreenshot();
      set_image_src(image);
    } else {
      toast.error(t("camera_not_ready"));
    }
  };

  const clearPhoto = () => set_image_src(null);

  const getSignedUrl = async (file) => {
    try {
      const fileUpload = { type: "photo", file_name: `${getUUID()}.jpg` };
      const response = await QueryRequest(fileQuery, fileUpload);
      const signedUrl = response.data?.get_signed_url?.url;

      if (signedUrl) {
        await upload_image_using_signed_url(signedUrl, file);
      } else {
        throw new Error("Signed URL not received");
      }
    } catch (error) {
      toast.error(t("Failed to upload image"));
      setIsLoading(false); // Reset loading state on error
    }
  };

  const upload_image_using_signed_url = async (signedUrl, file) => {
    try {
      await axios.put(signedUrl, file, {
        headers: { "Content-Type": file.type },
      });
      set_url(signedUrl.split("?")[0]);
      set_download_link(signedUrl.split("?")[0]);
      set_is_modal_visible(false);
      toast.success(t("image_uploaded_successfully"));
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(t("image_upload_failed"));
    } finally {
      setIsLoading(false); // Reset loading state after upload attempt
    }
  };

  const sendPhotoToServer = () => {
    if (!image_src) {
      toast.error(t("no_image_to_upload"));
      return;
    }

    setIsLoading(true); // Set loading state when submitting

    const canvas = document.createElement("canvas");
    const image = new Image();
    image.src = image_src;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);

      const format = "image/jpeg";
      const quality = 0.9;

      canvas.toBlob(
        async (blob) => {
          const file = new File([blob], "captured_image.jpg", { type: format });
          await getSignedUrl(file);
        },
        format,
        quality
      );
    };
  };

  const handleOpenModal = () => set_is_modal_visible(true);
  const handleCloseModal = () => {
    set_is_modal_visible(false);
    set_image_src(null);
    set_download_link("");
  };

  return (
    <div className="webcam-capture-container">
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Button className="take-photo-button" onClick={handleOpenModal}>
          <FaCamera /> {t("capture_id_proof")}
        </Button>
        {download_link && (
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <FaRegEye style={{ color: "#1e90ff" }} />
            <a
              href={
                download_link instanceof Blob
                  ? URL.createObjectURL(download_link)
                  : download_link
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#1e90ff", textDecoration: "underline" }}
            >
              {t("view")}
            </a>
          </div>
        )}
      </div>

      <Modal
        title={t("capture_id_proof")}
        open={is_modal_visible}
        onCancel={handleCloseModal}
        footer={null}
        centered
        width="80%"
        className="responsive-modal"
      >
        {image_src ? (
          <>
            <img
              src={image_src}
              alt={t("captured_image", "Captured Image")}
              className="responsive-image"
            />
            <HStack justifyContent="flex-end" space={5} wrap="wrap">
              <Button
                onClick={sendPhotoToServer}
                type="primary"
                loading={isLoading} // Add loading to submit button
              >
                {t("submit")}
              </Button>
              <Button onClick={clearPhoto} danger>
                {t("clear", "Clear")}
              </Button>
            </HStack>
          </>
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcam_ref}
              screenshotFormat="image/jpeg"
              className="responsive-webcam"
            />
            <Button onClick={capturePhoto} type="primary" block>
              {t("Capture Photo")}
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default WebcamCapture;
