import React, { useEffect } from "react";
import {
  dynamicRequest,
  query_get_booking_details,
  useDynamicSelector,
} from "../../../services/redux";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
import { Table } from "antd";
import { count_format, formatStatus } from "../../../helpers/constants";
import moment from "moment";
import { FaPrint } from "react-icons/fa";
import { useDispatch } from "react-redux";

const VipBookingUserHistoryTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: get_booking_list, loading: get_booking_list_loading } =
    useDynamicSelector("get_booking_list");
  const booking_details = useDynamicSelector("get_booking_detail");

  useEffect(() => {
    if (booking_details?.booking_reference) {
      console.log("booking_details", booking_details);
      const url = `/print-receipt/${booking_details?.booking_reference}/${booking_details?.booking_user?.mobile}`;
      const new_tab = window.open(url, "_blank", "width=850, height=900");
      if (new_tab) {
        new_tab.focus();
      }
    }
  }, [booking_details]);

  const get_booking_detail = (id) => {
    let key = [{ key: "get_booking_detail", loading: true }];
    let query = query_get_booking_details;
    let variables = {
      custom: {
        booking_reference: id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_booking_receipt = (booking_reference) => {
    get_booking_detail(booking_reference);
  };

  let columns = [
    {
      title: t("date"),
      dataIndex: ["date_slot", "date"],
      key: "date",
      fixed: "left",
      render: (date) => (date ? moment(date).format("DD/MM/YYYY") : "-"),
    },

    {
      title: t("table:booking_no"),
      dataIndex: "booking_reference",
      key: "booking_reference",
    },
    {
      title: t("table:booked"),
      dataIndex: "confirmed_count",
      key: "confirmed_count",
      render: count_format,
    },

    {
      title: t("table:leader_name"),
      dataIndex: ["booking_user", "name"],
      key: "leader_name",
    },
    {
      title: t("table:leader_no"),
      dataIndex: ["booking_user", "mobile"],
      key: "leader_no",
    },

    {
      title: t("table:payment_mode"),
      dataIndex: ["payment", "payment_mode"],
      key: "payment_mode",
      render: (record) => startCase(record),
    },

    {
      title: t("table:status"),
      dataIndex: "booking_status",
      key: "status",
      render: formatStatus,
      fixed: "right",
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "status",
      fixed: "right",
      render: (record) => {
        return (
          <>
            <FaPrint
              size={20}
              onClick={() => handle_booking_receipt(record?.booking_reference)}
              color="#3b90f5"
              className="icon"
            />
          </>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={get_booking_list}
      loading={get_booking_list_loading}
      className="vip-booking-table"
      rowClassName={(record, index) =>
        index % 2 === 0 ? "row-even" : "row-odd"
      }
      scroll={{
        x: 1900,
      }}
    />
  );
};
export default VipBookingUserHistoryTable;
