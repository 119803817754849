import { Table, Typography } from "antd";
import React, { useEffect } from "react";
import {
  dynamicRequest,
  query_enroll_action,
  query_get_booking_receipt,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import { capitalize, startCase, upperCase } from "lodash";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { format_duration, retrieveItem } from "../../../../helpers/functions";
import { MdAccessTimeFilled } from "react-icons/md";

const ReceiptTable = () => {
  const dispatch = useDispatch();
  const role = retrieveItem("role");

  const params = useParams();
  const { Text } = Typography;
  const {
    booked_on,
    mandram,
    booking_status,
    booking_reference: booking_number,
    count,
    date_slot,
    booking_user,
    time_slot,
    booked_by,
    enrolment_list,
  } = useDynamicSelector("get_booking_receipt");

  // const booked_by = retrieveItem("system_user");

  console.log("countddd", count);

  const receipt_no = enrolment_list?.map((count) => {
    return count?.sequence_count?.count;
  });

  const get_booking_receipt = () => {
    if (location.props_state) {
      let key = [{ key: "get_booking_receipt", loading: true }];
      let query = query_get_booking_receipt;
      let variables = {
        custom: {
          booking_reference: params.receipt_no,
          booking_user_mobile: params.mobile_number,
        },
      };
      dispatch(dynamicRequest(key, query, variables));
    }
  };

  useEffect(() => {
    if (location) get_booking_receipt();
  }, [location]);

  const columns = [
    {
      key: "header",
      dataIndex: "header",
      title: "Header",
      width: "30%",
      render: (text, record) => {
        if (
          record.header === "Date of Abishekam" ||
          record.header === "Number Of Irumudi" ||
          record.header === "Abishekam Time (G-Shed Reporting Time.)"
        ) {
          return <Text strong>{text}</Text>;
        }
        return text;
      },
    },
    {
      key: "value",
      dataIndex: "value",
      title: "Value",
      width: "50%",
      render: (text, record) => {
        if (
          record.header === "Date of Abishekam" ||
          record.header === "Number Of Irumudi" ||
          record.header === "Abishekam Time (G-Shed Reporting Time.)"
        ) {
          return <Text strong>{text}</Text>;
        }
        return text;
      },
    },
  ];

  const data = [
    ...(role === "MASM User" || role === "Enrolment Action User"
      ? [
          {
            key: "1",
            header: "Booking Number",
            value: `: ${booking_number}`,
          },
          {
            key: "1",
            header: "Receipt Number",
            value: `: ${receipt_no}`,
          },
        ]
      : []),

    {
      key: "1",
      header: "Date of Abishekam",
      // value: `: ${moment(date_slot?.date).format("DD-MMM-YYYY").toUpperCase()}`,
      value: `: ${date_slot?.date_string.toUpperCase()}`,
    },
    {
      key: "2",
      header: "Abishekam Time (G-Shed Reporting Time.)",
      value: (
        <span style={{ marginTop: "2px" }}>
          {`:`}
          {` ${format_duration(
            time_slot?.start_time_in_minutes
          )} - ${format_duration(time_slot?.end_time_in_minutes)}`}
        </span>
      ),
      align: "center",
      width: "200px",
    },
    {
      key: "3",
      header: "Number Of Irumudi",
      value: `: ${count}`,
    },
    {
      key: "4",
      header: "Booking Status",
      value: `: ${capitalize(booking_status)}`,
    },
    {
      key: "5",
      header: "VSG",
      value: `: ${mandram?.code} / ${upperCase(mandram?.name)}`,
    },
    {
      key: "23",
      header: "District",
      value: `: ${upperCase(mandram?.district?.name)}`,
    },
    {
      key: "6",
      header: "Incharge Name",
      value: `: ${mandram?.incharge_name}`,
    },
    {
      key: "7",
      header: "Incharge Mobile",
      value: `: ${mandram?.incharge_mobile}`,
    },
    {
      key: "8",
      header: "Booked Date",
      value: `: ${moment(booked_on).format("DD-MMM-YYYY").toUpperCase()}`,
    },
    {
      key: "8",
      header: "Booked By",
      value: `: ${startCase(booked_by?.name)}`,
    },
  ];

  const getRowClassName = (record) => {
    if (record.key === "2" || record.key === "3") {
      return "highlight-row"; // CSS class for custom background color
    }
    return "";
  };

  return (
    <div>
      <style>
        {`
          @media print {
            .print-table {
              margin: 2px auto;
              height: auto;
              padding: 10px;
              border-collapse: none !important;
              width: 500px;
            }
          
            .print-table th,
            .print-table td {
              border: 0; /* Set border to 0 to hide it */
              padding: 8px;
              border-collapse: collapse !important;
            }
          }
          
        `}
      </style>
      <Table
        style={{ border: "none" }}
        className="print-table"
        bordered={false}
        dataSource={data}
        columns={columns}
        showHeader={false}
        pagination={false}
        size="small"
        // rowClassName="custom-row"
        rowClassName={getRowClassName}
      />
    </div>
  );
};

export default ReceiptTable;
