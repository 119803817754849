import { Form, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";

const { Option } = Select;

const VipBookingUserTypeDistrict = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [district_list, set_district_list] = useState([]);
  const { items: districts } = useDynamicSelector("district_list");
  console.log("districts", districts);

  const handle_type_change = (e) => {
    form.setFieldsValue({ district: "" });
    get_district_list(e.target.value);
  };

  useEffect(() => {
    const filtered_districts = districts?.filter(
      (district_item) => district_item?.name !== "Additional"
    );
    set_district_list(filtered_districts || []);
  }, [districts]);

  const get_district_list = (booking_type) => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      filter: {
        is_foreign: booking_type === "vip" ? false : true,
      },
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(()=>{
    form.setFieldsValue({ booking_type: "vip" });
    get_district_list("vip");
  },[])

  return (
    <div className="vip_booking_form_item_container">
      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="booking_type"
          label={t("Booking Type")}
          rules={[
            { required: true, message: t("Please select a booking type") },
          ]}
        >
          <Radio.Group onChange={handle_type_change}>
            <Radio value="vip">{t("VIP")}</Radio>
            <Radio value="foreign">{t("Foreign")}</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="district"
          label={t("District")}
          rules={[
            { required: true, message: t("Please select your district") },
          ]}
        >
          <Select
            placeholder={t("Select district")}
            showSearch
            allowClear
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
          >
            {district_list?.map((district) => (
              <Select.Option key={district?.id} value={district?.id}>
                {district?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default VipBookingUserTypeDistrict;
