import { Form, Select } from "antd";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";

const EndPoints = (props) => {
  const { api_list } = props;
  const [api_items, set_api_items] = useState([]);

  console.log("api_items",api_items);
  
  useEffect(() => {
    let temp = api_list?.map((x) => ({
      label: startCase(x.name),
      value: x.id,
    }));
    set_api_items(temp);
  }, [api_list]);
  return (
    <>
      <Form.Item label="End Points" name={"api_module_id_list"}>
        <Select
          mode="multiple"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={api_items}
        />
        {/* {api_list?.map((item) => (
            <Option key={item.id} value={item.id}>
              {startCase(item.name)}
            </Option>
          ))}
        </Select> */}
      </Form.Item>
    </>
  );
};
export default EndPoints;
