import { Box, Text } from "native-base";
import React, { useEffect, useState } from "react";
import BookingPortal from "./booking_portal";
import IrumudiPortal from "./irumudi_portal";
import VipBookingPortal from "../vip_booking_portal/vip_booking_portal";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  get_active_season_query,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import VipBookingSecondVipPortal from "../vip_booking_portal/vip_booking_second_login_portal ";
import DistrictBookingPortalForm from "./booking_portal_form/district_booking_portal_form";
import DistrictBookingPortal from "./booking_portal_form/district_booking_portal";

const BookingAndIrumudiPortal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected_option, set_selected_option] = useState("");
  const { start_date: seasonStart, end_date: seasonEnd } =
    useDynamicSelector("get_active_season");

  useEffect(() => {
    fetchActiveSeason();
  }, []);

  const fetchActiveSeason = () => {
    const key = [{ key: "get_active_season", loading: false }];
    const query = get_active_season_query;
    dispatch(dynamicRequest(key, query, {}));
  };

  const handleOptionChange = (option) => {
    set_selected_option(option);
    dispatch(dynamicClear("get_booking_receipt"));
  };

  const handle_back_click = () => {
    set_selected_option("");
  };

  // Define colors for each option
  const optionColors = {
    "District Booking": "#ff5900",
    "Admin Portal": "#EA6A47",
    "Irumudi Receipt": "#AC3E31",
    "VIP Booking": "#D32d41",
  };

  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Text fontSize="15px">Welcome To Irumudi Booking System</Text>
        <Text color="#bd0000" fontSize="12px" marginTop="10px">
          {seasonStart && seasonEnd
            ? `Date: ${moment(seasonStart).format("DD-MMM-YYYY")} To ${moment(
                seasonEnd
              ).format("DD-MMM-YYYY")}`
            : null}
        </Text>
      </Box>

      {!selected_option && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Row
            justify="center"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {[
              "District Booking",
              "VIP Booking",
              "Admin Portal",
              "Irumudi Receipt",
            ].map((option) => (
              <Col
                key={option}
                className="login-menu"
                onClick={() => handleOptionChange(option)}
                style={{
                  backgroundColor: optionColors[option],
                  cursor:"pointer"
                }}
              >
                <div>{option}</div>
              </Col>
            ))}
          </Row>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
          flexDirection: "column",
        }}
      >
        {selected_option === "Admin Portal" && (
          <BookingPortal handle_back_click={handle_back_click} />
        )}
        {selected_option === "Irumudi Receipt" && (
          <IrumudiPortal handle_back_click={handle_back_click} />
        )}
        {selected_option === "VIP Booking" && (
          <VipBookingPortal handle_back_click={handle_back_click} />
        )}
        {selected_option === "District Booking" && (
          <DistrictBookingPortal handle_back_click={handle_back_click} />
        )}
        
      </div>
    </Box>
  );
};

export default BookingAndIrumudiPortal;
