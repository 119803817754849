import { Button, Modal, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../../services/redux";
import { ROUTES } from "../../../routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const VIPUserBookingNotificationModal = ({
  open_notification,
  set_open_notification,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { booking_reference, loading: booking_loading } =
    useDynamicSelector("get_booking_detail");

  const { booking: booking } = useDynamicSelector("check_payment_order_status");

  const on_finish = (values) => {
    set_open_notification(false);

    const url = `/print-receipt/${booking?.booking_reference}/${
      booking?.booking_user?.mobile
    }/${true}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");

    if (new_tab) {
      new_tab.focus();
    }
    handle_page_redirect();
  };

  const on_close = () => {
    set_open_notification(false);
  };

  const on_cancel = () => {
    set_open_notification(false);
  };

  const handle_page_redirect = () => {
    history.push(ROUTES.VIP_BOOKING_HISTORY);
  };
  return (
    <div>
      <Modal
        title={t("success")}
        open={open_notification}
        onCancel={on_cancel}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <>
            <Button
              key="no-button"
              style={{
                backgroundColor: "#168f92",
                color: "white",
                border: "transparent",
              }}
              onClick={on_close}
            >
              {t("no")}
            </Button>
            <Button
              key="yes-button"
              style={{
                backgroundColor: "#f96451",
                color: "white",
                border: "transparent",
              }}
              htmlType="submit"
              onClick={on_finish}
            >
              {t("yes")}
            </Button>
          </>,
        ]}
      >
        <div>
          <Typography
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              marginBottom: "3px",
            }}
          >
            {t("booking_success")}
          </Typography>

          <Typography>{t("success_notification")}</Typography>
        </div>

        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {booking?.booking_reference}
        </Typography>
      </Modal>
    </div>
  );
};

export default VIPUserBookingNotificationModal;
