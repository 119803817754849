import React, { useEffect } from "react";
import { Card, Image, Tag, Typography } from "antd";
import { count_format, formatStatus } from "../../../helpers/constants";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NO_DATA_FOUND, NO_DATA_IMAGE } from "../../../helpers/image_constants";
import {
  dynamicRequest,
  query_get_booking_details,
  useDynamicSelector,
} from "../../../services/redux";
import { FaPrint } from "react-icons/fa";
import { useDispatch } from "react-redux";

const { Text } = Typography;

const VipBookingMobileHistoryCard = ({ booking }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: booking_list_loading } =
    useDynamicSelector("get_booking_list");

  console.log("booking", booking);

  const booking_details = useDynamicSelector("get_booking_detail");

  useEffect(() => {
    if (booking_details?.booking_reference) {
      console.log("booking_details", booking_details);
      const url = `/print-receipt/${booking_details?.booking_reference}/${booking_details?.booking_user?.mobile}`;
      const new_tab = window.open(url, "_blank", "width=850, height=900");
      if (new_tab) {
        new_tab.focus();
      }
    }
  }, [booking_details]);

  const get_booking_detail = (id) => {
    let key = [{ key: "get_booking_detail", loading: true }];
    let query = query_get_booking_details;
    let variables = {
      custom: {
        booking_reference: id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_booking_receipt = (booking_reference) => {
    get_booking_detail(booking_reference);
  };

  return (
    <Card className="vip-booking-history-card" loading={booking_list_loading}>
      <div className="vip-booking-history-item">
        <Text className="vip-booking-history-title">{"Booking No: "} </Text>
        <Text className="vip-booking-history-text">
          {booking?.booking_reference}
        </Text>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Date: "}</Text>
          <Text className="vip-booking-history-text">
            {moment(booking?.date_slot?.date).format("DD/MM/YYYY")}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"booked: "}</Text>
          <Text className="vip-booking-history-text">
            {count_format(booking?.confirmed_count)}
          </Text>
        </div>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Leader Name: "}</Text>
          <Text className="vip-booking-history-text">
            {booking?.booking_user?.name}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Leader No: "}</Text>
          <Text className="vip-booking-history-text">
            {booking?.booking_user?.mobile}
          </Text>
        </div>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Booked By: "}</Text>
          <Text className="vip-booking-history-text">
            {booking?.booked_by?.name}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Payment Mode: "}</Text>
          <Text className="vip-booking-history-text">
            {startCase(booking?.payment?.payment_mode)}
          </Text>
        </div>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{t("status")}</Text>
          <Text
            className="vip-booking-history-text"
            style={{ marginLeft: "10px" }}
          >
            {formatStatus(booking?.booking_status) || "-"}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Tag
            onClick={() => handle_booking_receipt(booking?.booking_reference)}
            className="status-tag status-active"
          >
            {"Print Receipt"}
            <FaPrint
              size={11}
              color="#ffffff"
              style={{ marginLeft: "5px" }}
              // className="icon"
            />
          </Tag>
        </div>
      </div>
    </Card>
  );
};

export default VipBookingMobileHistoryCard;
