import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDynamicSelector } from "../../../../../services/redux/selector";
import { get_district_details_query } from "../../../../../services/redux/slices/graphql/graphql_district";
import { dynamicRequest } from "../../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../../../helpers/functions";

const MandramDistrictInput = (props) => {
  const { set_district_id, header_type } = props;

  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const district_name = retrieveItem("district_name");
  const district_id = retrieveItem("district_id");
  const { items: district_items } = useDynamicSelector("get_district_values");

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option?.label?.toLowerCase() || ""; // Using `label` for better compatibility
    return option_label.includes(input_value);
  };

  const get_district_details = () => {
    let key = [{ key: "get_district_values", loading: true }];
    let query = get_district_details_query;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    get_district_details();
  }, []);

  const handle_select_district = (value) => {
    set_district_id(value);
  };

  return (
    <Form.Item
      label="District"
      name="district_id"
      rules={[
        {
          required: true,
          message: "Please Select District",
        },
      ]}
    >
      {role === "Super Admin" ||
      role === "MASM Admin" ||
      role === "Mandram Renewal Officer" ? (
        <Select
          allowClear
          showSearch
          onChange={handle_select_district}
          filterOption={custom_filter_option} 
          options={district_items?.map((item) => ({
            value: item.id,
            label: item.name,
          }))} 
        />
      ) : (
        <Select
          allowClear
          disabled={header_type === "Edit Mandram"}
          onChange={handle_select_district}
        >
          <Select.Option key={district_name} value={district_id}>
            {district_name}
          </Select.Option>
        </Select>
      )}
    </Form.Item>
  );
};

export default MandramDistrictInput;
