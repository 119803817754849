import React, { useEffect } from "react";
import LoginHeaderBar from "../components/headerbar/login_header";
import VipBookingUserHistoryTable from "../components/vip_booking_history/vip_booking_user_history_table";
import VipBookingMobileHistoryCard from "../components/vip_booking_history/vip_booking_mobile_history_card";

import { Card, Image, Layout, Spin, Typography } from "antd";
import {
  dynamicRequest,
  query_booking_history_list,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { ScrollView } from "native-base";
import { useTranslation } from "react-i18next";
import { NO_DATA_IMAGE } from "../../helpers/image_constants";
import VipBookingSideBar from "../components/headerbar/vip_booking_side_bar";
import BackgroundLogo from "../../assets/icons/background_image.png";
import { retrieveItem } from "../../helpers/functions";
import { FaPrint } from "react-icons/fa";
const { Content } = Layout;

const VipBookingHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { items: booking_list, loading: booking_list_loading } =
    useDynamicSelector("get_booking_list");
  const vip_booking_id = retrieveItem("vip_booking_id");

  const compare_vip_booking_id =
    query_booking_history_list?.[0]?.useEffect(() => {
      if (vip_booking_id) {
      }
    }, [vip_booking_id]);

  const fetchBookingHistory = () => {
    let key = [{ key: "get_booking_list", loading: true }];
    let query = query_booking_history_list;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    fetchBookingHistory();
  }, [dispatch]);

  return (
    <Layout style={{ height: "100vh" }}>
      <VipBookingSideBar />
      <Content
        style={{
          backgroundImage: `url(${BackgroundLogo})`,
          backgroundSize: "cover",
        }}
      >
        <LoginHeaderBar />

        <div className="vip-history-header">
          <Typography.Title level={3}>
            {t("vip_booking_history")}
          </Typography.Title>
        </div>

        <div className="vip-history-table">
          <VipBookingUserHistoryTable />
        </div>
        {booking_list_loading ? (
          <Card
            className="vip-booking-history-loading"
            style={{ height: "300px", width: "100%" }}
          >
            <div id="page">
              <div id="container">
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="h3">loading</div>
              </div>
            </div>
          </Card>
        ) : booking_list?.length > 0 ? (
          <ScrollView className="vip-history-cards" style={{ padding: "10px" }}>
            {booking_list.map((booking) => (
              <VipBookingMobileHistoryCard key={booking.id} booking={booking} />
            ))}
          </ScrollView>
        ) : (
          <div
            className="vip-booking-history-no-data-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Card className="vip-booking-history-no-data">
              <Image
                src={NO_DATA_IMAGE}
                alt="no-data"
                preview={false}
                style={{ width: "100%", height: "150px", objectFit: "contain" }}
              />
            </Card>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default VipBookingHistory;
