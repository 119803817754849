import { Form, Input } from "antd";
import React from "react";

const InputSubDistrictAdminMobile = ({is_admin}) => {
  return (
    <>
      <Form.Item
        name={["sub_district_admin", "mobile"]}
        label="Mobile"
        rules={[
          {
            required: true,
            message: "Please enter mobile number",
          },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter a valid mobile number",
          },
        ]}
      >
        <Input
          type="text"
          placeholder="Mobile"
          disabled={is_admin ? false : true}
        />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictAdminMobile;
