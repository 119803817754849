import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  dynamicRequest,
  payment_redirect_query,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { initiate_payment_redirect } from "../../helpers/functions";
import { Player } from "@lottiefiles/react-lottie-player";

const PaymentRedirect = () => {
  const { id } = useParams("id");
  const dispatch = useDispatch();
  console.log("redirect_id", id);

  const { payment_url } = useDynamicSelector("payment_redirect");

  console.log("payment_url", payment_url);

  const get_payment_redirect = (create_booking_id) => {
    let key = [{ key: "payment_redirect", loading: true }];
    let query = payment_redirect_query;
    let variables = {
      ref_number: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_payment_redirect();
  }, [id]);

  useEffect(() => {
    if (payment_url) {
      initiate_payment_redirect({ payment_url });
    }
  }, [payment_url]);
  return (
    <div className="thank_you_modal_content_main_container">
      <div className="thank_you_modal_content">
        <Player
          autoplay
          loop
          src="https://lottie.host/61b7afec-37b4-4c51-9941-e96bc6e08aad/QA1VWQxkVn.json"
          className="thank_you_image"
          style={{ height: "400px", width: "400px" }}
        />
        <h6 className="thank_you_message" style={{ color: "#265ed6" }}>
          {"Redirecting to Secure Payment..."}
        </h6>
      </div>
    </div>
  );
};

export default PaymentRedirect;
