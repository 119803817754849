import { gql } from "@apollo/client";

export const query_get_all_district = gql`
  query get_district_list(
    $filter: get_district_list_filter_input
    $pagination: pagination_input
    $sort: sort_input
  ) {
    get_district_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        id
        name
        tamil_name
        code
        group_id
        order_number
        is_vip
        is_foreign
        extra_allowed_percentage
        is_active
        admin {
          name
          mobile
          email
          password
          system_user {
            id
            username
            name
            mobile
          }
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const query_get_one_district = gql`
  query get_district($id: String!) {
    get_district(id: $id) {
      id
      name
      tamil_name
      code
      group_id
      order_number
      extra_allowed_percentage
      is_vip
      is_foreign
      is_active
      admin {
        name
        mobile
        email
        password
        is_active
        system_user {
          id
          username
          name
          email
          is_active
        }
      }
      pos {
        id
        name
      }
      available_slot_list
    }
  }
`;

export const query_get_one_district_user = gql`
  query get_district_user($id: String!) {
    get_district_user: get_district(id: $id) {
      id
      admin {
        name
        mobile
        email
        password
        system_user {
          id
          username
          name
          email
        }
      }
    }
  }
`;

export const mutation_create_district = gql`
  mutation create_district($data: create_district_input!) {
    create_district(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_district = gql`
  mutation update_district($id: String!, $data: update_district_input!) {
    update_district(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_district = gql`
  mutation delete_district($id: String!) {
    delete_district(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_district_details_query = gql`
  query get_district_list(
    $filter: get_district_list_filter_input
    $sort: sort_input
  ) {
    get_district_values: get_district_list(filter: $filter, sort: $sort) {
      items {
        id
        name
        code
      }
    }
  }
`;

export const district_details_query = gql`
  query get_district_list(
    $filter: get_district_list_filter_input
    $sort: sort_input
  ) {
    district_list: get_district_list(filter: $filter, sort: $sort) {
      items {
        name
        id
      }
    }
  }
`;

export const query_get_district_users_list = gql`
  query get_district_user_list($filter: get_district_user_list_filter_input) {
    get_district_user_list(filter: $filter) {
      items {
        name
        mobile
        email
        password
        pos_id
        system_user {
          id
          username
          name
          email
          mobile
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_sub_district_users_list = gql`
  query get_sub_district_user_list(
    $filter: get_sub_district_user_list_filter_input
  ) {
    get_sub_district_user_list(filter: $filter) {
      items {
        name
        mobile
        email
        password
        pos_id
        system_user {
          id
          username
          name
          email
          mobile
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_district_user = gql`
  mutation create_district_user($data: create_district_user_input) {
    create_district_user(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_sub_district_user = gql`
  mutation create_sub_district_user($data: create_sub_district_user_input) {
    create_sub_district_user(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_district_user = gql`
  mutation update_district_user(
    $id: String!
    $data: update_district_user_input
  ) {
    update_district_user(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const mutation_update_sub_district_user = gql`
  mutation update_sub_district_user(
    $id: String!
    $data: update_sub_district_user_input
  ) {
    update_sub_district_user(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_district_user = gql`
  mutation delete_district_user($id: String!) {
    delete_district_user(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const mutation_delete_sub_district_user = gql`
  mutation delete_sub_district_user($id: String!) {
    delete_sub_district_user(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_unassigned_pos_list = gql`
  query get_unassigned_pos_list($filter: get_unassigned_pos_list_filter_input) {
    get_unassigned_pos_list(filter: $filter) {
      items {
        id
        name
        terminal_id
      }
    }
  }
`;

export const sub_district_details_query = gql`
  query get_sub_district_list(
    $filter: get_sub_district_list_filter_input
    $sort: sort_input
  ) {
    get_sub_district_list(filter: $filter, sort: $sort) {
      items {
        name
        id
        code
      }
    }
  }
`;
export const sub_district_list_details_query = gql`
  query get_sub_district_list(
    $filter: get_sub_district_list_filter_input
    $sort: sort_input
  ) {
    sub_district_list_history: get_sub_district_list(
      filter: $filter
      sort: $sort
    ) {
      items {
        name
        id
      }
    }
  }
`;
export const query_get_sub_district_filter = gql`
  query get_sub_district_list(
    $filter: get_sub_district_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_sub_district_filter: get_sub_district_list(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      items {
        id
        name
        is_active
      }
    }
  }
`;
export const query_get_available_slot_list = gql`
  query {
    get_available_slot_list {
      items {
        time_slot
      }
      error {
        message
      }
    }
  }
`;
