import { Form, Input } from "antd";
import React from "react";

const InputSubDistrictDistrictCode = ({is_admin}) => {
  return (
    <>
      <Form.Item
        name="code"
        label="Code"
        rules={[
          {
            required: true,
            message: "Please enter code",
          },
        ]}
      >
        <Input placeholder="District Code" allowClear={true}
          disabled={is_admin ? false : true}
          />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictDistrictCode;
