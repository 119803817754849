import React, { useEffect } from "react";
import { Radio, Form } from "antd";

const BookingTypeNormalVip = ({ form }) => {

  return (
    <Form.Item
      label="Booking Type"
      name="booking_type"
      rules={[{ required: true, message: "Please select a booking type" }]}
      // valuePropName="checked"  
    >
      <Radio.Group
        options={[
          { label: "Normal Booking", value: "normal" },
          { label: "VIP Booking", value: "vip" },
        ]}
      />
    </Form.Item>
  );
};

export default BookingTypeNormalVip;
