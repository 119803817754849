import { Layout, Menu, Image } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RiVipCrownFill } from "react-icons/ri";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { dynamicClear } from "../../../services/redux";
import { SIDE_BAR_IMAGE } from "../../../helpers/image_constants";
import { ROUTES } from "../../routes/my_routes";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { Sider, Content } = Layout;

const VipBookingSideBar = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const handleLogout = () => {
    clearLocalStorage();
    dispatch(dynamicClear("login"));
  };

  const handleMenuClick = (menu) => {
    if (menu?.key === "vip_booking_history") {
      history.push(ROUTES.VIP_BOOKING_HISTORY);
    } else if (menu?.key === "vip_booking_form") {
      history.push(ROUTES.VIP_BOOKING_USERS);
    }
  };

  const menuItems = [
    {
      key: "vip_booking_form",
      icon: <RiVipCrownFill />,
      label: "New Booking",
    },
    {
      key: "vip_booking_history",
      icon: <MdOutlineSpeakerNotes />,
      label: "Booking History",
    },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        backgroundImage: `url(${SIDE_BAR_IMAGE})`,
        backgroundSize: "cover",
        minWidth:"330px"
      }}
      className="vip-booking-side-bar"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          marginBottom: "10px",
        }}
      >
        <Image
          src={require("../../../assets/icons/Masm_logo.png")}
          alt="Adhiparasakthi Image"
          className="logo_image_drawer"
          preview={false}
        />
        <text className="header_text_drawer">
          ADHIPARASAKTHI SIDDHAR PEEDAM
        </text>
      </div>
      <hr></hr>
      <Menu
        theme="dark"
        // mode="inline"
        items={menuItems}
        onClick={handleMenuClick}
        style={{ background: "transparent" }}
        color="#ffffff"
      />
    </Sider>
  );
};

export default VipBookingSideBar;
