import { Avatar, Form, Radio, Segmented, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCamera, AiFillCreditCard } from "react-icons/ai";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import {
  CARD_IMAGE,
  CARD_IMAGE_G,
  CASH_IMAGE,
  ENROLLMENT_IMAGE,
  UPI_G,
  UPI_IMAGE,
} from "../../../../helpers/image_constants";

const BookingPayment = ({
  set_payment_status,
  payment_status,
  set_pay_mode,
  pay_mode,
  // has_pos,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const on_change = (value) => {
    set_pay_mode(value);
  };

  return (
    <div style={{ marginTop: "20px", width: "100%" }}>
      <Form.Item
        label={t("mode_of_payment")}
        name="payment_mode"
        rules={[
          {
            required: true,
            message: "Please Select Mode of payment",
          },
        ]}
      >
        <Space
          direction="vertical"
          style={{ width: "100%", flexWrap: "wrap", marginLeft: "50px" }}
        >
          <Segmented
            onChange={on_change}
            value={pay_mode}
            options={[
              {
                label: (
                  <div
                    style={{
                      padding: 4,
                      width: "100px",
                    }}
                  >
                    <Avatar
                      style={{
                        width: "70px",
                        height: "45px",
                        padding: "2px",
                      }}
                      src={UPI_IMAGE}
                      // src={!has_pos.result ? UPI_G : UPI_IMAGE}
                    />
                    <h4 style={{ fontWeight: "bold" }}>{t("Online")}</h4>
                  </div>
                ),
                value: "upi",
              },
            ]}
          />
        </Space>
      </Form.Item>
    </div>
  );
};

export default BookingPayment;
