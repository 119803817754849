import { Card, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import BookingConfirmationModal from "../components/booking/booking_confirmation_modal";
import { useTranslation } from "react-i18next";
import BookingNotificationModal from "../components/booking/booking_successfully_modal/booking_successfully_modal";
import CustomPageTitle from "../components/common/custom_page_title";
import BookingForm from "../components/booking/booking_form/booking_form";
import BookingIgnoreWaiting from "../components/booking/booking_form/booking_ignore_waiting";
import {
  dynamicRequest,
  query_current_date_time,
  query_yet_to_enroll,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import BookingDuplicateModal from "../components/booking/booking_form/booking_duplicate_modal";

const Booking = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [get_leader_details, set_get_leader_details] = useState();
  const [open_notification, set_open_notification] = useState(false);
  const [modal_open, set_modal_open] = useState(false);
  const [booking_details, set_booking_details] = useState({});
  const [selected_time, set_selected_time] = useState();
  const [selected_item, set_selected_item] = useState({
    id: "",
    date: "",
    date_string: "",
  });
  const [mandram_id, set_mandram_id] = useState();
  const [initial_values, set_initial_values] = useState({});
  const [payment_status, set_payment_status] = useState(null);
  const [payment_mode, set_payment_mode] = useState("");
  const [pay_id, set_pay_id] = useState();
  const [pay_mode, set_pay_mode] = useState("");
  const [ignore_waiting, set_ignore_waiting] = useState(false);
  const [count, set_count] = useState(0);
  const [value, set_value] = useState(1);
  const [amount, set_amount] = useState(0);
  const [waiting_conformation, set_waiting_conformation] = useState(false);
  const [leader_personal_details, set_leader_personal_details] = useState({});
  const [new_user_mobile_no, set_new_user_mobile_no] = useState();
  const [get_leader_mobile_no, set_leader_mobile_number] = useState();
  const [selected_id_proof, set_selected_id_proof] = useState("Aadhaar Card");
  const [leader_details, set_leader_details] = useState(false);
  const [duplicate_booking, set_duplicate_booking] = useState(false);
  const [duplicate_booking_modal, set_duplicate_booking_modal] =
    useState(false);
  const [is_order_status_cancelled, set_is_order_status_cancelled] =
    useState(false);
    
  useEffect(() => {
    current_date_time_query();
  }, []);

  const current_date_time_query = () => {
    let key = [{ key: "get_current_date_time", loading: true }];
    let query = query_current_date_time;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("booking")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Booking"} /> */}
        </Row>
      </Row>

      <Card
        style={{
          width: "98%",
          margin: "5px auto",
          minHeight: 0,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <BookingForm
          set_get_leader_details={set_get_leader_details}
          get_leader_details={get_leader_details}
          set_modal_open={set_modal_open}
          set_booking_details={set_booking_details}
          set_selected_time={set_selected_time}
          selected_time={selected_time}
          set_selected_item={set_selected_item}
          selected_item={selected_item}
          booking_details={booking_details}
          set_mandram_id={set_mandram_id}
          initial_values={initial_values}
          set_initial_values={set_initial_values}
          set_payment_mode={set_payment_mode}
          set_payment_status={set_payment_status}
          payment_status={payment_status}
          set_pay_mode={set_pay_mode}
          pay_mode={pay_mode}
          set_waiting_conformation={set_waiting_conformation}
          set_ignore_waiting={set_ignore_waiting}
          count={count}
          set_count={set_count}
          form={form}
          value={value}
          set_amount={set_amount}
          set_value={set_value}
          amount={amount}
          mandram_id={mandram_id}
          set_leader_personal_details={set_leader_personal_details}
          set_new_user_mobile_no={set_new_user_mobile_no}
          set_leader_mobile_number={set_leader_mobile_number}
          get_leader_mobile_no={get_leader_mobile_no}
          selected_id_proof={selected_id_proof}
          set_selected_id_proof={set_selected_id_proof}
          set_leader_details={set_leader_details}
          leader_details={leader_details}
        />

        <BookingConfirmationModal
          modal_open={modal_open}
          set_duplicate_booking={set_duplicate_booking}
          set_duplicate_booking_modal={set_duplicate_booking_modal}
          set_open_notification={set_open_notification}
          set_modal_open={set_modal_open}
          booking_details={booking_details}
          selected_time={selected_time}
          selected_item={selected_item}
          mandram_id={mandram_id}
          initial_values={initial_values}
          form={form}
          count={count}
          set_count={set_count}
          payment_mode={payment_mode}
          pay_id={pay_id}
          payment_status={payment_status}
          pay_mode={pay_mode}
          ignore_waiting={ignore_waiting}
          set_pay_mode={set_pay_mode}
          set_selected_item={set_selected_item}
          set_value={set_value}
          set_selected_time={set_selected_time}
          set_amount={set_amount}
          set_get_leader_details={set_get_leader_details}
          set_leader_mobile_number={set_leader_mobile_number}
          set_selected_id_proof={set_selected_id_proof}
          set_leader_details={set_leader_details}
          set_is_order_status_cancelled={set_is_order_status_cancelled}
          set_mandram_id={set_mandram_id}
        />

        <BookingDuplicateModal
          duplicate_booking_modal={duplicate_booking_modal}
          set_duplicate_booking_modal={set_duplicate_booking_modal}
          count={count}
          set_open_notification={set_open_notification}
          set_modal_open={set_modal_open}
          leader_personal_details={leader_personal_details}
          new_user_mobile_no={new_user_mobile_no}
          is_order_status_cancelled={is_order_status_cancelled}
          form={form}
          set_get_leader_details={set_get_leader_details}
          set_amount={set_amount}
          set_pay_mode={set_pay_mode}
          set_leader_details={set_leader_details}
          set_selected_id_proof={set_selected_id_proof}
        />

        <BookingNotificationModal
          count={count}
          open_notification={open_notification}
          set_open_notification={set_open_notification}
          set_modal_open={set_modal_open}
          leader_personal_details={leader_personal_details}
          new_user_mobile_no={new_user_mobile_no}
          is_order_status_cancelled={is_order_status_cancelled}
          form={form}
          set_get_leader_details={set_get_leader_details}
          set_amount={set_amount}
          set_pay_mode={set_pay_mode}
          set_leader_details={set_leader_details}
        />

        <BookingIgnoreWaiting
          set_waiting_conformation={set_waiting_conformation}
          set_ignore_waiting={set_ignore_waiting}
          waiting_conformation={waiting_conformation}
          set_modal_open={set_modal_open}
        />
      </Card>
    </div>
  );
};

export default Booking;
