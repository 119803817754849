import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import {
  VStack,
} from "native-base";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { retrieveItem } from "../../../../helpers/functions";
import { useLocation } from "react-router-dom";
import { startCase } from "lodash";

const Sidebar = ({ menu_list }) => {
  const { t } = useTranslation();
  const pathname = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [menu_items, setMenuItems] = useState([]);
  const [selectedKey, setSelectedKey] = useState([
    startCase(pathname.pathname),
  ]);
  let page_accesses = retrieveItem("page_accesses");
  const onMenuItemClick = (menu) => {
    setSelectedKey(menu?.keyPath);
    console.log("onMenuItemClick", menu);
    let find_menu = menu_list?.filter((list) => list?.key === menu?.key);
    // setSelectedKey(find_menu?.[0]?.label);
  };
  useEffect(() => {
    let _items = processItems(menu_list);
    setMenuItems(_items);
  }, [menu_list]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  function getItem(label, key, icon, url, accessKey, children) {
    let menu_label = label;
    if (url) {
      menu_label = (
        <Link to={url}>
          <span>{label}</span>
        </Link>
      );
    }
    return {
      key,
      icon,
      children,
      label: menu_label,
    };
  }

  const processItems = (_menu_list) => {
    let _items = [];
    for (let i = 0; i < _menu_list.length; i++) {
      let _menu = _menu_list[i];
      if (_menu.accessKey && page_accesses?.indexOf(_menu.accessKey) === -1) {
        continue;
      }
      let _children = null;
      if (_menu.children) {
        _children = processItems(_menu.children);
      }
      let _item = getItem(
        _menu.label,
        _menu.key,
        _menu.icon,
        _menu.url,
        _menu.accessKey,
        _children
      );
      _items.push(_item);
    }

    return _items;
  };
  const selectedMenuItemStyle = {
    color: "white",
  };

  const defaultMenuItemStyle = {
    color: "inherit",
  };
  let get_menu_item = [];
  const handle_change = (menu) => {
    console.log("handle_change", menu);
    let find_menu = menu_list?.filter((list) => list?.key === menu?.key);
    // setSelectedKey(menu?.keyPath);
    get_menu_item.push(menu?.keyPath);

    console.log("get_menu_item", get_menu_item);
  };
  const handleChange = (get_menu_item) => {
    console.log("handleChange", get_menu_item);
  };
  return (
    <div style={{ display: "flex" }}>
      <VStack>
        <Menu
          className="sidebar-menu"
          selectedKeys={selectedKey}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={menu_items}
          onSelect={onMenuItemClick}
          onClick={handle_change}
          onChange={handleChange}
        >
          {menu_items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              className={
                selectedKey.includes(item?.key)
                  ? "selected-menu-item"
                  : "default-menu-item"
              }
              // onClick={() => handle_change("test")}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </VStack>
    </div>
  );
};
export default Sidebar;
