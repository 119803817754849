import { Form, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux";

const SpotBookingDevoteesCount = ({ set_spot_booking_amount ,ticket_count,set_ticket_count}) => {
  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");
  const ticket_price = season_date_slot_list_items?.[0]?.season?.ticket_price;
  console.log("ticket_price", ticket_price);

  const { t } = useTranslation();
  const on_change = (val) => {
    set_ticket_count(val);
  };

  useEffect(() => {
    set_spot_booking_amount(ticket_count * ticket_price);
  }, [ticket_count]);

  return (
    <div>
      <Form.Item
        label={t("devotees_count")}
        name="devotees_count"
        rules={[
          {
            required: true,
            message: "Please enter the count!",
          },
        ]}
      >
        <InputNumber
          style={{
            width: "100%",
          }}
          placeholder={t("devotees_count")}
          onChange={on_change}
        />
      </Form.Item>
    </div>
  );
};

export default SpotBookingDevoteesCount;
