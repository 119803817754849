import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const VipBookingDistrict = ({ districts, set_district_id }) => {
  const { t } = useTranslation();

  const district_list = districts || [];

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };

  const handleChange = (value) => {
    set_district_id(value);
    // get_mandram_list();
  };

  return (
    <Form.Item
      label={t("district")}
      name="district"
      rules={[{ required: true, message: "Please select the District" }]}
    >
      <Select
        style={{
          width: "100%",
        }}
        placeholder={t("district")}
        showSearch
        filterOption={custom_filter_option}
        onChange={handleChange}
      >
        {district_list.map((district) => (
          <Select.Option key={district.id} value={district.id}>
            {district.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default VipBookingDistrict;
