import { Button, Modal, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { FaPrint } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { Pressable } from "native-base";
import moment from "moment";
import { startCase } from "lodash";
import BookingActionCancelBookingModal from "../booking_action_cancel_booking_modal/booking_action_cancel_booking_modal";
import {
  dynamicClear,
  dynamicRequest,
  mutation_booking_cancel,
  query_booking_action_list,
  query_booking_history_list,
  query_check_has_pos,
  query_get_asynchronous_request,
  send_sms_to_mobile_phone,
  useDynamicSelector,
} from "../../../../services/redux";
import { MdOutlinePayment, MdOutlineTextsms } from "react-icons/md";
import BookingActionPaymentModal from "../booking_action_payment_modal/booking_action_payment_modal";
import CustomSMSModal from "../../common/custom_sms_modal";
import { send_sms_to_mobile } from "../../../../services/redux/slices/graphql/graphql_sms";
import { toast } from "react-toastify";
import { retrieveItem } from "../../../../helpers/functions";
let modal_Instance_request = Modal;

const BookingActionList = ({ mandram_name, active_mandram_keys, has_pos }) => {
  const [open_notification, set_open_notification] = useState(false);
  const [modal_open, set_modal_open] = useState(false);
  const [cancel_booking_details, set_cancel_booking_details] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(10);
  const [open_payment_details, set_open_payment_details] = useState(false);
  const [booking_id, set_booking_id] = useState();
  const [booking_status, set_booking_status] = useState();
  const [payment_status, set_payment_status] = useState("");
  const [enrolled_status, set_enroll_status] = useState();
  const [update_payment_id, set_update_payment_id] = useState();

  const [cancel_booking_id, set_cancel_booking_id] = useState("");
  const [cancel_error, set_cancel_booking_error] = useState("");
  const [cancel_status, set_cancel_booking_status] = useState("");

  const [sms_modal_visible, set_sms_modal_visible] = useState(false);
  const [sms_booking_id, set_sms_booking_id] = useState();

  const role = retrieveItem("role");

  const { items: booking_action_list_items, loading: booking_list_loading } =
    useDynamicSelector("get_action_list");
  const { id: check_cancel_booking_id } = useDynamicSelector("cancel_booking");
  const {
    status: message_status,
    loading: message_loading,
    error: sms_error,
  } = useDynamicSelector("send_sms");
  console.log("sms_booking_id", sms_booking_id);
  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_action_list", loading: true }];
    let query = query_booking_action_list;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current(page_number);
    set_current_limit(page_limit);
  };

  let filter = {
    date_slot_id: mandram_name?.date_slot_id,
    mandram_id: mandram_name?.mandram_id,
  };
  let pagination = {
    page_number: 1,
    page_limit: 10,
  };

  const {
    // error: create_booking_error,
    loading: create_booking_request_loading,
    // id: create_booking_id,
    status: check_cancel_booking_status,
    response,
  } = useDynamicSelector("get_asynchronous_request");

  useEffect(() => {
    if (response) {
      const create_booking_details = JSON.parse(response);
      set_cancel_booking_status(create_booking_details?.status);
      set_cancel_booking_id(create_booking_details?.id);
      set_cancel_booking_error(create_booking_details?.error);
    }
  }, [response]);

  const closeModal_instance_request = () => {
    if (modal_Instance_request) {
      modal_Instance_request.destroyAll();
    }
  };

  useEffect(() => {
    if (check_cancel_booking_id) {
      modal_Instance_request.info({
        title: "Booking Cancel Process",
        content: (
          <div>
            <p>Booking Cancel is processing...</p>
            <p>Do not close or refresh the page.</p>
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ),
        okButtonProps: {
          style: { display: "none" },
        },
        getContainer: document.body,
        className: "custom-modal",
      });
      get_asynchronous_request();

      const interval_id = setInterval(() => get_asynchronous_request(), 5000);
      return () => clearInterval(interval_id);
    }
  }, [check_cancel_booking_id]);

  useEffect(() => {
    if (check_cancel_booking_status === "completed") {
      dispatch(dynamicClear("cancel_booking"));
    }
    if (cancel_status === "success") {
      closeModal_instance_request();
      mandram_wise_booking_list(filter, pagination);
      toast.success("Booking Cancelled");
      set_cancel_booking_status("");
      dispatch(dynamicClear("cancel_booking"));
      dispatch(dynamicClear("get_asynchronous_request"));
    } else if (cancel_status === "failed") {
      closeModal_instance_request();
      toast.error("Booking creation failed");
      dispatch(dynamicClear("cancel_booking"));
    }
  }, [cancel_status, check_cancel_booking_status]);

  const get_asynchronous_request = () => {
    let key = [{ key: "get_asynchronous_request", loading: true }];
    let query = query_get_asynchronous_request;
    let variables = {
      id: check_cancel_booking_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const mandram_wise_booking_list = (filter, pagination) => {
    let key = [{ key: "get_action_list", loading: true }];
    let query = query_booking_action_list;
    let variables = {
      filter,
      pagination,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const mandram_booking_cancel = (id) => {
    let key = [{ key: "cancel_booking", loading: true }];
    let query = mutation_booking_cancel;
    let variables = {
      id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const send_sms_api = (mobile, id) => {
    let key = [{ key: "send_sms", loading: true }];
    let query = send_sms_to_mobile_phone;
    let variables = {
      data: {
        booking_id: id,
        mobile,
      },
    };
    console.log("variables", variables);
    dispatch(dynamicRequest(key, query, variables));
  };

  // useEffect(() => {
  //   if (cancel_status === "success") {
  //     toast.success("Booking Cancelled");
  //     mandram_wise_booking_list(filter, pagination);
  //     dispatch(dynamicClear("cancel_booking"));
  //   } else if (cancel_error) {
  //     toast.error(cancel_error?.message);
  //     dispatch(dynamicClear("cancel_booking"));
  //   }
  // }, [cancel_status, cancel_error]);

  // useEffect(() => {
  //   mandram_wise_booking_list(filter, pagination);
  // }, [mandram_name, active_mandram_keys]);

  const handle_booking_receipt = (booking_details) => {
    const url = `/print-receipt/${booking_details?.booking_reference}/${booking_details?.booking_user?.mobile}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }
  };
  const handle_mandram_close = (booking_details) => {
    set_cancel_booking_details(booking_details);
    set_open_notification(true);
    // mandram_booking_cancel(booking_details?.id);
  };

  const handle_payment = (values) => {
    set_open_payment_details(true);
    set_booking_id(values.id);
  };

  const handle_sms = (_booking_id) => {
    console.log("_booking_id", _booking_id);
    set_sms_booking_id(_booking_id);
    set_sms_modal_visible(true);
  };

  useEffect(() => {
    if (message_status === "success") {
      toast.success("Message sent!");
      set_sms_modal_visible(false);
      dispatch(dynamicClear("send_sms"));
    } else if (sms_error?.message) {
      toast.error("Failed to send message");
      dispatch(dynamicClear("send_sms"));
    }
  }, [message_status, sms_error]);

  const BookingAction = ({ booking_details }) => {
    console.log("booking_details", booking_details);
    const enroll_status = booking_details?.confirmed_count;

    return (
      <div className="booking-action">
        {/* <Popover content={<p>Payment</p>} style={{ height: "10px" }}>
          <Button
            type="text"
            disabled={
              has_pos.result === false ||
              booking_details?.is_prepaid ||
              booking_details?.booking_status === "cancelled"
            }
            onClick={() => handle_payment(booking_details)}
          >
            <MdOutlinePayment size={20} style={{ color: "green" }} />
          </Button>
        </Popover> */}

        {booking_status !== "cancelled" ? (
          <Popover content={<p>Send SMS</p>}>
            <Button
              disabled={booking_details?.booking_status === "cancelled"}
              type="text"
              onClick={() => {
                handle_sms(booking_details?.id);
              }}
            >
              <MdOutlineTextsms size={20} style={{ color: "purple" }} />
            </Button>
          </Popover>
        ) : (
          ""
        )}

        <Popover content={<p>Print</p>}>
          <Button
            type="text"
            disabled={booking_details?.booking_status === "cancelled"}
            onClick={() => handle_booking_receipt(booking_details)}
          >
            <FaPrint size={17} style={{ color: "#0075ff" }} />
          </Button>
        </Popover>

        {/* {role != "District User" && ( */}
        <Popover content={<p>Cancel Booking</p>}>
          <Button
            type="text"
            disabled={
              booking_details?.is_prepaid ||
              booking_details?.booking_status === "cancelled"
            }
            onClick={() => handle_mandram_close(booking_details)}
          >
            <TiCancel size={23} style={{ color: "red", marginTop: "-2px" }} />
          </Button>
        </Popover>
        {/* )} */}
      </div>
    );
  };

  let columns = [
    {
      title: t("booking_number"),
      dataIndex: "booking_reference",
      key: "booking_reference",
      fixed: "left",
      width: 150,
    },

    {
      title: t("mandram_vsg"),
      dataIndex: "",
      key: "",
      align: "center",
      width: 200,
      render: (record) => {
        return `${record?.mandram?.code}/${record?.mandram?.name}`;
      },
    },

    {
      title: t("booked_counts"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        let result = record?.count;
        return result;
      },
    },

    {
      title: t("cancelled_count"),
      dataIndex: "cancelled_count",
      key: "cancelled_count",
      align: "center",
    },

    {
      title: t("enrolled"),
      dataIndex: "",
      key: "",
      align: "center",

      render: (record) => {
        const total_arrived_count = record?.enrolment_list?.reduce(
          (acc, booking) => {
            return acc + booking.arrived_count;
          },
          0
        );
        return total_arrived_count || 0;
      },
    },

    {
      title: t("not_arrived"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        let yet_to_enroll = 0;

        const total_enrolled = record?.enrolment_list?.reduce(
          (acc, booking) => {
            return acc + booking.arrived_count;
          },
          0
        );
        yet_to_enroll = record?.count - total_enrolled;
        if (yet_to_enroll < 0) {
          yet_to_enroll = 0;
          return yet_to_enroll;
        } else if (record?.cancelled_count > 0) {
          return 0;
        } else {
          return yet_to_enroll;
        }
      },
    },
    {
      title: t("excess"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        let excess_count = 0;
        let yet_to_enroll = 0;

        const total_enrolled = record?.enrolment_list?.reduce(
          (acc, booking) => {
            return acc + booking.arrived_count;
          },
          0
        );
        yet_to_enroll = record?.count - total_enrolled;
        if (yet_to_enroll < 0) {
          excess_count = yet_to_enroll * -1;
        }
        return excess_count;
      },
    },
    {
      title: t("group_leader_name"),
      dataIndex: "",
      key: "",
      align: "center",
      width: 170,
      render: (record) => {
        return (
          <p style={{ display: "flex", alignItems: "flex-start" }}>
            {record?.booking_user?.name}
          </p>
        );
      },
    },
    {
      title: t("leader_no"),
      dataIndex: "",
      key: "",
      align: "center",
      width: 150,
      render: (record) => {
        return record?.booking_user?.mobile;
      },
    },
    {
      title: t("booked_by"),
      dataIndex: "",
      key: "",
      align: "left",
      width: 150,
      render: (record) => {
        return startCase(record?.booked_by?.name);
      },
    },
    {
      title: t("booked_date"),
      dataIndex: "booked_on",
      key: "booked_on",
      align: "center",
      render: (booked_date) => {
        const booking_date = moment(booked_date).format("DD/MM/YYYY");
        return booking_date;
      },
    },
    {
      title: t("payment_mode"),
      dataIndex: "",
      key: "",
      width: 120,
      align: "center",
      render: (record) => {
        const paymentMode = startCase(record?.payment?.payment_mode);
        return paymentMode.toUpperCase();
      },
    },
    {
      title: t("payment_status"),
      dataIndex: "",
      key: "",
      align: "center",
      width: 120,
      render: (record) => {
        set_payment_status("");
        return record?.enrolment_list?.status
          ? record?.enrolment_list?.status
          : "-";
      },
    },
    {
      title: t("enrollment_status"),
      dataIndex: "",
      align: "center",
      render: (record) => {
        const enrolled_status = startCase(record?.enrolment_status);

        set_enroll_status(enrolled_status);
        return enrolled_status || "-";
      },
    },
    {
      title: t("status"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        const status = startCase(record?.booking_status);
        set_booking_status(status);

        // Increase opacity to 0.8 (you can adjust the value as needed)
        const opacityValue = 10;

        return <span style={{ opacity: opacityValue }}>{status}</span>;
      },
      fixed: "right",
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "",
      align: "center",
      fixed: "right",
      width: "200px",
      render: (record) => {
        return <BookingAction booking_details={record} />;
      },
    },
  ];
  return (
    <div>
      <Table
        className="custom-table-booking-action"
        columns={columns}
        dataSource={booking_action_list_items}
        loading={booking_list_loading}
        scroll={{
          x: 1900,
        }}
        pagination={{
          current: current,
          pageSize: current_limit,
          total: booking_action_list_items?.length,
          showSizeChanger: true,
          onChange: fetch_data,
          onShowSizeChange: (current, size) => {
            fetch_data(current, size);
          },
        }}
      />
      <BookingActionCancelBookingModal
        open_notification={open_notification}
        set_open_notification={set_open_notification}
        set_modal_open={set_modal_open}
        mandram_booking_cancel={mandram_booking_cancel}
        cancel_booking_details={cancel_booking_details}
      />

      <BookingActionPaymentModal
        open_payment_details={open_payment_details}
        set_open_payment_details={set_open_payment_details}
        booking_id={booking_id}
        update_payment_id={update_payment_id}
        set_update_payment_id={set_update_payment_id}
      />

      <CustomSMSModal
        loading={message_loading}
        sms_api={send_sms_api}
        modal_visible={sms_modal_visible}
        set_modal_visible={set_sms_modal_visible}
        sms_booking_id={sms_booking_id}
        has_pos={has_pos}
      />
    </div>
  );
};

export default BookingActionList;
