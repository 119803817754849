import { Button, Modal } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { IoMdClose } from "react-icons/io";
import SubDistrictAdminEditForm from "../sub_district_table/sub_district_admin_edit_form";

const EditSubDistrictAdminModal = (props) => {
  const { open, set_open } = props;


  const { loading: update_sub_district_loading } = useDynamicSelector(
    "update_sub_district"
  );
  const { loading: create_sub_district_loading } = useDynamicSelector(
    "create_sub_district"
  );

  return (
    <Modal
      title={"Edit Admin"}
      open={open}
      destroyOnClose={true}
      onCancel={() => set_open(false)}
      footer={[
        <Button key="cancel" onClick={() => set_open(false)}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          htmlType="submit"
          form="district_edit_admin_form"
          loading={update_sub_district_loading || create_sub_district_loading}
        >
          Save
        </Button>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <SubDistrictAdminEditForm {...props} />
    </Modal>
  );
};

export default EditSubDistrictAdminModal;
