import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PlaceHolder from "../../common/place_holder";
import moment from "moment";
import {
  dynamicRequest,
  dynamicSet,
  query_get_date_district_mandram_wise_summary,
  query_get_mandram_list,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { sub_district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { retrieveItem } from "../../../../helpers/functions";

const BookingHistoryForm = (props) => {
  const { abishekam_date } = props;
  
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { items: get_date_slot_list } = useDynamicSelector(
    "get_season_date_slot_list"
  );
  
  const { items: get_districts } = useDynamicSelector("district_list");
  const { items: get_sub_districts } = useDynamicSelector(
    "get_sub_district_list"
  );
  const { loading } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );
  const { items: get_mandrams } = useDynamicSelector("get_mandram_details");
  const [date_slot_list, set_date_slot_list] = useState([]);
  const [mandram_list, set_mandram_list] = useState([]);
  const [sub_district_list, set_sub_district_list] = useState([]);
  const district_id = Form.useWatch("district_id", form);
  const sub_district_id = Form.useWatch("sub_district_id", form);
  const mandram_id = Form.useWatch("mandram_id", form);
  const district_user_id = retrieveItem("district_id");
  const sub_district_user_id = retrieveItem("sub_district_id");

  console.log("sub_district_user_id",sub_district_user_id);
  

  const handle_values_change = (val, values) => {
    if (val?.district_id) {
      get_sub_district_list(val?.district_id);
    }
    if (values?.mandram_id) {
      console.log("mandram_id", values?.mandram_id);

      dispatch(dynamicSet("mandram_id", { mandram_id: values?.mandram_id }));
    }
    if (val?.sub_district_id) {
      get_mandram_list(values?.sub_district_id);
    }
  };
  const handle_change_district = () => {
    form.setFieldValue("mandram_id", undefined);
    form.setFieldValue("sub_district_id", null);
    set_sub_district_list([]);
    set_mandram_list([]);
  };
  const handle_change_sub_district = () => {
    form.setFieldValue("mandram_id", undefined);
    set_mandram_list([]);
  };
  const get_sub_district_list = (district_id) => {
    let key = [{ key: "get_sub_district_list", loading: true }];
    let query = sub_district_details_query;
    let variables = {
      filter: {
        district_id: district_id || undefined,
      },
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_mandram_list = (district_list) => {
    let key = [{ key: "get_mandram_details", loading: true }];
    let query = query_get_mandram_list;
    let variables = {
      filter: {
        sub_district_id: district_list || "",
      },
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  useEffect(() => {
    if (get_date_slot_list && get_date_slot_list?.length > 0) {
      let date_slot_list_array = get_date_slot_list?.map((list) => {
        return {
          label: moment(list?.date).format("DD/MM/YYYY"),
          value: list.id,
        };
      });
      set_date_slot_list(date_slot_list_array);
    }
  }, [get_date_slot_list]);

  useEffect(() => {
    if (district_id || (district_user_id && get_sub_districts?.length > 0)) {
      set_sub_district_list(get_sub_districts);
    }
    if (
      district_id ||
      (district_user_id && sub_district_id) ||
      (sub_district_user_id && get_mandrams?.length > 0)
    ) {
      set_mandram_list(get_mandrams);
    }
  }, [
    district_id,
    sub_district_id,
    mandram_id,
    get_sub_districts,
    get_mandrams,
  ]);
  console.log("get_mandrams", get_mandrams);
  useEffect(() => {
    if (!usePageComponentAccess("district_filter")) {
      form.setFieldValue("district_id", district_user_id);
      get_sub_district_list(district_user_id);
    } else if (!usePageComponentAccess("sub_district_filter")) {
      form.setFieldValue("sub_district_id", sub_district_user_id);
      get_mandram_list(sub_district_user_id);
    }
  }, []);

  useEffect(() => {
    if (abishekam_date) {
      form.setFieldsValue({abishekam_date_id:abishekam_date})
      get_booking_history();
    }
  }, [abishekam_date]);


  const get_booking_history=()=>{
    let key = [
      { key: "get_date_district_mandram_wise_summary", loading: true },
    ];
    let query = query_get_date_district_mandram_wise_summary;
    let variables = {
      filter: {
        date_slot_id_list: abishekam_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  }

  const handle_finish = (values) => {
    dispatch(dynamicSet("date_wise_active_key", { key: [] }));
    dispatch(dynamicSet("district_wise_active_key", { key: [] }));
    dispatch(dynamicSet("sub_district_wise_active_key", { key: [] }));
    dispatch(dynamicSet("mandram_wise_active_key", { key: [] }));
    let key = [
      { key: "get_date_district_mandram_wise_summary", loading: true },
    ];
    let query = query_get_date_district_mandram_wise_summary;
    let variables = {
      filter: {
        date_slot_id_list: values.abishekam_date_id,
        district_id_list: values.district_id ? [values.district_id] : undefined,
        mandram_id_list: values.mandram_id ? values.mandram_id : undefined,
        sub_district_id_list: values?.sub_district_id
          ? [values?.sub_district_id]
          : undefined,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  return (
    <Form
      form={form}
      onValuesChange={handle_values_change}
      layout="vertical"
      className="booking_history_form_container"
      onFinish={handle_finish}
    >
      <Form.Item label={t("form:abishekam_date")} name={"abishekam_date_id"}>
        <Select
          options={date_slot_list || []}
          style={{ width: "200px" }}
          mode="multiple"
          placeholder={<PlaceHolder label={t("form:all_abishekam_date")} />}
          allowClear
        />
      </Form.Item>
      
      {usePageComponentAccess("district_filter") && (
        <Form.Item label={t("form:district")} name={"district_id"}>
          <Select
            options={get_districts || []}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "200px" }}
            placeholder={<PlaceHolder label={t("form:all_district")} />}
            allowClear
            showSearch
            onChange={handle_change_district}
            filterOption={(input, option) =>
              option?.props?.name?.toLowerCase().indexOf(input.toLowerCase()) >=
              0
            }
          />
        </Form.Item>
      )}

      {usePageComponentAccess("sub_district_filter") && (
        <Form.Item label={t("form:sub_district")} name={"sub_district_id"}>
          <Select
            options={sub_district_list}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "200px" }}
            placeholder={<PlaceHolder label={t("form:all_sub_district")} />}
            allowClear
            showSearch
            onChange={handle_change_sub_district}
            filterOption={(input, option) =>
              option?.props?.name?.toLowerCase().indexOf(input.toLowerCase()) >=
              0
            }
          />
        </Form.Item>
      )}
      <Form.Item label={t("form:mandram_vsg")} name={"mandram_id"}>
        <Select
          options={mandram_list}
          fieldNames={{ label: "name", value: "id" }}
          style={{ width: "200px" }}
          placeholder={<PlaceHolder label={t("form:all_mandram")} />}
          allowClear
          showSearch
          mode="multiple"
          filterOption={(input, option) =>
            option?.props?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </Form.Item>
      <Button htmlType="submit" className="get-button" loading={loading}>
        {t("get")}
      </Button>
    </Form>
  );
};

export default BookingHistoryForm;
