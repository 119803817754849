import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { Card, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import SubUserTable from "../components/district/district_table/sub_user_table";
import {
  dynamicRequest,
  query_get_all_district_user_list,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { IoMdAddCircleOutline } from "react-icons/io";
import CustomButton from "../components/common/custom_button";
import { marginRight } from "styled-system";
import DistrictUserSelectFilter from "../components/managing_provisions/district_user_select_filter/district_user_select_filter";
import {
  query_get_district_users_list,
  query_get_one_district,
  // query_get_sub_district_users_list,
  query_get_unassigned_pos_list,
} from "../../services/redux/slices/graphql/graphql_district";
import { retrieveItem } from "../../helpers/functions";
import { query_get_sub_district_users_list } from "../../services/redux/slices/graphql/graphql_sub_district";
const { Title } = Typography;

const AdditionalUsers = () => {
  const [header_type, set_header_type] = useState();
  const [add_user_modal_visible, set_add_user_modal_visible] = useState();
  const [district_user_id, set_district_user_id] = useState("");
  // const [district_id, set_district_id] = useState();
  const [mandram_list, set_mandram_list] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const sub_district_id = retrieveItem("sub_district_id");
  const session_id = retrieveItem("session_id ");
  const district_id = retrieveItem("district_id");

  console.log("sub_district_id", district_id, session_id, sub_district_id);

  const { loading: mandrams_loading } = useDynamicSelector("get_mandrams");

  const table_row_class_name = (record, index) => {
    return index % 2 === 0 ? "odd-row" : "even-row";
  };
  const get_all_district_user_list = () => {
    let key = [{ key: "get_district_user_list", loading: true }];
    let query = query_get_district_users_list;
    let variable = {
      filter: {
        district_id: district_id || "",
      },
    };

    dispatch(dynamicRequest(key, query, variable));
  };
  const get_all_sub_district_user_list = () => {
    let key = [{ key: "get_sub_district_user_list", loading: true }];
    let query = query_get_sub_district_users_list;
    let variable = {
      filter: {
        sub_district_id: sub_district_id || "",
      },
    };

    dispatch(dynamicRequest(key, query, variable));
  };

  const get_one_district = (id) => {
    let keys = [{ key: "get_district", loading: true }];
    let query = query_get_one_district;
    let variable = {
      id: id || "",
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    if (role === "Sub-district Admin") {
      get_all_sub_district_user_list();
    } else if (role === "District Admin") {
      get_all_district_user_list();
    }
  }, [district_id, sub_district_id,role]);

  // const add_user = () => {
  //   set_header_type("add_user");
  //   set_add_user_modal_visible(true);
  // };
  const add_user = () => {
    set_header_type("add_user");
    set_add_user_modal_visible(true);
  };

  // const get_all_unassigned_pos = () => {
  //   let keys = [{ key: "get_unassigned_pos_list", loading: true }];
  //   let query = query_get_unassigned_pos_list;

  //   let variable = {
  //     filter: {
  //       district_id: district_id,
  //     },
  //   };
  //   dispatch(dynamicRequest(keys, query, variable));
  // };
  // useEffect(() => {
  //   get_all_unassigned_pos();
  // }, []);
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "15px" }}>
          <CustomPageTitle title={t("additional_users")} />
        </Row>

        {/* <Row style={{ margin: "8px", marginRight: "23px" }}>
          <CustomBreadCrumbs pages={"Home/Additional-Users"} />
        </Row> */}
      </Row>

      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            margin: "5px",
            padding: "5px",
          }}
        >
          <Title level={5} style={{ color: "black" }}>
            {role === "District Admin"
              ? t("district_user")
              : t("sub_district_user")}
          </Title>
          <Space>
            {/* <DistrictUserSelectFilter set_district_id={set_district_id} /> */}

            <div>
              <CustomButton
                button_name={t("add")}
                onclick_button={add_user}
                icon={<IoMdAddCircleOutline />}
              />
            </div>
          </Space>
        </Row>
        <SubUserTable
          get_all_districts_users={get_all_district_user_list}
          // get_all_sub_district_users={get_all_sub_district_user_list}
          get_one_district={get_one_district}
          table_row_class_name={table_row_class_name}
          header_type={header_type}
          set_header_type={set_header_type}
          add_user_modal_visible={add_user_modal_visible}
          set_add_user_modal_visible={set_add_user_modal_visible}
          set_district_user_id={set_district_user_id}
          district_user_id={district_user_id}
          district_id={district_id}
          mandram_list={mandram_list}
          set_mandram_list={set_mandram_list}
          mandrams_loading={mandrams_loading}
          // get_all_accessible_mandram_list={get_all_accessible_mandram_list}
        />
      </Card>
    </>
  );
};
export default AdditionalUsers;
