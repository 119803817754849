import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  create_system_otp_mutation,
  dynamicClear,
  dynamicRequest,
  query_login,
  useDynamicSelector,
} from "../../../../../services/redux";
import { toast } from "react-toastify";
import {
  navigate,
  redirect_page,
  storeItem,
} from "../../../../../helpers/functions";
import { ROUTES } from "../../../../routes/my_routes";
import BookingLoginFormItem from "../../booking_portal/booking_login_form_item";
import BookingPortalSendOtpButton from "../../booking_portal/booking_portal_send_otp_button";
import BookingLoginButton from "./booking_login_button";
import TermsAndConditions from "./terms_and_conditions";

const DistrictBookingPortalForm = ({ handle_back_click }) => {
  const [form] = Form.useForm();
  const [countdown, set_countdown] = useState(60);
  const [is_otp_status, set_is_otp_status] = useState(false);
  const [is_login_portal, set_is_login_portal] = useState(
    "login_with_password"
  );
  const [is_otp, set_is_otp] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    status: login_status,
    session_id,
    district,
    sub_district,
    error,
    system_user,
    page_accesses,
    page_component_accesses,
  } = useDynamicSelector("login");
  const booking_user_mobile = Form.useWatch("booking_user_mobile", form);

  const { status: create_otp_status, error: create_otp_error } =
    useDynamicSelector("create_system_otp");

  useEffect(() => {
    if (error) {
      if (
        error.message ===
        "Your account is inactive, please contact the administrator"
      ) {
        toast.error(
          "Your account is inactive, please contact the administrator"
        );
        dispatch(dynamicClear("login"));
      } else if (error.message === "not found") {
        toast.error("Please enter a valid username");
        dispatch(dynamicClear("login"));
      } else if (error.message === "invalid_credentials") {
        toast.error("Please enter a valid password");
        dispatch(dynamicClear("login"));
      } else if (error.message === "Invalid OTP") {
        toast.error("Please enter a valid OTP");
        dispatch(dynamicClear("login"));
      }
    }
    if (login_status === "success") {
      storeItem("session_id", session_id);
      storeItem("role", system_user?.roles?.[0]?.name);
      storeItem("page_accesses", page_accesses);
      storeItem("system_user", system_user?.name);
      storeItem("district_name", district?.name);
      storeItem("district_id", district?.id);
      storeItem("sub_district_id", sub_district?.id);
      storeItem("sub_district_", sub_district?.district?.id);
      storeItem("system_user_mail_id", system_user?.email);

      storeItem("page_component_accesses", page_component_accesses);

      if (system_user?.roles?.[0]?.name === "Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Super Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "District Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "District User") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Bank Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Bank Branch Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Dashboard User") {
        redirect_page(ROUTES.DASHBOARD);
      }
      if (system_user?.roles?.[0]?.name === "Dashboard Admin") {
        redirect_page(ROUTES.DASHBOARD);
      }
      if (system_user?.roles?.[0]?.name === "Helpdesk Officer") {
        redirect_page(ROUTES.RECEIPT);
      }
      if (system_user?.roles?.[0]?.name === "Mandram Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "MASM Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "MASM User") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Spl Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "VIP Enrolment Officer") {
        redirect_page(ROUTES.VIP_BOOKING);
      }
      if (system_user?.roles?.[0]?.name === "Enrolment Action User") {
        redirect_page(ROUTES.ENROLL_ACTION);
      }
      if (system_user?.roles?.[0]?.name === "Mandram Renewal Officer") {
        redirect_page(ROUTES.MANDRAM_RENEWAL);
      }
      if (system_user?.roles?.[0]?.name === "Sub-district Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Sub-district User") {
        redirect_page(ROUTES.BOOKING);
      }
    }
  }, [
    login_status,
    session_id,
    page_accesses,
    page_component_accesses,
    system_user,
    error,
  ]);

  const get_login = (values) => {
    let key = [{ key: "login", loading: true }];
    let query = query_login;
    const variables = {
      custom: {
        username: values?.booking_user_mobile,
        otp: values?.otp,
        is_otp_login: "true",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_finish = (values) => {
    if (!navigator.onLine) {
      toast.error("Something went wrong please try again");
      return;
    } else if (values?.booking_user_mobile && values?.otp) {
      get_login(values);
    } else if (values?.booking_user_mobile) {
      generate_otp_login_user();
    }
  };

  const handle_click = () => {
    navigate(ROUTES.TERMS_OF_SERVICES);
  };
  const handle_privacy_policy = () => {
    navigate(ROUTES.PRIVACY_POLICY);
  };

  const generate_otp_login_user = () => {
    const key = [{ key: "create_system_otp", loading: true }];
    const query = create_system_otp_mutation;
    const variables = {
      data: {
        mobile: booking_user_mobile,
        is_district_booking: true,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const [resend_otp, set_resend_otp] = useState(false);

  console.log("create_otp_error", create_otp_error);

  useEffect(() => {
    if (resend_otp) {
      const timer = setInterval(() => {
        set_countdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      if (countdown === 0) {
        set_resend_otp(false);
        set_countdown(60);
      }

      return () => clearInterval(timer);
    }
  }, [resend_otp, countdown]);

  useEffect(() => {
    if (create_otp_status === "success") {
      toast.success("OTP sent Successfully");
      set_is_otp_status(true);
      set_resend_otp(true);
      dispatch(dynamicClear("create_system_otp"));
    } else if (
      create_otp_error?.message ===
      "Your account is inactive, please contact the administrator"
    ) {
      toast.error("Your account is inactive, please contact the administrator");
      dispatch(dynamicClear("create_system_otp"));
    } else if (create_otp_error) {
      toast.error("Something went Wrong");
      dispatch(dynamicClear("create_system_otp"));
    }
    dispatch(dynamicClear("create_system_otp"));
  }, [create_otp_status, create_otp_error]);

  let resent_otp_content;
  if (resend_otp) {
    resent_otp_content = (
      <p className="otp-text">{`Resend OTP in ${countdown} seconds`}</p>
    );
  } else if (resend_otp === false && is_otp_status) {
    resent_otp_content = (
      <text onClick={generate_otp_login_user} style={{ cursor: "pointer" }}>
        Resend OTP
      </text>
    );
  }

  return (
    <>
      <div>
        <Form onFinish={handle_finish} id="login_form" form={form}>
          <BookingLoginFormItem />

          {!is_otp_status ? (
            <BookingPortalSendOtpButton
              generate_otp_login_user={generate_otp_login_user}
              form={form}
            />
          ) : (
            <>
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: t("otp"),
                  },
                ]}
              >
                <Input
                  maxLength={4}
                  placeholder="Enter OTP"
                  className="otp-input"
                />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {resent_otp_content}
              </div>
            </>
          )}
          {is_otp_status && <BookingLoginButton />}
        </Form>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Button
            style={{ width: "100%", height: "30px" }}
            onClick={handle_back_click}
          >
            Back
          </Button>
        </div>
        <TermsAndConditions />
      </div>
    </>
  );
};

export default DistrictBookingPortalForm;
