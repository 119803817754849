import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { query_get_available_slot_list } from "../../../../../services/redux/slices/graphql/graphql_district";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const { Option } = Select;

const InputDistrictAvailableTimeSlot = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: time_slots } = useDynamicSelector("get_available_slot_list");

  const get_available_slots = () => {
    const key = [{ key: "get_available_slot_list", loading: true }];
    const query = query_get_available_slot_list;
    const variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    get_available_slots();
  }, []);

  // Helper function to convert a numeric time range to a readable format
  const convertToTimeRange = (range) => {
    const [start, end] = range.split("-").map((num) => parseInt(num, 10));

    const formatTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      const period = hours < 12 ? "AM" : "PM";
      const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
      return `${formattedHour}:${mins === 0 ? "00" : mins} ${period}`;
    };

    return `${formatTime(start)} - ${formatTime(end)}`;
  };

  return (
    <Form.Item
      name="available_slot_list"
      label={t("applicable_time_slots")}
      rules={[{ required: true, message: t("please_select_time_slot") }]}
    >
      <Select
        mode="multiple"
        placeholder={t("select_time_slots")}
        style={{ width: "100%" }}
      >
        {Array.isArray(time_slots) &&
          time_slots.map(({ time_slot }) => (
            <Option key={time_slot} value={time_slot}>
              {convertToTimeRange(time_slot)}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default InputDistrictAvailableTimeSlot;
