import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_get_all_mandrams,
  query_get_mandram_wise_summary,
  useDynamicSelector,
} from "../../../../../services/redux";
import Loading from "../../../system/ui/loading/loading";
import { Collapse } from "antd";
import BookingHeaderList from "../../../booking_history/booking_history_header_list";
import DistrictHeaderList from "../../../booking_history/booking_history_form/booking_history_district_header_list";
import BookingMandramList from "../../../booking_history/booking_history_mandram_header_list";
import DateWiseReportMandramList from "../../../booking_history/date_wise_report_mandram_list";
import moment from "moment";
import PrintHeader from "../../../common/print_header";
import LoaderSpinner from "../../../common/custom_spinner";

const DateWiseReportCollapse = ({
  table_open,
  get_date_district_mandram_wise_summary,
  mandram_item,
  selected_last_least_date,
}) => {
  const dispatch = useDispatch();
  const [active_key, set_active_key] = useState([]);
  const [active_district_keys, set_active_district_keys] = useState([]);
  const [active_mandram_keys, set_active_mandram_keys] = useState([]);
  const [mandram_details, set_mandram_details] = useState({});
  const [booking_summary_list, set_booking_summary_list] = useState([]);
  const [booking_mandram_summary_list, set_mandram_booking_summary_list] =
    useState([]);
  const [mandram_summary_key_value, set_mandram_summary_key_value] =
    useState("");

  const {
    items: booking_list,
    loading: booking_list_loading,
    pagination: get_details_pagination,
  } = useDynamicSelector("get_booking_list");

  const { items: booking_summary, loading: booking_summary_loading } =
    useDynamicSelector("get_date_district_mandram_wise_summary");

  const { loading: get_date_district_mandram_wise_summary_loading } =
    useDynamicSelector("get_date_district_mandram_wise_summary");

  console.log("booking_summary", booking_summary);

  const { items: districts } = useDynamicSelector("district_list");

  const { items: mandram_summary, loading: mandram_summary_loading } =
    useDynamicSelector("get_mandram_wise_summary");

  const { items: date_wise_count_list, loading: get_date_wise_count_loading } =
    useDynamicSelector("get_date_wise_summary_count");

  const {
    items: date_wise_summary_list,
    loading: get_date_wise_summary_loading,
  } = useDynamicSelector("get_date_wise_summary");

  const get_all_mandram_list = () => {
    let key = [{ key: "get_all_mandrams", loading: true }];
    let query = query_get_all_mandrams;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };

  const mandram_wise_summary = (filter) => {
    let key = [{ key: "get_mandram_wise_summary", loading: true }];
    let query = query_get_mandram_wise_summary;
    let variables = {
      filter,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_mandram_list();
  }, []);

  useEffect(() => {
    if (booking_summary?.length > 0) {
      let temp = booking_summary?.filter((x) => x.district_id !== null);
      set_booking_summary_list(temp);
    }
  }, [booking_summary]);

  useEffect(() => {
    if (mandram_summary?.length > 0) {
      set_mandram_booking_summary_list(mandram_summary);
    }
  }, [mandram_summary]);

  const grouped_by_date = booking_summary_list?.reduce((acc, item) => {
    const date = moment(item.date).tz("Asia/Kolkata").format("DD/MM/YYYY");
    if (!acc[date]) {
      acc[date] = {
        date,
        date_slot_id: item.date_slot_id,
        total_arrived: 0,
        total_booked: 0,
        total_enrolled: 0,
        total_yet_to_enroll_count: 0,
        total_cancelled_count: 0,
        total_excess_count: 0,
        total_received_amount: 0,
        total_prepaid_amount: 0,
        enrolled: 0,
        districts: {},
      };
    }
    acc[date].total_arrived += item?.count?.arrived;
    acc[date].total_booked += item?.count?.booked;
    acc[date].total_yet_to_enroll_count += item?.count?.yet_to_enroll;
    acc[date].total_cancelled_count += item?.count?.cancelled;
    acc[date].total_excess_count += item?.count?.excess;
    acc[date].total_enrolled += item?.count?.enrolled;
    acc[date].total_received_amount += item.received_amount;
    acc[date].total_prepaid_amount += item.prepaid_amount;

    if (item.district_id && districts) {
      if (!acc[date].districts[item.district_id]) {
        acc[date].districts[item.district_id] = {
          date,
          district_id: item.district_id,
          district_name: districts?.find(
            (list) => list?.id === item?.district_id
          )?.name,
          total_arrived: 0,
          total_booked: 0,
          total_enrolled: 0,
          total_yet_to_enroll_count: 0,
          total_cancelled_count: 0,
          total_excess_count: 0,
          total_received_amount: 0,
          total_prepaid_amount: 0,
          mandram_items: {},
        };
      }
      acc[date].districts[item.district_id].total_arrived +=
        item?.count?.arrived;
      acc[date].districts[item.district_id].total_booked += item?.count?.booked;
      acc[date].districts[item.district_id].total_yet_to_enroll_count +=
        item?.count?.yet_to_enroll;
      acc[date].districts[item.district_id].total_cancelled_count +=
        item?.count?.cancelled;
      acc[date].districts[item.district_id].total_excess_count +=
        item?.count?.excess;
      acc[date].districts[item.district_id].total_enrolled +=
        item?.count?.enrolled;
      acc[date].districts[item.district_id].total_received_amount +=
        item?.received_amount;
      acc[date].districts[item.district_id].total_prepaid_amount +=
        item?.prepaid_amount;
    }

    return acc;
  }, {});

  const handle_date_collapse = (key, item, data) => {
    dispatch(dynamicClear("get_mandram_wise_summary"));
    dispatch(dynamicClear("get_booking_list"));
    // set_active_district_keys([]);
    set_active_mandram_keys([]);
    if (data[key] && key === data[key]?.date) {
      console.log("get_booking_list",data[key])
      set_active_key([key]);
  }
  };

  const handle_district_collapse = (key, item, list, active_key) => {
    if (!key || typeof key !== "string" || !list || !list.districts) {
        console.error("Invalid key or list:", { key, list });
        return;
    }
    const split_key = key?.split("_")?.[1];
    if (!split_key) {
        console.error("Invalid split_key:", key);
        return;
    }
    set_mandram_summary_key_value(split_key);
    dispatch(dynamicClear("get_mandram_wise_summary"));
    dispatch(dynamicClear("get_booking_list"));
    const district = list.districts[split_key];
    console.log("listttttt", list,district);

    if (!district) {
        console.error("District not found for split_key:", split_key);
        return;
    }
    const district_id = district?.district_id;
    const sub_district_id=district?.sub_district_id
    if (split_key === district_id) {
        const filter = {
            district_id: district_id,
            sub_district_id:sub_district_id,
            date_slot_id: list?.date_slot_id,
            mandram_id_list: mandram_item,
        };
        set_active_district_keys([key]);
        mandram_wise_summary(filter);
    }
    set_active_mandram_keys([]);
};


  const handle_change_mandram_collapse = (key, item, list) => {
    set_mandram_details(item);

    set_active_mandram_keys([key]);
  };

  // useEffect(() => {
  //   get_date_district_mandram_wise_summary();
  // }, []);

  const columns = [
    {
      title: "Date",
    },
    {
      title: "District",
    },
    {
      title: "Mandram",
    },
    {
      title: "Booked",
    },
    {
      title: "Arrived",
    },
    {
      title: "Not Arrived",
    },
  ];

  const bookings_column = [
    {
      title: "Booking No",
    },
    {
      title: "Irumudi Count",
    },
    {
      title: "Mandram(VSG)/ID",
    },
    {
      title: "Abishekam Date",
    },
    {
      title: "Status",
    },
    {
      title: "Arrived",
    },
    {
      title: "Not Arrived",
    },
    {
      title: "Excess",
    },
    {
      title: "Booked",
    },
    {
      title: "Payment Mode",
    },
    {
      title: "Payment Status",
    },
    {
      title: "Leader",
    },
  ];

  return (
    <div>
      {booking_summary && (
        <div>
          {/* <BookingHistoryCollapseButton
            setExpand={set_is_expand}
            collapse_all_panels={collapse_all_panels}
            booking_summary={grouped_by_date}
            expand_all_panels={expand_all_panels}
          /> */}
          {booking_summary_loading ||
          get_date_district_mandram_wise_summary_loading ? (
            // <Loading marginTop="10px" />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <LoaderSpinner />
            </div>
          ) : (
            <div style={{ marginTop: "20px" }} className="screen-table ">
              <Collapse
                accordion={true}
                activeKey={active_key}
                onChange={(key, al, all) => {
                  set_active_key(key ? [key] : []);
                  // set_active_district_keys([]);
                  handle_date_collapse(
                    key,
                    grouped_by_date[key[key?.length - 1]],
                    grouped_by_date
                  );
                }}
              >
                {Object.keys(grouped_by_date)?.map((item, index) => (
                  <Collapse.Panel
                    key={item}
                    style={{ backgroundColor: "#275670", color: "white" }}
                    header={<BookingHeaderList item={grouped_by_date[item]} />}
                  >
                    <Collapse
                      style={{ backgroundColor: "#23545B", color: "white" }}
                      accordion={true}
                      activeKey={active_district_keys}
                      onChange={(key, val, values) => {
                        handle_district_collapse(
                          key,
                          grouped_by_date[item]?.districts[
                            key[key?.length - 1]
                          ],
                          grouped_by_date[item],
                          ""
                        );
                      }}
                    >
                      {Object.keys(grouped_by_date[item]?.districts)?.map(
                        (district_id) => (
                          <Collapse.Panel
                            key={`${item}_${district_id}`}
                            header={
                              <DistrictHeaderList
                                district={
                                  grouped_by_date[item]?.districts[district_id]
                                }
                              />
                            }
                          >
                            {mandram_summary_loading ? (
                              <Loading marginTop="10px" />
                            ) : (
                              <Collapse
                                accordion={true}
                                activeKey={active_mandram_keys}
                                onChange={(key, value) => {
                                  handle_change_mandram_collapse(
                                    key,
                                    booking_mandram_summary_list[Number(key)],
                                    booking_mandram_summary_list
                                  );
                                }}
                              >
                                {mandram_summary_key_value === district_id &&
                                  booking_mandram_summary_list?.map(
                                    (mandram_name, index) => (
                                      <Collapse.Panel
                                        key={index}
                                        style={{
                                          backgroundColor: "#34495E",
                                          color: "white",
                                        }}
                                        header={
                                          <BookingMandramList
                                            item={mandram_name}
                                          />
                                        }
                                      >
                                        <div key={index}>
                                          <DateWiseReportMandramList
                                            mandram_name={
                                              active_mandram_keys?.length !== 0
                                                ? mandram_details
                                                : {}
                                            }
                                            active_mandram_keys={
                                              active_mandram_keys
                                            }
                                            mandram_names={mandram_name}
                                            district={
                                              grouped_by_date[item]?.districts[
                                                district_id
                                              ]
                                            }
                                            item={grouped_by_date[item]}
                                          />
                                        </div>
                                      </Collapse.Panel>
                                    )
                                  )}
                              </Collapse>
                            )}
                          </Collapse.Panel>
                        )
                      )}
                    </Collapse>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          )}
        </div>
      )}

      {/* table */}
      <div style={{ padding: "10px" }}>
        <div className="print-header" style={{ padding: "15px" }}>
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            DATE WISE REPORT
          </h4>
          <h5
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {Object.keys(selected_last_least_date).length > 0
              ? `${selected_last_least_date?.least_date} To ${selected_last_least_date?.last_date}`
              : "All Dates"} */}
          </h5>
        </div>

        <table
          className="table table-bordered print-table"
          style={{ width: "99%", marginTop: "10px", marginLeft: "-5px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns.map((column, i) => (
                <th className="header-style" style={{ width: "100px" }} key={i}>
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {date_wise_count_list?.map((item, index) => (
              <tr key={index}>
                <td className="body-style" style={{ textAlign: "center" }}>
                  {item?.abishekam_date
                    ? moment(item?.abishekam_date).format("DD-MM-YYYY")
                    : "-"}
                </td>
                <td className="body-style" style={{ textAlign: "left" }}>
                  {item?.district_name}
                </td>
                <td className="body-style" style={{ textAlign: "left" }}>
                  {item?.mandram_name
                    ? `${item.mandram_code} / ${item.mandram_name}`
                    : "-"}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {item?.mandram_total_count}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {item?.mandram_total_enrolled
                    ? item?.mandram_total_enrolled
                    : "-"}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {item?.mandram_total_not_enrolled
                    ? item?.mandram_total_not_enrolled
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table
          className="table table-bordered print-table"
          style={{ width: "100%", marginTop: "10px", marginLeft: "-30px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {bookings_column.map((column, i) => (
                <th className="header-style" style={{ width: "100px" }} key={i}>
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {date_wise_summary_list?.map((item, index) => (
              <tr key={index}>
                <td className="body-style" style={{ textAlign: "center" }}>
                  {item?.booking_reference}
                </td>
                <td
                  className="body-style"
                  style={{ textAlign: "right", width: "40px" }}
                >
                  {item?.booking_count}
                </td>
                <td className="body-style" style={{ textAlign: "left" }}>
                  {`${item?.mandram_code}/`}
                  <br></br>
                  {`${item?.mandram_name}`}
                </td>
                <td className="body-style" style={{ textAlign: "center" }}>
                  {moment(item?.abishekam_date).format("DD-MM-YYYY")}
                </td>
                <td className="body-style" style={{ textAlign: "center" }}>
                  {item?.booking_status}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {item?.booking_count}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {item?.not_enrolled_count}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {item?.excess_count}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {`${item?.booked_by_name}`}
                  <br></br>
                  {`${moment(item?.booked_date).format("DD-DD-YYYY")}`}
                </td>
                <td className="body-style" style={{ textAlign: "center" }}>
                  {item?.payment_mode}
                </td>
                <td className="body-style" style={{ textAlign: "center" }}>
                  {item?.status}
                </td>
                <td className="body-style" style={{ textAlign: "left" }}>
                  {`${item?.leader_name}`}
                  <br></br>
                  {`${item?.leader_mobile}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <style>
        {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @page {
        size: A4;
        margin: 1cm;
      }

      body::before {
        content: counter(page);
        top: 0;
        right: 1cm;
      }
    }
  `}
      </style>
    </div>
  );
};

export default DateWiseReportCollapse;
