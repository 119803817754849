import React from "react";

import { Col, Row } from "antd";
import InputSubDistrictAdminName from "../sub_district_form_inputs/input_sub_district_admin_name";
import InputSubDistrictAdminEmail from "../sub_district_form_inputs/input_sub_district_admin_email";
import InputSubDistrictAdminMobile from "../sub_district_form_inputs/input_sub_district_admin_mobile";
import InputSubDistrictAdminUserName from "../sub_district_form_inputs/input_sub_district_admin_username";
import InputSubDistrictAdminPassword from "../sub_district_form_inputs/input_sub_district_system_user_password";
import InputSubdistrictAdminActive from "../sub_district_form_inputs/input_subdistrict_admin_active";
import { retrieveItem } from "../../../../../helpers/functions";

const SubDistrictAdmin = (props) => {
  const role = retrieveItem("role");

  const is_admin =
    role === "Super Admin" ||
    role === "MASM Admin" ||
    role === "Mandram Renewal Officer";

  const is_visible = role === "Super Admin" || role === "MASM Admin";
  return (
    <>
      <Col
        style={{
          padding: "10px",
          borderRadius: "6px",
          width: "100%",
          backgroundColor: "rgba(211, 211, 211, 0.2)",
          marginTop: "10px",
        }}
      >
        <h3 style={{ marginLeft: "12px" }}>Sub District Admin</h3>
        <Row gutter={16} justify={"space-between"}>
          <Col span={12}>
            <InputSubDistrictAdminName is_admin={is_admin} />
            <InputSubDistrictAdminEmail is_admin={is_admin} />
          </Col>
          <Col span={11}>
            <InputSubDistrictAdminMobile is_admin={is_admin} />
            {is_visible && <InputSubdistrictAdminActive />}
            {/* <InputSubDistrictAdminUserName header_type={props.header_type} />
            <InputSubDistrictAdminPassword header_type={props.header_type} /> */}
          </Col>
        </Row>
      </Col>
    </>
  );
};
export default SubDistrictAdmin;
