import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const BookingLoginFormItem = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        name="booking_user_mobile"
        rules={[
          {
            required: true,
            message: t("Please enter your mobile number"),
          },
          {
            pattern: /^[0-9]{10}$/,
            message: t("Mobile number must be exactly 10 digits"),
          },
        ]}
      >
        <Input
          className="placeholder-black"
          type="text"
          placeholder={t("Mobile")}
          style={{
            backgroundColor: "#FFDE8B",
            color: "#2e291e",
            border: "1px solid black",
          }}
        />
      </Form.Item>
    </div>
  );
};

export default BookingLoginFormItem;
