import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../../services/redux";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";

const BookingPortalSendOtpButton = ({ generate_otp_login_user, form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: otp_loading } = useDynamicSelector("create_system_otp");

  const booking_user_mobile = Form.useWatch("booking_user_mobile", form);

  const handle_send_otp = () => {
    generate_otp_login_user();
  };

  return (
    <div>
      <Button
        onClick={handle_send_otp}
        loading={otp_loading}
        className="login_button"
        style={{
          backgroundColor: "#1e90ff",
          color: "white",
          marginBottom: "10px",
        }}
        disabled={booking_user_mobile ? false : true}
      >
        {t("send_otp")}
      </Button>
    </div>
  );
};

export default BookingPortalSendOtpButton;
