import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  Link,
  Menu,
  Pressable,
  Text,
  VStack,
} from "native-base";
import Avatar from "react-avatar";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { clearLocalStorage, navigate, retrieveItem } from "@helpers/functions";

import { useDispatch } from "react-redux";

import HeaderBreadcrumb from "./header_breadcrumb";
import { useTranslation } from "react-i18next";
import { Button, Drawer, Form, Modal } from "antd";
// import ChangePassword from "../change_password/change_password";
import { MdOutlineNotifications } from "react-icons/md";
import TopHeader from "./top_header";
import UserIcon from "@assets/icons/user.png";
import ChangePassword from "../../change_password/change_password_form/change_password_main_form";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { unset_counter_user_mutation } from "../../../../services/redux/slices/graphql/graphql_enroll_booking";
import axios from "axios";

const HeaderBar = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    status: change_password_status,
    error: change_password_error,
    loading,
  } = useDynamicSelector("change_password");

  const role = retrieveItem("role");
  const system_user = retrieveItem("system_user");
  const district_name = retrieveItem("district_name");

  const [is_modal_open, set_is_modal_open] = useState(false);
  const [counter_mac_address, set_counter_mac_address] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:7878/my-address");
        set_counter_mac_address(response?.data);
      } catch (error) {
        console.error("Error fetching MAC address:", error);
      }
    };
    fetchData();
  }, []);

  const handle_modal_close = () => {
    set_is_modal_open(false);
  };

  const handle_modal_open = () => {
    set_is_modal_open(true);
  };
  const handle_profile = (values) => {
    console.log("values33", values);
    navigate(ROUTES.UPDATE_PROFILE);
  };

  const handleLogout = () => {
    unset_counter_for_user();

    setTimeout(() => {
      clearLocalStorage();
      dispatch(dynamicClear("login"));
      dispatch(dynamicClear("role"));
      dispatch(dynamicClear("session_id"));
      dispatch(dynamicClear("data"));

      //  navigate(ROUTES.REDIRECTOR);
      window.location.href = `${ROUTES.REDIRECTOR}`;
    }, 1500);
  };
  const unset_counter_for_user = () => {
    let keys = [{ key: "unset_counter_user", loading: true }];
    let query = unset_counter_user_mutation;
    let variables = {
      id: counter_mac_address,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const showDrawer = () => {
    props.setOpen(true);
  };

  useEffect(() => {
    if (change_password_status === "success") {
      toast.success("Password Changed");
      set_is_modal_open(false);
      form.resetFields("");
      dispatch(dynamicClear("change_password"));
    } else if (change_password_status === "failure") {
      toast.error("Incorrect old password");
      dispatch(dynamicClear("change_password"));
    }
  }, [change_password_status, change_password_error]);

  return (
    <Box h={"90px"} w="100%" bg="#ffffff">
      <HStack
        h="100%"
        w="100%"
        bg="#780a0a"
        space="2"
        justifyContent={"space-between"}
        style={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        }}
      >
        <Center ml="20px">
          <HStack alignItems={"center"}>
            <Image
              source={require(`../../../../assets/icons/masm.png`)}
              width={"60px"}
              height={"60px"}
            />
            <VStack mt={"-7px"}>
              <HStack>
                <Text
                  fontFamily={"project_header"}
                  fontSize={{ base: "10px", lg: "20px" }}
                  bold
                  mx={2}
                  color="#f7fff0"
                  width={{ base: "100px", lg: "" }}
                >
                  {t("masm_header")}
                </Text>
                {role === "District Admin" || role === "District User" ? (
                  <Text
                    fontFamily={"project_header"}
                    fontSize={18}
                    bold
                    mx={40}
                    color="#f7fff0"
                  >
                    {district_name} District
                  </Text>
                ) : (
                  ""
                )}
              </HStack>
            </VStack>
          </HStack>
        </Center>
        <Box my={"auto"}>
          <HStack>
            <Box right="10px" ml="6">
              <Menu
                w="160"
                position="absolute"
                right="0px"
                top="20px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      alignItems="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <VStack>
                        <HStack>
                          <Text
                            bold
                            fontSize={{ base: "10px", lg: "md" }}
                            fontWeight=""
                            color="#f7fff0"
                          >
                            Welcome, {role}
                          </Text>
                          <Box
                            right="10px"
                            ml={{ base: "5", lg: "6" }}
                            marginTop={{ base: "-5px", lg: "" }}
                          >
                            <Avatar round size="25" src={UserIcon} />
                          </Box>
                        </HStack>
                        {/* <Text
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "white",
                          }}
                        >
                          ({role})
                        </Text> */}
                      </VStack>
                    </Pressable>
                  );
                }}
              >
                {/* <Menu.Item onPress={handleAccount}>Account</Menu.Item> */}
                <Menu.Item
                  onPress={() => {
                    handle_profile();
                  }}
                >
                  {t("profile_update")}
                </Menu.Item>
                <Menu.Item
                  onPress={() => {
                    handle_modal_open();
                  }}
                >
                  Change Password
                </Menu.Item>

                <Menu.Item
                  onPress={() => {
                    handleLogout();
                  }}
                >
                  Logout
                </Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>
      <Modal
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        title={t("change_password")}
        open={is_modal_open}
        onOk={handle_modal_close}
        onCancel={handle_modal_close}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={handle_modal_close}>
            Close
          </Button>,
          <Button
            key="save"
            form="change_password_form"
            htmlType="submit"
            // onClick={handle_modal_close}
            style={{ backgroundColor: "#1e90ff", color: "white" }}
            loading={loading}
          >
            Save
          </Button>,
        ]}
        width={500}
      >
        <ChangePassword
          handle_modal_close={handle_modal_close}
          set_is_modal_open={set_is_modal_open}
        />
      </Modal>
      {/* <Drawer
        className="ant-drawer-title ant-drawer-close"
        headerStyle={{
          backgroundColor: "#712e5a",
          textDecorationColor: "#fff",
        }}
        bodyStyle={{ backgroundColor: "#fef5fb" }}
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Box>
          <Text>{"Notifications"}</Text>
        </Box>
      </Drawer> */}
    </Box>
  );
};

export default HeaderBar;
