export const APP_ICON = require("../assets/icons/vertace.png");
export const VERTACE_ICON = require("../assets/icons/vertace.png");
export const GOOGLE_ICON = require("../assets/icons/google.png");
export const FACEBOOK_ICON = require("../assets/icons/facebook.png");
export const APPLE_ICON = require("../assets/icons/apple.png");
export const PROFILE_ICON = require("../assets/icons/profile.png");
export const FILTER_ICON = require("../assets/icons/filter.png");
export const CLOSE_ICON = require("../assets/icons/close-circle.png");
export const HEART_ICON = require("../assets/icons/heart.png");
export const BOOKMARK_ICON = require("../assets/icons/bookmark.png");
export const DASHBOARD_USER = require("../assets/icons/user (4).png");
export const HEADER_BAR = require("../assets/icons/logo.png");
export const UPI_IMAGE = require("../assets/icons/upi.png");
export const CASH_IMAGE = require("../assets/icons/cash.png");
export const CARD_IMAGE = require("../assets/icons/card2.png");
export const DD_IMAGE = require("../assets/icons/cheque (1).png");
export const NO_DATA_FOUND = require("../assets/svg/no_data_found.svg");
export const ENROLLMENT_IMAGE = require("../assets/icons/enrollment.png");
export const PAYMENT_SUCCESS = require("../assets/icons/success_payment_status.png");
export const VERIFICATION_BADGE = require("../assets/icons/verification-badge.png");
export const PAYMENT_LOADING = require("../assets/icons/payment_loading.gif");
export const CARD_IMAGE_G = require("../assets/icons/card_g.png");
export const UPI_G = require("../assets/icons/upi_g.png");
export const MASM_LOGO = require("../assets/icons/masm.png");
export const SIDE_BAR_IMAGE = require("../assets/icons/sidebar_img.png");
export const NO_DATA_IMAGE = require("../assets/icons/no_data.avif");
export const APPRECIATION_IMAGE_ = require("../assets/icons/appreciation.jpeg");
export const PAYMENT_FAILED = require("../assets/icons/payment_failed.jpg");
export const PAYMENT_LINK_EXPIRED = require("../assets/icons/expired.jpg");
export const DUPLICATE_BADGE = require("../assets/icons/duplicate_img.jpg");

