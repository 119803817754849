import { Button, Modal, Popconfirm, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import {
  retrieveItem,
  send_response_to_printer_service,
} from "../../../../helpers/functions";
import {
  dynamicClear,
  dynamicRequest,
  mutation_enrolment_ticket_print,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";

const TokenPrint = (props) => {
  const {
    is_print_token_modal_visible,
    set_is_print_token_modal_visible,
    enrolled_id,
    spot_enrol_button_ref,
    set_front_url,
    set_back_url,
  receipt_form
  } = props;
  const dispatch = useDispatch();
  const session_id = retrieveItem("session_id");
  const [is_loading, set_is_loading] = useState(false);
  const [token_printer_response, set_token_printer_response] = useState("");


  useEffect(() => {
    if (token_printer_response === "printing started") {
      TicketsPrinted();
      set_is_loading(false);
      close_token_printer_modal();
      set_token_printer_response("");
      dispatch(dynamicClear("enroll_booking"));
      dispatch(dynamicClear("asynchronous_check_request"));
      set_front_url("");
      set_back_url("");
      dispatch(dynamicClear("enrol_detail"));
      dispatch(dynamicClear("booking_details"));
      if (spot_enrol_button_ref.current) {
        spot_enrol_button_ref.current.focus();
        receipt_form.resetFields()
      }
    } else if (
      token_printer_response === "tickets already printed" ||
      token_printer_response === "invalid enrolment status" ||
      token_printer_response === "error"
    ) {
      toast.error("Tickets already printed");
      close_token_printer_modal();
      set_token_printer_response("");
      if (spot_enrol_button_ref.current) {
        spot_enrol_button_ref.current.focus();
        receipt_form.resetFields()
      }
      dispatch(dynamicClear("enroll_booking"));
      dispatch(dynamicClear("asynchronous_check_request"));
    }
  }, [token_printer_response, spot_enrol_button_ref]);

  const TicketsPrinted = async () => {
    let key = [{ key: "set_enrolment_ticket_printed", loading: true }];
    let query = mutation_enrolment_ticket_print;
    let variables = {
      id: enrolled_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const close_token_printer_modal = () => {
    set_is_print_token_modal_visible(false);
  };

  return (
    <Modal
      title={"Print Token"}
      open={is_print_token_modal_visible}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <Popconfirm
            title={"Are you sure to process without printing?"}
            onConfirm={close_token_printer_modal}
            okText="Yes"
            cancelText="No"
          >
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </Popconfirm>
        </span>
      }
      footer={[
        <Button
          loading={is_loading}
          style={{
            color: "white",
            border: "transparent",
            backgroundColor: "#168f92",
          }}
          htmlType="submit"
          onClick={() => {
            send_response_to_printer_service(
              enrolled_id,
              session_id,
              set_is_loading,
              TicketsPrinted,
              close_token_printer_modal,
              set_token_printer_response
            );
          }}
        >
          {"Print"}
        </Button>,
        ,
      ]}
    >
      <Typography>
        {
          "Your information saved successfully..! Do you want to print the receipt ?"
        }
      </Typography>
    </Modal>
  );
};

export default TokenPrint;
