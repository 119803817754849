import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Typography } from "antd";
import VipBookingUserLeaderDetailsCard from "../vip_booking_user_leader_details/vip_booking_user_leader_details_card";
import VipBookingUserCountAmtSection from "./vip_booking_user_count_amt_section";
import VipBookingUserTypeDistrict from "./vip_booking_user_type_district";
import VipBookingUserAbishekamDate from "./vip_booking_user_abishekam_date";
import VipBookingUserTime from "./vip_booking_user_time";
import {
  create_vip_booking_mutation,
  dynamicClear,
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BookingConfirmationDetail from "../../booking/booking_modal_form_item/booking_confirmation_detail";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { parseInt } from "lodash";
import {
  initiatePayment,
  navigate,
  retrieveItem,
  storeItem,
} from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import WebcamCapture from "./vip_booking_web_cam";
import { decryptResponse } from "../../../../helpers/crypto";

const VipBookingFormUser = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [time_slots, set_time_slots] = useState([]);
  const [modal_open, set_modal_open] = useState(false);
  const [booking_details, set_booking_details] = useState();

  const vip_booking_id = retrieveItem("vip_booking_id");
  // const [url, set_url] = useState();

  // console.log("url", url);

  const [selected_time, set_selected_time] = useState(null);
  const [is_button_disabled, set_is_button_disabled] = useState(true);
  const [selected_item, set_selected_item] = useState({
    id: "",
    date: "",
    date_string: "",
  });
  console.log("booking_details", booking_details);

  const { items: season_date_slot_list_items, loading } = useDynamicSelector(
    "get_season_date_slot_list"
  );
  const leader_details = retrieveItem("vip_booking_user");
  console.log("leader_details", leader_details);

  const {
    id: booking_id,
    status: vip_booking_status,
    error: vip_booking_error,
    loading: create_booking_loading,
  } = useDynamicSelector("create_vip_booking");

  useEffect(() => {
    if (vip_booking_status === "booking payment order initiated") {
      toast.success("Vip booking initiated");
      // set_url("");
      set_modal_open(false);
      online_payment();
      dispatch(dynamicClear("create_vip_booking"));
    } else if (vip_booking_error) {
      toast.error("Vip Booking initiated Failed");
      set_modal_open(false);
      dispatch(dynamicClear("create_vip_booking"));
    }
  }, [vip_booking_status, vip_booking_error]);

  const handle_submit = (values) => {
    set_booking_details(values);
    set_modal_open(true);
    // spot_vip_booking(values);
  };

  const spot_vip_booking = (records) => {
    let key = [{ key: "create_vip_booking", loading: true }];
    let query = create_vip_booking_mutation;
    let data = {
      time_slot_id: selected_time,
      count: parseInt(records?.count),
      booking_user_district_id: records?.district,
      booking_user_id: leader_details?.id ? leader_details?.id : "",
      booking_type: records?.booking_type,
      // attachment_url: url,

      booking_user: {
        name: records?.leader_name
          ? records?.leader_name
          : leader_details?.name,
        mobile: records?.mobile ? records?.mobile : leader_details?.mobile,
        email: records?.email ? records?.email : leader_details?.email,

        address: records?.address ? records?.address : leader_details?.address,
        id_type: records?.id_proof
          ? records?.id_proof
          : leader_details?.id_type,
        id_number: records?.id_number
          ? records?.id_number
          : leader_details?.id_number,
      },
    };

    if (leader_details?.id) {
      delete data.booking_user;
    } else {
      delete data.booking_user_id;
    }

    let variables = {
      data: data,
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  const get_season_date_slot_list = () => {
    const key = [{ key: "get_season_date_slot_list", loading: true }];
    const query = query_get_season_date_slot_list;
    const variables = {
      sort: { field: "date", order: "asc" },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (selected_item?.id) {
      const result = season_date_slot_list_items?.find(
        (x) => x.id === selected_item.id
      );
      set_time_slots(result?.time_slots || []);
    }
  }, [selected_item, season_date_slot_list_items]);

  const form_values = form.getFieldsValue();
  const booking_type = Form.useWatch("booking_type", form);
  const count = Form.useWatch("count", form);
  const date = Form.useWatch("date", form);
  const leader_name = Form.useWatch("leader_name", form);
  const address = Form.useWatch("address", form);
  const id_proof = Form.useWatch("id_proof", form);
  const id_number = Form.useWatch("id_number", form);
  const district = Form.useWatch("district", form);

  useEffect(() => {
    if (
      (leader_details?.id ||
        (address && leader_name && id_proof && id_number)) &&
      booking_type &&
      count &&
      selected_item?.id &&
      selected_time&&district
    ) {
      set_is_button_disabled(false);
    } else {
      set_is_button_disabled(true);
    }
  }, [
    leader_details,
    form,
    address,
    leader_name,
    id_proof,
    id_number,
    booking_type,
    count,
    selected_item,
    selected_time,
    district
  ]);

  // const on_form_values_change = () => {
  //   if (
  //     (leader_details?.id ||
  //       (address && leader_name && id_proof && id_number)) &&
  //     booking_type &&
  //     count &&
  //     selected_item.id &&
  //     selected_time
  //   ) {
  //     set_is_button_disabled(false);
  //   } else {
  //     set_is_button_disabled(true);
  //   }
  // };

  const on_finish = () => {
    spot_vip_booking(booking_details);
  };

  console.log("booking_id", booking_id);

  useEffect(() => {
    if (booking_id) {
      // storeItem("vip_booking_id", decryptResponse(booking_id));
      storeItem("vip_booking_id", booking_id);
    }
  }, [booking_id]);

  console.log("vip_booking_id", vip_booking_id);

  const online_payment = () => {
    initiatePayment({
      booking_id,
      on_success: (response) => {
        console.log("Payment_Success:", response);
        toast.success("Vip booking created Successfully");
        navigate(ROUTES.VIP_BOOKING_HISTORY);
      },
      on_error: (error) => {
        console.log("Payment Error:", error);
        toast.error("Vip booking created failed");
      },
    });
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        // onValuesChange={on_form_values_change}
        onFinish={handle_submit}
        style={{ padding: "10px", margin: "auto" }}
      >
        <VipBookingUserAbishekamDate
          set_selected_item={set_selected_item}
          form={form}
        />

        {selected_item?.id && (
          <VipBookingUserTime
            items={time_slots}
            selected_date={selected_item?.date}
            set_selected_time={set_selected_time}
            selected_time={selected_time}
          />
        )}
        <VipBookingUserLeaderDetailsCard form={form} />
        <VipBookingUserTypeDistrict form={form} />
        <VipBookingUserCountAmtSection />
        {/* <VipBookingAadhaarUpload/> */}
        {/* <WebcamCapture set_url={set_url} /> */}
        <Form.Item>
          <Button
            htmlType="submit"
            disabled={is_button_disabled}
            className={
              is_button_disabled
                ? "vip_booking_user_disabled_button"
                : "vip_booking_user_button"
            }
            block
            style={{
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          >
            {t("book")}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={t("conformation")}
        open={modal_open}
        onCancel={() => set_modal_open(false)}
        // style={{ maxWidth: 600, height: 300 }}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={() => set_modal_open(false)}
          >
            {t("no")}
          </Button>,
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            form="add_counter_form"
            onClick={on_finish}
            loading={create_booking_loading}
          >
            {t("yes")}
          </Button>,
        ]}
      >
        <Typography
          style={{
            marginBottom: "15px",
          }}
        >
          {t("details_for_booking")}
        </Typography>

        <BookingConfirmationDetail
          title={t("devotee_s")}
          value={booking_details?.count}
        />
        <BookingConfirmationDetail
          title={t("date")}
          value={selected_item?.date}
        />
      </Modal>
    </>
  );
};

export default VipBookingFormUser;
