import React from "react";
import { PAYMENT_FAILED } from "../../helpers/image_constants";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const payment_failed = () => {
  return (
    <div className="thank_you_modal_content_main_container">
      <div className="thank_you_modal_content">
        <DotLottieReact
          src="https://lottie.host/a38a37a4-5def-428e-99fa-a06e03ec77f4/WNQwAtJcgt.lottie"
          loop
          autoplay
          style={{ height: "400px", width: "400px" }}

        />
        <h6 className="thank_you_message" style={{ color: "#d32f2f" }}>
          {"Transaction Failed"}
        </h6>
      </div>
    </div>
  );
};

export default payment_failed;
