import React, { useEffect, useState } from "react";
import moment from "moment";
import Weekdays from "./week_days";
import DateAndMonth from "./date_and_month";
import { useDynamicSelector } from "../../../../services/redux";
import CustomSkeletonLoading from "../../common/custom_skeleton_loading";
import _orderBy from "lodash/orderBy";
import {
  get_calendar_cell_color,
  navigateWithProps,
} from "../../../../helpers/functions";

const Calendar = ({ start_date, end_date }) => {
  const [ordered_date_slots, set_ordered_date_slots] = useState([]);

  const { items: irumudi_calendar_items, loading: irumudi_calendar_loading } =
    useDynamicSelector("get_dashboard_calendar");
  const { items: date_slot_list_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  const on_click_date = ({ date, id } = {}) => {
    
    if (date) {
      navigateWithProps(`/booking-history`, { state: { abishekam_date: date, id } });
    }
  };

  const days_of_week = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const date_array = [];
  let current_date = moment(start_date);

  const index_of_day = (day) => {
    let result = days_of_week.findIndex((x) => x === day);
    return result;
  };

  const get_last_index_of_day = (day) => {
    let result = days_of_week.findIndex((x) => x === day);
    result = 6 - result;
    return result;
  };

  for (let i = 0; i < irumudi_calendar_items?.length; i++) {
    let temp_date = irumudi_calendar_items[i];
    date_array.push({
      date: moment(temp_date.date).format("DD"),
      month_format: moment(temp_date.date).format("MMM"),
      day: moment(temp_date.date).format("ddd"),
      year: moment(temp_date.date).format("YYYY"),
      date_slot: temp_date.date_slot,
      date_string: temp_date.date_slot.date_string,
      count: temp_date.count,
      waiting_quota: temp_date.waiting_quota,
      confirmed_quota: temp_date.confirmed_quota,
      booked: temp_date.count.booked,
      excess: temp_date.count.excess,
      enrolled: temp_date.count.enrolled,
    });
  }

  if (date_array[date_array?.length - 1]?.day !== "Sun") {
    let temp_count = get_last_index_of_day(
      date_array[date_array?.length - 1]?.day
    );
    for (let i = 0; i < temp_count; i++) {
      const new_obj = { date: "blank", day: "" };
      date_array.push(new_obj);
    }
  }

  if (date_array[0]?.day !== "Mon") {
    let temp_count = index_of_day(date_array[0]?.day);
    for (let i = 0; i < temp_count; i++) {
      const new_obj = { date: "blank", day: "" };
      date_array.unshift(new_obj);
    }
  }

  const num_rows = Math.ceil(date_array.length / 7);

  useEffect(() => {
    set_ordered_date_slots(_orderBy(date_slot_list_items, ["date"], "asc"));
  }, [date_slot_list_items]);

  return (
    <div className="main-calendar-container">
      <div className="week-days-container">
        {days_of_week?.map((day, ind) => (
          <Weekdays key={ind} day={day} ind={ind} />
        ))}
      </div>
      <div className="calendar" style={{ display: "flex", flexWrap: "wrap" }}>
        {Array.from({ length: num_rows }).map((_, rowIndex) => (
          <div
            key={rowIndex}
            className="calendar-row"
            style={{ display: "flex", width: "100%" }}
          >
            {days_of_week?.map((day, dayIndex) => {
              const date_index = rowIndex * 7 + dayIndex;
              return (
                <div
                  key={`${day}-${date_index}`}
                  className="calendar-day"
                  onClick={() =>
                    on_click_date(date_array[date_index]?.date_slot)
                  }
                  style={{
                    backgroundColor:
                      date_array[date_index]?.date === "blank"
                        ? "#e4e5e5"
                        : get_calendar_cell_color(date_array[date_index]),
                    flex: 1,
                    padding: "2px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "47px",
                    margin: "10px 7px",
                    width: "10%",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {irumudi_calendar_loading && (
                    <CustomSkeletonLoading
                      style={{
                        height: "47px",
                        width: "10%",
                        borderRadius: "5px",
                      }}
                    />
                  )}

                  {irumudi_calendar_loading === false &&
                  date_array[date_index] ? (
                    <DateAndMonth
                      date_array={date_array}
                      date_index={date_index}
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
