import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { IoMdClose } from "react-icons/io";
import SubDistrictUserForm from "../sub_district_user/sub_district_user_form/add_sub_district_user_form";

const DistrictUserModal = (props) => {
  const { t } = useTranslation();
  const {
    header_type,
    add_user_model_visible,
    handle_edit_user_modal_close,
    get_one_district,
    record_id,
    edit_record,
  } = props;

  useEffect(() => {
    get_one_district(record_id);
  }, [record_id]);

  const { loading: update_district_user_loading } = useDynamicSelector(
    "update_district_user"
  );

  const { loading: create_district_user_loading } = useDynamicSelector(
    "create_district_user"
  );

  return (
    <Modal
      key={`user-${record_id}`}
      title={
        header_type === "edit_user" && edit_record?.name
          ? "Edit User"
          : "Add User"
      }
      open={add_user_model_visible}
      destroyOnClose={true}
      onCancel={() => {
        handle_edit_user_modal_close();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handle_edit_user_modal_close();
          }}
        >
          {t("Cancel")}
        </Button>,
        <Button
          key="confirm"
          type="primary"
          htmlType="submit"
          form={`add_edit_district_user_form-${record_id}`}
          loading={create_district_user_loading || update_district_user_loading}
        >
          {t("Save")}
        </Button>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <SubDistrictUserForm {...props} />
    </Modal>
  );
};

export default DistrictUserModal;
