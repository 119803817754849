import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import SpotBookingForm from "../../spot_booking/spot_booking_form/spot_booking_form";
import { Form } from "antd";
import { IoMdClose } from "react-icons/io";
import { dynamicClear, useDynamicSelector } from "../../../../services/redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
const EnrollSpotBooking = (props) => {
  const {
    set_spot_booking_open,
    spot_booking_open,
    get_enroll_booking_list,
    open_token_printer_modal,
    spot_enrol_button_ref,
    get_closure_details,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [selected_id_proof, set_selected_id_proof] = useState("Aadhaar Card");
  const [get_leader_mobile_no, set_leader_mobile_number] = useState();

  const {
    status: spot_booking_and_enroll_status,
    error: create_spot_booking_and_enroll_error,
    loading: spot_booking_and_enroll_loading,
  } = useDynamicSelector("create_spot_booking_and_enroll");

  const cancel_spot_booking = () => {
    form.resetFields("");
    set_spot_booking_open(false);
  };

  const get_mobile_number = () => {
    console.log("get_mobile");
  };

  useEffect(() => {
    if (spot_booking_and_enroll_status === "booking enrolled") {
      open_token_printer_modal();
      set_spot_booking_open(false);
      get_enroll_booking_list();
      get_closure_details();
      form.resetFields("");
      if (spot_enrol_button_ref.current) {
        spot_enrol_button_ref.current.focus();
      }
      dispatch(dynamicClear("create_spot_booking_and_enroll"));
    }
    if (spot_booking_and_enroll_status === "booking created & paid") {
      open_token_printer_modal();
      set_spot_booking_open(false);
      get_enroll_booking_list();
      get_closure_details();
      form.resetFields("");
      if (spot_enrol_button_ref.current) {
        spot_enrol_button_ref.current.focus();
      }
      dispatch(dynamicClear("create_spot_booking_and_enroll"));
    } else if (create_spot_booking_and_enroll_error?.message) {
      set_spot_booking_open(false);
      toast.error(create_spot_booking_and_enroll_error?.message);
      if (spot_enrol_button_ref.current) {
        spot_enrol_button_ref.current.focus();
      }
      dispatch(dynamicClear("create_spot_booking_and_enroll"));
    }
  }, [spot_booking_and_enroll_status, spot_enrol_button_ref]);
  return (
    <Modal
      title="Spot Booking"
      headStyle={{ backgroundColor: "#275670", color: "white" }}
      open={spot_booking_open}
      destroyOnClose={true}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      onCancel={() => set_spot_booking_open(false)}
      footer={[
        <Button
          style={{
            width: "20%",
            borderRadius: "10px",
            backgroundColor: "#ec9501",
            color: "white",
          }}
          onClick={cancel_spot_booking}
        >
          Cancel
        </Button>,
        <Button
          style={{
            width: "20%",
            borderRadius: "10px",
            backgroundColor: "#033e3e",
            color: "white",
          }}
          htmlType="submit"
          form="spot_booking_form"
          loading={spot_booking_and_enroll_loading}
        >
          Book
        </Button>,
      ]}
      width="68%"
    >
      <div style={{ marginLeft: "30px" }}>
        <SpotBookingForm
          form={form}
          set_receipt_modal_open={set_spot_booking_open}
          open_token_printer_modal={open_token_printer_modal}
          set_selected_id_proof={set_selected_id_proof}
          selected_id_proof={selected_id_proof}
          get_mobile_number={get_mobile_number}
          set_leader_mobile_number={set_leader_mobile_number}
          get_leader_mobile_no={get_leader_mobile_no}
        />
      </div>
    </Modal>
  );
};

export default EnrollSpotBooking;
