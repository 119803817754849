import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  dynamicRequest,
  query_get_all_district_list,
  query_get_all_mandram_list,
  query_get_all_mandram_list_search,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { Text } from "native-base";

const MandramFilter = ({record_id}) => {
  const dispatch = useDispatch();

  const { items: mandram_list } = useDynamicSelector("get_mandrams");
  const { items: get_searchable_mandram_list } = useDynamicSelector("get_mandrams_search");

  console.log("get_searchable_mandram_list",get_searchable_mandram_list);
  
  const handle_input_change = (e) => {
    console.log("e", e.target.value);
    get_all_mandram_list_search(e.target.value);
  };

  const get_all_mandram_list_search = (val) => {
    let key = [{ key: "get_mandrams", loading: true }];
    let query = query_get_all_mandram_list;
    let variable = {
      filter: {
        district_id: record_id,
        search_string: val || "",
      },
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };


  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {/* <Text
        style={{
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   backgroundColor: "pink",
        }}
      >
        Search
      </Text> */}
      <Input
        placeholder="Search"
        suffix={<FaSearch style={{ marginRight: "8px", color: "#1890ff" }} />}
        onChange={(e) => {
          handle_input_change(e);
        }}
      />
    </div>
  );
};
export default MandramFilter;
