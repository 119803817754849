import { Card, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import MandramIdInput from "./add_mandram_form_inputs/mandram_id_input";
import MandramNameInput from "./add_mandram_form_inputs/mandram_name_input";
import MandramDistrictInput from "./add_mandram_form_inputs/mandram_district_input";
import MandramInChargeMobile from "./add_mandram_form_inputs/mandram_incharge_mobile";
import MandramInChargeInput from "./add_mandram_form_inputs/mandram_incharge_input";
import MandramActiveStatusInput from "./add_mandram_form_inputs/mandram_active_status_input";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  create_mandram_query,
  dynamicClear,
  dynamicRequest,
  update_mandram_query,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import { get_mandram_status, mandram_status } from "../../../../helpers/constants";
import MandramSubDistrict from "./add_mandram_form_inputs/mandram_sub_district";

const AddMandramForm = (props) => {
  const { set_add_mandram, get_all_mandram_list, header_type } = props;
  const [is_sakthi_peedam_status, set_is_sakthi_peedam] = useState(false);
  const [is_active_status, set_is_active_status] = useState(true);
  const [mandram_values, set_mandram_values] = useState();
  const [mandram_code, set_mandram_code] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    id,
    name,
    code,
    district_id,
    incharge_name,
    incharge_mobile,
    is_sakthi_peedam,
    sub_district_id,
    is_active,
    admin,
  } = useDynamicSelector("get_mandram");
  const [get_one_district_id, set_district_id] = useState("");

  const {
    status: create_mandram_status,
    loading: create_mandram_loading,
    error: create_mandram_error,
  } = useDynamicSelector("create_mandram");

  const {
    status: update_mandram_status,
    loading: update_mandram_loading,
    error: update_mandram_error,
  } = useDynamicSelector("update_mandram");

  useEffect(()=>{
    set_district_id(district_id)
  },[district_id])

  const on_finish = (values) => {
    // values.activation_status = mandram_status[values.status || false];
    if (header_type === "Add Mandram") {
      add_mandram(values);
    } else {
      update_one_mandram(values);
    }
    set_mandram_values(values);
  };

  const add_mandram = (values) => {
    let key = [{ key: "create_mandram", loading: true }];
    let query = create_mandram_query;
    let variables = {
      data: {
        // code: mandram_code,
        ...values,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_one_mandram = (values) => {
    let key = [{ key: "update_mandram", loading: true }];
    let query = update_mandram_query;
    let variables = {
      id: id,
      data: {
        ...values,
        // code: mandram_code,
        is_active: values.is_active,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (create_mandram_status === "success") {
      toast.success("Mandram Created Successfully");
      set_add_mandram(false);
      get_all_mandram_list();
      dispatch(dynamicClear("create_mandram"));
    } else if (create_mandram_error) {
      toast.error(create_mandram_error?.message);
      // set_add_mandram(false);
      dispatch(dynamicClear("create_mandram"));
    } else if (update_mandram_status === "success") {
      toast.success("Mandram Updated Successfully");
      set_add_mandram(false);
      get_all_mandram_list();
      dispatch(dynamicClear("update_mandram"));
    } else if (update_mandram_error) {
      toast.error(update_mandram_error?.message);
      // set_add_mandram(false);
      dispatch(dynamicClear("update_mandram"));
    }
  }, [
    create_mandram_status,
    create_mandram_error,
    update_mandram_status,
    update_mandram_error,
  ]);

  useEffect(() => {
    if (header_type === "Edit Mandram") {
      form.setFieldsValue({
        id,
        name,
        code,
        district_id,
        incharge_name,
        incharge_mobile,
        is_sakthi_peedam,
        sub_district_id,
        is_active,
        get_one_district_id,
        mandram_admin: { ...admin },
        // activation_status:get_mandram_status["paid"]

      });
    } else if (header_type === "Add Mandram") {
      form.resetFields();
    }
  }, [header_type, id]);

  const on_value_changes = (val, values) => {
    if (val?.district_id) {
      form.setFieldValue("sub_district_id", "");
    }
  };

  return (
    <Form
      id="add_mandram_form"
      onFinish={on_finish}
      form={form}
      onValuesChange={on_value_changes}
    >
      <Card
        style={{
          width: "auto",
          height: "auto",
          backgroundColor: "#fafafa",
          marginBottom: "10px",
        }}
      >
        <h3 style={{ marginLeft: "12px" }}>{t("mandram")}</h3>
        <Row gutter={16}>
          <Col span={12}>
            <MandramNameInput header_type={header_type} />
            <MandramIdInput
              header_type={header_type}
              set_mandram_code={set_mandram_code}
              form={form}
            />
            <MandramInChargeMobile />
            {/* {header_type === "Edit Mandram" ? (
              <MandramActiveStatusInput
                set_is_active_status={set_is_active_status}
                is_active_status={is_active_status}
                is_active={is_active}
              />
            ) : (
              ""
            )} */}
          </Col>
          <Col span={12}>
            <MandramDistrictInput
              header_type={header_type}
              set_district_id={set_district_id}
            />
            <MandramSubDistrict
              get_district_id={get_one_district_id}
              header_type={header_type}
            />
            <MandramInChargeInput />
            {/* {usePageComponentAccess("paid_form_filed") && (
              <MandramPaidStatusInput />
            )} */}
          </Col>
        </Row>
      </Card>
      {/* <Card
        style={{
          width: "auto",
          height: "auto",
          backgroundColor: "#fafafa",
          marginBottom: "10px",
        }}
      >
        <h3 style={{ marginLeft: "12px" }}>{t("mandram_admin")}</h3>
        <Row gutter={16}>
          <Col span={12}>
            <MandramUserName />
            <ContactNumber />
            <EmailId />
          </Col>
          <Col span={12}>
            <Username header_type={header_type} />
            <Password header_type={header_type} />
          </Col>
        </Row>
      </Card> */}
    </Form>
  );
};

export default AddMandramForm;
