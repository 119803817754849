import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const IrumudiCount = ({ set_count }) => {
  const { t } = useTranslation();
  const on_change = (val) => {
    set_count(val);
  };
  return (
    <Form.Item
      label={t("irumudi_count")}
      name="irumudi_count"
      rules={[
        {
          validator: (_, value) => {
            if (value < 0) {
              return Promise.reject("Negative numbers are not allowed!");
            } else if (value === 0) {
              return Promise.reject("Zero is not allowed!");
            } else {
              return Promise.resolve();
            }
          },
          required: true,
        },
      ]}
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        placeholder="Enter the irumudi count"
        onChange={on_change}
      />
    </Form.Item>
  );
};

export default IrumudiCount;
