import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { ROUTES } from "../routes/my_routes";
import {
  dynamicRequest,
  get_check_payment_order_status_query,
  useDynamicSelector,
} from "../../services/redux";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Player } from "@lottiefiles/react-lottie-player";

const VipBookingUserPaymentResult = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("params", params);
  const [payment_status, set_payment_status] = useState("");
  const [open_notification, set_open_notification] = useState(false);

  const { status: check_order_status, error: check_order_error } =
    useDynamicSelector("check_payment_order_status");

  useEffect(() => {
    if (check_order_status === "completed") {
      set_payment_status("completed");
      set_open_notification(true);
    } else if (check_order_status === "cancelled") {
      set_payment_status("cancelled");
    } else if (check_order_status === "initiated") {
      set_payment_status("initiated");
    } else if (check_order_status === "pending") {
      set_payment_status("pending");
    } else if (check_order_status === "failed") {
      set_payment_status("failed");
    }
  }, [check_order_status]);

  const get_check_payment_order_status = () => {
    let key = [{ key: "check_payment_order_status", loading: true }];
    let query = get_check_payment_order_status_query;
    let variables = {
      ref_number: params?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_check_payment_order_status();
  }, []);

  useEffect(() => {
    if (
      check_order_status === "initiated" ||
      check_order_status === "pending"
    ) {
      setTimeout(() => {
        get_check_payment_order_status();
      }, 5000);
    }
  }, [check_order_status]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="thank_you_modal_content_main_container">
        {payment_status === "completed" && (
          <div className="thank_you_modal_content">
            <DotLottieReact
              src="https://lottie.host/8794cb33-52dd-4c3f-a0ae-82a3d95fd45e/oTevoywAdk.lottie"
              loop
              autoplay
              style={{ height: "400px", width: "400px" }}
            />

            <h6 className="thank_you_message" style={{ color: "#00b74a" }}>
              {"Payment Success"}
            </h6>
          </div>
        )}

        {(payment_status === "failed" || payment_status === "cancelled") && (
          <div className="thank_you_modal_content">
            <DotLottieReact
              src="https://lottie.host/a38a37a4-5def-428e-99fa-a06e03ec77f4/WNQwAtJcgt.lottie"
              loop
              autoplay
              style={{ height: "400px", width: "400px" }}
            />
            <h6 className="thank_you_message" style={{ color: "#d32f2f" }}>
              {payment_status === "failed"
                ? "Payment Failed"
                : "Payment Cancelled"}
            </h6>
          </div>
        )}

        {(payment_status === "pending" || payment_status === "initiated") && (
          <div className="thank_you_modal_content">
            <Player
              autoplay
              loop
              src="https://lottie.host/61b7afec-37b4-4c51-9941-e96bc6e08aad/QA1VWQxkVn.json"
              className="thank_you_image"
              style={{ height: "400px", width: "400px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VipBookingUserPaymentResult;
