import React, { useState } from "react";
import MandramRenewalList from "../components/mandram_renewal/mandram_renewal_list";
import MandramRenewalFilterForm from "../components/mandram_renewal/mandram_renewal_filter_form";

const MandramRenewal = () => {
  const [district_id, set_district_id] = useState("");
  const [sub_district_id, set_sub_district_id] = useState("");
  return (
    <div style={{ margin: "10px" }}>
      <MandramRenewalFilterForm
        set_sub_district_id={set_sub_district_id}
        set_district_id={set_district_id}
      />
      <MandramRenewalList
        district_id={district_id}
        sub_district_id={sub_district_id}
      />
    </div>
  );
};

export default MandramRenewal;
